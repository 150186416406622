import { useEffect, useState } from 'react';
import loanIcon from '../../../common-icons/loan-dashboard-icon.svg';
import greyArrowIcon from '../../../common-icons/dashboard-grey-arrow.svg';
import schedulePayment from '../../../common-icons/schedule-payment.png';
import remitPaymentIcon from '../../../common-icons/remit-payment-icon.svg';
import { formatNumberAsDollars } from '../../../utils';
import { getClient } from '../../../api-utils/general-utils';
import '../index.scss';
import PayroButton from '../../../widgets/PayroButton';
import { useRecoilState, useRecoilValue } from 'recoil';
// eslint-disable-next-line max-len
import { showHideClassNameState } from '../../../recoil-state/request-funding-states';
// eslint-disable-next-line max-len
import SchedulePaymentModal from '../../Ledger/LedgerDetail/SchedulePaymentModal';
import NextScheduledPayment from './NextScheduledPayment';
import NextScheduledCAPayment from './NextScheduledCAPayment';
import NextScheduledCashAdvancePayment from './NextScheduledCashAdvancePayment';
import {
  accountRecordState,
  activeRepaymentsState,
  recentRepaymentsState,
  repaymentsState,
} from '../../../recoil-state/general-states';
import {
  getLoanIdToDisplay,
  getLoanIdToDisplayLedgerDetail,
} from '../../Ledger/ledger-utils';
import {
  GetAccountDto,
  GetRepaymentDto,
} from '../../../api-utils/generated-client';

import { useHistory } from 'react-router-dom';
import ScheduleAdvancePaymentModal from '../../Ledger/LedgerDetail/ScheduleAdvancePaymentModal';

const RecentPayrollFunded = () => {
  let history = useHistory();
  const [showHideClassName, setShowHideClassName] = useRecoilState(
    showHideClassNameState,
  );
  const recentRepayments = useRecoilValue(recentRepaymentsState);
  const accountDetails = useRecoilValue<GetAccountDto>(
    accountRecordState,
  );
  const [activeRepayments, setActiveRepaymentsList] = useRecoilState<
    GetRepaymentDto[]
  >(activeRepaymentsState);
  useEffect(() => {}, [recentRepayments]);
  // console.log('ActiveRepayment',activeRepayments)
  var bankaccnumber =
    activeRepayments &&
    activeRepayments[0] &&
    activeRepayments[0].bank_account_number
      ? activeRepayments[0].bank_account_number.toString()
      : '';

  return (
    <>
      <div className="recent-payroll-funded-main-wrapper">
        <div className="recent-payroll-wrapper">
          <p className="recent-payroll-funded-text">
            Recent Payrolls Funded
          </p>
          {recentRepayments?.length ? (
            recentRepayments.map((r) => (
              <div key={r.uuid}>
                <div className="recent-payroll-item">
                  <div
                    className="loan-id-and-icon-wrapper cursor-sec"
                    onClick={() => {
                      history.push(`/ledger-detail/${r.uuid}`);
                    }}
                  >
                    <img src={loanIcon} />
                    <p className="loan-id-style">
                      {getLoanIdToDisplay(r.uuid)}
                    </p>
                  </div>
                  <div className="balance-wrapper-recent-payroll-item">
                    <p className="balance-text-style">Bal &nbsp;</p>
                    <p className="balance-number-style">
                      {' '}
                      {r.status == 'Request In - Review Payroll'
                        ? formatNumberAsDollars(r.TotalAmountPayback)
                        : formatNumberAsDollars(
                            r?.total_outstanding_amount
                              ? r?.total_outstanding_amount
                              : r?.total_outstanding_principal,
                          )}
                    </p>
                  </div>
                  <div>
                    <PayroButton
                      key={r.uuid}
                      customWidth="width-small"
                      variant="purple-white"
                      endIcon={greyArrowIcon}
                      onClick={() => {
                        history.push(`/ledger-detail/${r.uuid}`);
                      }}
                    >
                      {''}
                    </PayroButton>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="no-next-payroll-text">
              You have no recent fundings at this time
            </p>
          )}

          {accountDetails.total_outstanding_amount &&
          accountDetails.total_outstanding_amount > 0 ? (
            <div className="outstanding-balance-wrapper-dashboard">
              <p className="outstanding-balance-text-style">
                Total Outstanding Balance
              </p>
              <p className="outstanding-balance-number-style">
                {formatNumberAsDollars(
                  accountDetails.total_outstanding_amount,
                )}
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="recent-payroll-next-scheduled-main-wrapper">
          <div className="recent-payroll-next-scheduled-wrapper">
            <NextScheduledCashAdvancePayment />
            {/* <NextScheduledCAPayment 
              isCashAdvance={true}
              repayment={activeRepayments && activeRepayments.length > 0 && activeRepayments[0]}
              allrepayments={activeRepayments}
            /> */}
          </div>
          <div className="button-and-explanation-wrapper">
            <div>
              {activeRepayments.length > 0 && (
                <ScheduleAdvancePaymentModal
                  key={activeRepayments[0].uuid}
                  repaymentUuid={activeRepayments[0].uuid}
                  repaymentPaybackDate={
                    activeRepayments[0].next_interest_date
                  }
                  loanId={getLoanIdToDisplayLedgerDetail(
                    activeRepayments[0].uuid,
                  )}
                  // eslint-disable-next-line max-len
                  repaymentBalance={
                    activeRepayments[0].total_outstanding_amount
                  }
                  nextScheduledPaymentBankName={
                    activeRepayments[0].withdrawl_bank_name
                  }
                  repaymentSfId={activeRepayments[0]?.salesforce_id}
                  // eslint-disable-next-line max-len

                  // nextScheduledPaymentBankNumber={activeRepayments && activeRepayments[0].bank_account_number && activeRepayments[0].bank_account_number.toString()}
                  nextScheduledPaymentBankNumber={bankaccnumber}
                  className={showHideClassName}
                ></ScheduleAdvancePaymentModal>
              )}
              <PayroButton
                startIcon={remitPaymentIcon}
                customWidth="width-182"
                onClick={() => {
                  setShowHideClassName('modal display-block');
                }}
                disabled={activeRepayments.length == 0}
              >
                Remit Payment
              </PayroButton>{' '}
            </div>

            <p className="exclamation-text">
              Payro gives you the ability to pay off your payroll
              advancement to save on fees.
            </p>
          </div>
        </div>{' '}
      </div>
    </>
  );
};

export default RecentPayrollFunded;
