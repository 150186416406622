export default [
  { name: 'AAP isolved', id: 'aap_isolved' },
  { name: 'Abacus HCM isolved', id: 'abacus_isolved' },
  { name: 'ABP', id: 'abp' },
  { name: 'Absolute Payroll isolved', id: 'absolute_isolved' },
  { name: 'Absolute Payroll', id: 'absolute_payroll' },
  { name: 'Accupay isolved', id: 'accupay_isolved' },
  { name: 'Ace Workforce Technologies isolved', id: 'ace_isolved' },
  { name: 'ADP', id: 'adp' },
  { name: 'ADP TotalSource', id: 'adp_totalsource' },
  { name: 'ADP Workforce Now', id: 'adp_workforce_now' },
  {
    name: 'Advantage Payroll Services',
    id: 'advantage_payroll_services',
  },
  {
    name: 'Affiliated HR Payroll Services Ev',
    id: 'affiliated_hr_payroll_services_evolution',
  },
  {
    name: 'Affiliated HR Payroll Services is',
    id: 'affiliated_hr_payroll_services_isolved',
  },
  { name: 'Ahola', id: 'ahola' },
  { name: 'Alliance Business Solutions', id: 'alliance_business_solutions' },
  { name: 'AlphaStaff', id: 'alphastaff' },
  { name: 'AmCheck Austin', id: 'amcheck_austin' },
  { name: 'Amcheck Las Vegas', id: 'amcheck_las_vegas' },
  { name: 'Amplify HR', id: 'amplify_hr' },
  { name: 'Apex Payroll Services', id: 'apex_payroll_services' },
  { name: 'APS Payroll', id: 'aps_payroll' },
  { name: 'ASAP Payroll Services', id: 'asap_payroll_services' },
  { name: 'Asset HR', id: 'asset_hr' },
  { name: 'Asure Software', id: 'asure_software' },
  { name: 'Balance Point', id: 'balance_point' },
  { name: 'Bamboo HR', id: 'bamboo_hr' },
  { name: 'Baron Payroll isolved', id: 'baron_isolved' },
  { name: 'BBCS Payroll', id: 'bbcs' },
  { name: 'BenCo', id: 'benco' },
  { name: 'Bene-Care', id: 'benecare' },
  {
    name: 'Big Fish Employer Services',
    id: 'big_fish_employer_services',
  },
  { name: 'Bizchecks Payroll', id: 'bizchecks_payroll' },
  //{ name: 'bob', id: 'bob' },
  { name: 'Capitol Payroll Partners', id: 'capitol_payroll_partners' },
  { name: 'Carolina Paymasters', id: 'carolina_paymasters' },
  { name: 'Celayix', id: 'celayix' },
  { name: 'Ceridian Dayforce', id: 'ceridian_dayforce' },
  { name: 'Ceridian Powerpay', id: 'ceridian_powerpay' },
  { name: 'CEZTA', id: 'cezta' },
  { name: 'CharlieHR', id: 'charlie_hr' },
  { name: 'CLEAR PEO', id: 'clear_peo' },
  { name: 'Collage', id: 'collage' },
  { name: 'CoAdvantage', id: 'coadvantage' },
  { name: 'Coastal Payroll', id: 'coastal_payroll' },
  { name: 'Cognos HR', id: 'cognos_hr' },
  { name: 'Combined HCM', id: 'combined_hcm' },
  {
    name: 'Commonwealth Payroll & HR',
    id: 'commonwealth_payroll_hr',
  },
  { name: 'CPM Employer Services isolved', id: 'cpm_isolved' },
  { name: 'Creative Planning', id: 'creative_planning' },
  {
    name: 'Crescent Payroll Solutions',
    id: 'crescent_payroll_solutions',
  },
  { name: 'CRI Payroll', id: 'cri_payroll' },
  { name: 'Critical Pay Solutions', id: 'critical_pay_solutions' },
  { name: 'Data', id: 'data' },
  { name: 'Deel', id: 'deel' },
  { name: 'Devin Maloy', id: 'devin_maloy' },
  { name: 'Dominion Payroll', id: 'dominion_payroll' },
  { name: 'EBC Payroll', id: 'ebc_payroll' },
  { name: 'Employdrive', id: 'employdrive' },
  { name: 'EmPower HR', id: 'empower_hr' },
  { name: 'Envision Payroll', id: 'envision_payroll' },
  { name: 'eSub', id: 'esub' },
  { name: 'Everee', id: 'everee' },
  { name: 'Finch', id: 'finch' },
  { name: 'Fingercheck', id: 'fingercheck' },
  { name: 'Goco', id: 'goco' },
  { name: 'Green Door Funding', id: 'green_door_funding' },
  { name: 'Gusto', id: 'gusto' },
  {
    name: 'GTM Payroll Services Evolution',
    id: 'gtm_payroll_services_evolution',
  },
  {
    name: 'GTM Payroll Services isolved',
    id: 'gtm_payroll_services_isolved',
  },
  { name: 'Heartland Payroll', id: 'heartland_payroll' },
  { name: 'Highflyer HR', id: 'highflyer_hr' },
  { name: 'Hire Beyond', id: 'hire_beyond' },
  { name: 'Hire Level', id: 'hire_level' },
  { name: 'Hire Up', id: 'hire_up' },
  { name: 'HR Ledger', id: 'hr_ledger' },
  { name: 'Hrmonious', id: 'hrmonious' },
  { name: 'HRtoGO', id: 'hrtogo' },
  { name: 'Hubstaff', id: 'hubstaff' },
  { name: 'Humi', id: 'humi' },
  { name: 'Infinium Group', id: 'infinium_group' },
  { name: 'Integrated Payroll Services', id: 'integrated_payroll_services' },
  { name: 'Insperity', id: 'insperity' },
  { name: 'isolved', id: 'isolved' },
  { name: 'JM Consulting Group', id: 'jm_consulting_group' },
  { name: 'Justworks', id: 'justworks' },
  { name: 'KeyHR', id: 'keyhr' },
  { name: 'Keystone Payroll isolved', id: 'keystone_isolved' },
  { name: 'Landmark Payroll', id: 'landmark_payroll' },
  { name: 'Miami Payroll Center', id: 'miami_payroll_center' },
  { name: 'Moneywise', id: 'moneywise' },
  { name: 'mp', id: 'mp' },
  { name: 'My Payroll Freedom', id: 'my_payroll_freedom' },
  { name: 'Namely', id: 'namely' },
  { name: 'NaturalHR', id: 'natural_hr' },
  { name: 'Next Gen', id: 'next_gen' },
  { name: 'Next HR', id: 'next_hr' },
  { name: 'Nextep', id: 'nextep' },
  { name: 'Newtek', id: 'newtek' },
  { name: 'North Georgia Payroll', id: 'north_georgia_payroll' },
  { name: 'OneSource Payroll', id: 'onesource_payroll' },
  { name: 'OnPay', id: 'onpay' },
  { name: 'Oracle PeopleSoft', id: 'oracle_peoplesoft' },
  { name: 'Paper Trails', id: 'paper_trails' },
  { name: 'Paragon Payroll isolved', id: 'paragon_payroll_isolved' },
  { name: 'Patriot', id: 'patriot' },
  { name: 'PayFit', id: 'payfit' },
  { name: 'PayNorthwest', id: 'paynw' },
  { name: 'PayUSA', id: 'pay_usa_inc' },
  { name: 'Paychex Flex', id: 'paychex_flex' },
  { name: 'Paycor', id: 'paycor' },
  { name: 'Paycom', id: 'paycom' },
  {
    name: 'Payday Workforce Solutions',
    id: 'payday_workforce_solutions',
  },
  { name: 'PayFWD', id: 'payfwd' },
  { name: 'Paylocity', id: 'paylocity' },
  { name: 'Paymaster Payroll', id: 'paymaster_payroll' },
  { name: 'PAYMEDIA', id: 'paymedia' },
  { name: 'PayPro HCS isolved', id: 'paypro_hcs_isolved' },
  { name: 'Payroll Freedom', id: 'payroll_freedom' },
  { name: 'Payroll Growth Partners', id: 'payroll_growth_partners' },
  { name: 'Payroll Medics', id: 'payroll_medics' },
  { name: 'Payroll Network', id: 'payroll_network' },
  {
    name: 'Payroll Office of America',
    id: 'payroll_office_of_america',
  },
  {
    name: 'Payroll Plus HCM Evolution',
    id: 'payroll_plus_hcm_evolution',
  },
  {
    name: 'Payroll Plus HCM isolved',
    id: 'payroll_plus_hcm_isolved',
  },
  { name: 'Payroll Rx', id: 'payroll_rx' },
  { name: 'Payroll Solutions', id: 'payroll_solutions' },
  { name: 'Payrolls Plus', id: 'payrolls_plus' },
  { name: 'PaySouth Payroll', id: 'paysouth_payroll' },
  { name: 'PCS HCM', id: 'pcs_hcm' },
  { name: 'PeopleWorx', id: 'peopleworx' },
  { name: 'Platinum Group', id: 'platinum_group' },
  { name: 'Premier Payroll Solutions', id: 'premier_payroll_solutions' },
  { name: 'PrimePay', id: 'primepay' },
  { name: 'PrismHR', id: 'prism_hr' },
  { name: 'Proliant', id: 'proliant' },
  { name: 'Proxus HR isolved', id: 'proxus_hr' },
  { name: 'Quick Payroll Solutions', id: 'quick_payroll_solutions' },
  { name: 'Quickbooks Payroll', id: 'quickbooks' },
  { name: 'Remote', id: 'remote' },
  { name: 'Robinson and Company', id: 'robinson_and_company' },
  { name: 'RPM Payroll', id: 'rpm_payroll' },
  { name: 'Run Powered by ADP', id: 'adp_run' },
  { name: 'Rush Tech Solutions', id: 'rush_tech_solutions' },
  {
    name: 'Sage 50 US Edition Accounting',
    id: 'sage_50_us_edition_accounting',
  },
  {
    name: 'Sage 50 Quantum Accounting',
    id: 'sage_50_quantum_accounting',
  },
  { name: 'Sage Payroll', id: 'sage_payroll' },
  { name: 'San Diego Back Office', id: 'san_diego_back_office' },
  { name: 'SAP SuccessFactors', id: 'successfactors' },
  { name: 'Sapling', id: 'sapling' },
  { name: 'Savant HCM Evolution', id: 'savant_hcm_evolution' },
  { name: 'Select HCM', id: 'select_hcm' },
  { name: 'Sequoia One', id: 'sequoia_one' },
  { name: 'Serendipity Payroll', id: 'serendipity_payroll' },
  { name: 'Solex Payroll', id: 'solex_payroll' },
  { name: 'Solutions HCM', id: 'solutions_hcm' },
  { name: 'Sonnon Properties', id: 'sonnon_properties' },
  { name: 'Source One Payroll', id: 'source_one_payroll' },
  { name: 'Southland Data Processing', id: 'southland_data_processing' },
  { name: 'Spin Capital', id: 'spin_capital' },
  { name: 'Square Payroll', id: 'square_payroll' },
  {
    name: 'Strategic Payroll Solutions isolv',
    id: 'strategic_payroll_solutions_isolved',
  },
  { name: 'SuitePeople (Oracle NetSuite)', id: 'suite_people' },
  { name: 'Switch Payroll', id: 'switch_payroll' },
  { name: 'SyncHR', id: 'sync_hr' },
  { name: 'The Payroll Company', id: 'the_payroll_company' },
  { name: 'Thread HCM', id: 'thread_hcm' },
  { name: 'Thrive HCM', id: 'thrive_hcm' },
  { name: 'Toast Payroll', id: 'toast_payroll' },
  { name: 'TriNet', id: 'trinet' },
  { name: 'Trustpoint', id: 'trustpoint' },
  { name: 'UKG Ready (Kronos)', id: 'ukg_ready' },
  { name: 'UltiPro', id: 'ulti_pro' },
  { name: 'VensureHR', id: 'vensure_hr' },
  {
    name: 'Viewpoint HR Management Spectrum',
    id: 'viewpoint_hr_management_spectrum',
  },
  {
    name: 'Viewpoint HR Management Vista',
    id: 'viewpoint_hr_management_vista',
  },
  { name: 'Viventium', id: 'viventium' },
  { name: 'Wagepoint', id: 'wagepoint' },
  { name: 'Wave Payroll', id: 'wave' },
  { name: 'WebHR', id: 'web_hr' },
  { name: 'whirks', id: 'whirks' },
  { name: 'Workday', id: 'workday' },
  { name: 'Workforce Solutions-Accu', id: 'workforce_solutions_accu' },
  { name: 'WorkGroup Payroll', id: 'workgroup_payroll' },
  { name: 'wurk', id: 'wurk' },
  { name: 'Xero', id: 'xero' },
  { name: 'Your Payroll Department', id: 'your_payroll_department' },
  { name: 'Zenefits', id: 'zenefits' },
  { name: 'Zoho Payroll', id: 'zoho_payroll' },
  { name: 'Zuma Payroll', id: 'zuma_payroll' },
  { name: '10Sense', id: '10sense' },
  { name: 'Other', id: 'other' },
];
