import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { getClient } from '../../../api-utils/general-utils';
import {
  BankAccount,
  CATransactionAvailability,
  TransactionAvailability,
  UpdateBankAccountDto,
} from '../../../api-utils/generated-client';
import FooterButtons from '../../../Footer/footer-buttons';
import TitleSection from '../../../Header/title-section';
import Loader from '../../../widgets/Loader';
import './index.scss';
import { formatDate, formatNumberAsDollars } from '../../../utils';
import { DateTime } from 'luxon';
import PayroButton from '../../../widgets/PayroButton';
import RequestFundingTitle from '../request-funding-title';
import { calculateTotalPayback } from '../utils';
import PayroSelect from '../../../widgets/PayroSelectv2';
import {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
} from 'recoil';
import BankFetcher from '../DataFetchers/bank-fetcher';
import {
  docVersionState,
  signatureDataUrlState,
} from '../../../recoil-state/application-stage-states';
import {
  bankAccountsState,
  dealDraftState,
  sectionState,
  transactionAvailabilityState,
  catransactionAvailabilityState,
  needsManualFormState,
  showHideClassNameModalManualFormState,
  bankAccountToUpdateState,
  chaseBankAccountsArrayState,
} from '../../../recoil-state/request-funding-states';
import ManualBankModal from '../../../widgets/PlaidConnector/ManualBankModal';
import { MessageContext } from '../../../context';
import OpenModalLink from './OpenManualModalLink';
import { isPlaidConnectedState } from '../../../recoil-state/general-states';
import InfoMessage from '../../../widgets/InfoMessage';

interface myProps {
  confirmButtonClick: Function;
}

interface ConfirmationInfoLine {
  theLabel: string;
  theValue?: any;
}

const CashAdvanceConfirm = (props: myProps) => {
  const section = useRecoilValue(sectionState);
  const [dealRecord, setDealRecord] = useRecoilState(dealDraftState);
  const [bankAccounts, setBankAccounts] =
    useRecoilState(bankAccountsState);
  const [totalCost, setTotalCost] = useState<number>(0)
  const [signatureDataUrl, setSignaturedataUrl] = useRecoilState<any>(
    signatureDataUrlState,
  );
  const [updatingBank, setUpdatingBank] = useState<boolean>(false);
  const [docVersion, setDocVersion] = useRecoilState<string>(docVersionState);
  const [fundingTime, setFundingTime] = useState<any>('')
  const [signedAch, setSignedAch] = useState(false);
  const [showClickedConfirmLoader, setShowClickConfirmedLoader] =
    useState(false);
  const [transactionAvailability, setTransactionAvailability] =
    useRecoilState<TransactionAvailability>(
      transactionAvailabilityState,
    );
  const [catransactionAvailability, setCATransactionAvailability] =
    useRecoilState<CATransactionAvailability>(
      catransactionAvailabilityState,
    );
  const [showHideClassName1, setShowHideClassName1] = useState('modal display-none');
  const [legalCompanyName, setLegalCompanyName] = useState<string>();
  const [needsManualForm, setNeedsManualForm] = useRecoilState(
    needsManualFormState,
  );
  const [
    showHideClassNameModalDisplay,
    setShowHideClassNameModalDisplay,
  ] = useRecoilState(showHideClassNameModalManualFormState);
  const [bankAccountToUpdate, setBankAccountToUpdate] =
    useRecoilState<UpdateBankAccountDto>(bankAccountToUpdateState);

  let [chaseBankAccountsArray, setChaseBankAccountsArray] =
    useRecoilState(chaseBankAccountsArrayState);
  const messageContext = useContext(MessageContext);
  const setArray = async () => {
    let mainArray: any = [];
    const chaseAccounts = bankAccounts
      .filter((account) => account.plaid_id)
      .filter((name) => name.bank_name?.toLowerCase() == 'chase');
    chaseAccounts.forEach(
      (account, index) =>
        (mainArray = [
          ...mainArray,
          {
            key: Math.random(),
            uuid: account.uuid,
            bankRoutingNumber:
              account.is_real_bank_numbers == true
                ? account.bank_routing_number?.toString()
                : '',
            bankRoutingNumberConfirm:
              account.is_real_bank_numbers == true
                ? account.bank_routing_number?.toString()
                : '',
            bankAccountNumber:
              account.is_real_bank_numbers == true
                ? account.bank_account_number?.toString()
                : '',
            bankAccountNumberConfirm:
              account.is_real_bank_numbers == true
                ? account.bank_account_number?.toString()
                : '',
          },
        ]),
    );
    await setChaseBankAccountsArray(mainArray);
  };
  useEffect(() => {
    const getTransactionAvailability = async () => {
      const client = await getClient();
      if (client) {
        const availability = await client.dealsControllerGetCATransactionAvailability();
        setCATransactionAvailability(availability.data);
        let t_today = DateTime.now().toISODate()
        let t_tomorrow = DateTime.now().plus({ days: 1}).toISODate();
        // console.log('availability?.data?.fundingDate',availability?.data?.fundingDate)
        // console.log('t_today',t_today)
        if(availability?.data?.fundingDate && t_today == availability.data.fundingDate){
          setFundingTime('today')
        }else if (availability?.data?.fundingDate && t_tomorrow == availability.data.fundingDate){
          setFundingTime('tomorrow')
        }else{
          setFundingTime(availability.data.fundingDate)
        }
      }
    };
    getTransactionAvailability().then(() => {});
    if(dealRecord && dealRecord?.TotalAdvanceCost){
      setTotalCost((dealRecord?.TotalAdvanceCost+150));
    }
  }, []);

  useEffect(() => {
    const getCompanyName = async () => {
      if (!legalCompanyName) {
        const client = await getClient();
        if (client) {
          const account = await client.accountsControllerGetMyInfo();
          setLegalCompanyName(account.data.legal_name);
        }
      }
    };
    getCompanyName();

    {
      bankAccounts.length > 0 && setArray();
    }
  }, [bankAccounts, needsManualForm]);
  if (!catransactionAvailability.fundingDate) {
    return <Loader />;
  }

  if (
    !dealRecord.funding_amount ||
    !dealRecord.PlanType ||
    !bankAccounts
  ) {
    return (
      <div>
        <Loader message="1 second please" />
        <BankFetcher getFromApiEvenIfEmptyArray="yes" />
      </div>
    );
  }
// console.log('docVersion---------',docVersion)
// console.log('dealRecord---------',dealRecord)
// console.log('signatureDataUrl---------',signatureDataUrl)
// console.log('TransactionAvailability---------',catransactionAvailability)
  const depositBank = bankAccounts.find(
    (ba) => ba.uuid === dealRecord.deposit_bank_account,
  );
  const withdrawalBank = bankAccounts.find(
    (ba) => ba.uuid === dealRecord.withdrawal_bank_account,
  );

  const totalPayback = formatNumberAsDollars(
    dealRecord.TotalAmountPayback
    // calculateTotalPayback(
    //   dealRecord.funding_amount,
    //   dealRecord.selected_num_of_weeks_in_portal,
    // ),
  );

//   const totalInterest =
//     dealRecord.funding_amount *
//     0.015 *
//     dealRecord.selected_num_of_weeks_in_portal;

  const paybackDate = DateTime.fromISO(
    transactionAvailability.fundingDate,
  )
    .plus({ weeks: dealRecord.selected_num_of_weeks_in_portal })
    .toLocaleString();

  const getAccountLastFour = (b: any) => {
    if (
      b.account_last_four != '' ||
      b.account_last_four != undefined ||
      b.account_last_four != null
    ) {
      return b.account_last_four;
    } else {
      getLastFourCharacters(b.bank_account_number);
    }
  };
  const depositBankSelect = (
    <>
    <select 
      className='selectbank' 
      value={depositBank?.uuid} 
      onChange={async(e) => {
        setUpdatingBank(true)
        let uuid = e.target.value;
        const bankAccountWithThisUuid = bankAccounts.find(
          (b) => b.uuid === uuid,
        );
        const client = await getClient();
        
        if (!client) {
          setUpdatingBank(false)
          return;
        }
        // console.log('wwbankAccounts',bankAccounts)
        // console.log('ssbankAccountWithThisUuid',bankAccountWithThisUuid)
        if (!bankAccountWithThisUuid) {
          setUpdatingBank(false)
          return;
        }
        
        await setDealRecord({
          ...dealRecord,
          deposit_bank_account: uuid,
          withdrawal_bank_account: uuid,
        });
        await client.bankAccountsControllerSelectForDeposit(true, {
          uuid: uuid,
        });
        await client.bankAccountsControllerSelectForWithdrawal(true, {
          uuid: uuid,
        });
        setUpdatingBank(false)
        if (
          // bankAccountWithThisUuid.bank_name?.toLowerCase() ==
          //   'chase' &&
          bankAccountWithThisUuid.is_real_bank_numbers == false
        ) {
          setBankAccountToUpdate({
            ...bankAccountToUpdate,
            uuid: bankAccountWithThisUuid.uuid,
            bank_name: bankAccountWithThisUuid.bank_name,
            bank_account_type:
              bankAccountWithThisUuid.bank_account_type,
            account_last_four:
              bankAccountWithThisUuid.account_last_four,
          });
          if (section == 'more-funding') {
            await setArray();
          }
          setNeedsManualForm(true);
          // await setShowHideClassNameModalDisplay(
          //   'modal display-block',
          // );
        } else {
          setNeedsManualForm(false);
          // await setShowHideClassNameModalDisplay(
          //   'modal display-none',
          // );
        }
      }}
      >
      <option value=''>Select Bank</option>
      { bankAccounts.map((b) => (
        <option value={b.uuid}>
          {`${
            b.bank_name ? b.bank_name : ''
          } ${getAccountLastFour(b)}`}
        </option>
        
      ))
      }
    </select>
    {/* <PayroSelect
      wrapperAdditionalClasses="select-content-bank"
      options={bankAccounts.map((b) => {
        return {
          label: `${
            b.bank_name ? b.bank_name : 'Select Bank'
          } ${getAccountLastFour(b)}`,
          value: b.uuid,
        };
      })}
      onSelect={async (uuid: string) => {
        const bankAccountWithThisUuid = bankAccounts.find(
          (b) => b.uuid === uuid,
        );
        // console.log('bankAccountWithThisUuid',bankAccountWithThisUuid)
        const client = await getClient();
        if (!client) {
          return;
        }
        if (!bankAccountWithThisUuid) {
          return;
        }
        await setDealRecord({
          ...dealRecord,
          deposit_bank_account: uuid,
          withdrawal_bank_account: uuid,
        });
        await client.bankAccountsControllerSelectForDeposit(true, {
          uuid: uuid,
        });
        await client.bankAccountsControllerSelectForWithdrawal(true, {
          uuid: uuid,
        });
        if (
          // bankAccountWithThisUuid.bank_name?.toLowerCase() ==
          //   'chase' &&
          bankAccountWithThisUuid.is_real_bank_numbers == false
        ) {
          setBankAccountToUpdate({
            ...bankAccountToUpdate,
            uuid: bankAccountWithThisUuid.uuid,
            bank_name: bankAccountWithThisUuid.bank_name,
            bank_account_type:
              bankAccountWithThisUuid.bank_account_type,
            account_last_four:
              bankAccountWithThisUuid.account_last_four,
          });
          if (section == 'more-funding') {
            await setArray();
          }
          setNeedsManualForm(true);
          // await setShowHideClassNameModalDisplay(
          //   'modal display-block',
          // );
        } else {
          setNeedsManualForm(false);
          // await setShowHideClassNameModalDisplay(
          //   'modal display-none',
          // );
        }
      }}
      selectName="deposit-bank"
      placeholderText="deposit bank number"
      defaultSelectedValue={depositBank?.uuid}
    /> */}
    </>
  );
  const withdrawalBankSelect = (
    <PayroSelect
      wrapperAdditionalClasses="select-content-bank"
      options={bankAccounts.map((b) => {
        return {
          label: `${
            b.bank_name ? b.bank_name : ''
          }  ${getAccountLastFour(b)}`,
          value: b.uuid,
        };
      })}
      onSelect={async (uuid: string) => {
        const bankAccountWithThisUuid = bankAccounts.find(
          (b) => b.uuid === uuid,
        );
        const client = await getClient();
        if (!client) {
          return;
        }
        if (!bankAccountWithThisUuid) {
          return;
        }
        await setDealRecord({
          ...dealRecord,
          withdrawal_bank_account: uuid,
        });

        if (
          bankAccountWithThisUuid.bank_name?.toLowerCase() ==
            'chase' &&
          bankAccountWithThisUuid.is_real_bank_numbers == false
        ) {
          setBankAccountToUpdate({
            ...bankAccountToUpdate,
            uuid: bankAccountWithThisUuid.uuid,
            bank_name: bankAccountWithThisUuid.bank_name,
            bank_account_type:
              bankAccountWithThisUuid.bank_account_type,
            account_last_four:
              bankAccountWithThisUuid.account_last_four,
          });
          if (section == 'more-funding') {
            await setArray();
          }
          setNeedsManualForm(true);
          await setShowHideClassNameModalDisplay(
            'modal display-block',
          );
        } else {
          setNeedsManualForm(false);
          await setShowHideClassNameModalDisplay(
            'modal display-none',
          );
        }

        await client.bankAccountsControllerSelectForWithdrawal(true, {
          uuid: uuid,
        });
      }}
      selectName="withdrawal-bank"
      placeholderText="withdrawal bank number"
      defaultSelectedValue={withdrawalBank?.uuid}
    />
  );

  const confirmationInfo: ConfirmationInfoLine[] = [
    // { theLabel: 'For Payroll Date', theValue: DateTime.fromISO(repaymentRecord.payroll_due_date as string).toLocaleString() },
    // { theLabel: "Payback Date:", theValue: paybackDate },
    // {
    //   theLabel: "Total Interest:",
    //   theValue: formatNumberAsDollars(totalInterest),
    // },
    // {
    //   theLabel: "Total Interest %:",
    //   theValue: `${1.5 * dealRecord.selected_num_of_weeks_in_portal}%`,
    // },

    { theLabel: 'Total Payback:', theValue: totalPayback },
    { theLabel: 'Funds sent to Bank:', theValue: depositBankSelect },
    { theLabel: 'Repayment Bank:', theValue: withdrawalBankSelect },
  ];

  const infoDisplay = confirmationInfo.map((el, idx) => {
    return (
      <div key={idx * 10} className="confirmation-info-wrapper">
        <div className="confirmation-info-label">{el.theLabel}</div>
        <div className="confirmation-info-value">{el.theValue}</div>
      </div>
    );
  });
  const days = {
    "1" : "Monday",
    "2" : "Tuesday",
    "3" : "Wednesday",
    "4" : "Thursday",
    "5" : "Friday",
    "6" : "Saturday",
    "7" : "Sunday",
  }
  const months = {
    "1" : "January",
    "2" : "February",
    "3" : "March",
    "4" : "April",
    "5" : "May",
    "6" : "June",
    "7" : "July",
    "8" : "August",
    "9" : "September",
    "10" : "October",
    "11" : "November",
    "12" : "December",
  }
  useEffect(() => {
    let dateobj = DateTime.now().toObject()
    if(fundingTime == ''){
      if(dateobj && dateobj.hour <= 12){
        if(dateobj && dateobj.minute <= 50){
          setFundingTime('today')
        }else{
          if(DateTime.now().weekday == 6){
            setFundingTime(`${days[DateTime.now().plus({ days: 2}).weekday]}, ${DateTime.now().plus({ days: 2}).day} ${months[DateTime.now().plus({ days: 2}).month]}`)
          }else if(DateTime.now().weekday == 7){
            setFundingTime(`${days[DateTime.now().plus({ days: 1}).weekday]}, ${DateTime.now().plus({ days: 1}).day}  ${months[DateTime.now().plus({ days: 1}).month]}`)
          }else{
            setFundingTime('tomorrow')
          }
        }
      }else{
        if(DateTime.now().weekday == 6){
          setFundingTime(`${days[DateTime.now().plus({ days: 2}).weekday]}, ${DateTime.now().plus({ days: 2}).day}  ${months[DateTime.now().plus({ days: 2}).month]}`)  
        }else if(DateTime.now().weekday == 7){
          setFundingTime(`${days[DateTime.now().plus({ days: 1}).weekday]}, ${DateTime.now().plus({ days: 1}).day}  ${months[DateTime.now().plus({ days: 1}).month]}`)  
        }else{
          setFundingTime('tomorrow')
        }
      }
    }
  })

  const Read=()=>{
    setShowHideClassName1('modal display-block');
  }
  if(updatingBank){
    return (<Loader message="Updating bank details" />);
  }


  return (
    <>


    <div>
    <div id="request-funding-title-wrapper" className="onboarding">
      {/* <div id="request-funding-section-number" className="onboarding">4
      <span id="title-period-after-number">.</span></div> */}
      <div id="request-funding-section-title-text-wrapper">
        <h1 id="request-funding-section-title" className="onboarding">Confirm the Details</h1>
        <h1 id="request-funding-section-subtitle" className="onboarding">Review the details of your first advance</h1>
    </div></div>
      {/* <div className='confirm-head'>
        <div className='confirm-pageno.'>
          <p>4</p>
        </div>
        <div>
        <h1 >Confirm the Details</h1>
        <h5 >Review the details of your first Advance</h5>
        </div>
      </div> */}

      <div className='outer-bx-new'>
        <div>
          <p id='Payback-txt'>Total Payback</p>
          <p id='Payback-cost'> {formatNumberAsDollars(dealRecord.TotalAmountPayback)}</p>
          <p id='Payback-txt'>Total Cost  {formatNumberAsDollars(totalCost)}</p>
        </div>
        <div>
        <div className='border-bx'>
          <p id='Payback-txt-amt'>Funding Amount</p>
          <p id='cost-amt'>{formatNumberAsDollars(dealRecord.funding_amount)}</p>
        </div>
        </div>
      </div>

      <div className='outer-bx'>
        <div>
          <p id="Estimated-Weekly-Payment">Estimated Weekly Payment</p>
          { 
            dealRecord.EstFinalPaymentDate 
            ? 
              <p id='Payback-txt'>Estimated final payment date {(dealRecord.EstFinalPaymentDate)}</p>
            : 
              ''
          }
        </div>
        <div>
          <p id='cost-amttwo'>{formatNumberAsDollars(dealRecord.WeeklyPaymentAmount)}</p>
        </div>
      </div>

      <div className='outer-bx'>
        <div>
          <p id="Estimated-Weekly-Payment">Bank Account:</p>
          <p id='Payback-txt'>Change your preferred payment account via <br/> the dashboard.</p>
        </div>
        {/* <div className='border-bx'> */}
          <div className='bank-select-sec'>{depositBankSelect}</div>
        {/* </div> */}
      </div>

      <div
          className={
            section == 'more-funding'
              ? 'ach-container-more'
              : 'ach-container'
          }
        >
          <div className='achbox'>
          <p className="ach-header-text">
            <input
              type="checkbox"
              onChange={() => setSignedAch(!signedAch)}
              className="grey-square"
              // className="checkbox-design"
            />
            <span className="ach-span">ACH Debit Authorization:</span>
          </p>
          <span className='readfull' onClick={Read}>Read full</span>
          <div id="myModal" className={showHideClassName1}>
                <div className="modal-content-repay">
                <span
                  onClick={() => {
                    setShowHideClassName1('modal display-none');
                  }}
                  className="close"
                >
                  &times;
                </span>
                <>
                  <h2>ACH AUTHORIZATION</h2>
                    <div>
                        <div><h4><u>Authorization:</u></h4>Business identified authorizes Payro Capital LLC or its affiliate Payro 
                        finance LLC and its agents to initiate automated clearing house (“ACH”) electronic credit 
                        entries to the following business financial institution account (and, if necessary, electronically 
                        debit the financial institution account to correct erroneous ACH credits), as follows:
                        </div>
                      <h4><u>Amount(s) and Date(s) of ACH credit(s): </u></h4>
                      <p>ACH credits will be initiated in accordance with the terms of the Merchant Cash Advance
                         Agreement (“MCA Agreement”)</p>
                      <h4><u>Business Account for Payments.</u></h4>
                      <p>The Business represents and warrants that the Business Account provided for  Payment is used 
                        solely for business and commercial purposes and NOT for any personal, family or household purpose.</p>
                      <h4><u>Account Information.</u></h4>
                      <p>The Business certifies that all information provided with respect to the Business Account MCA Payments is
                         accurate and the Business will undertake to inform Payro Capital, in writing, of any change to the Business
                         Account for MCA Payments information provided in this ACH Authorization prior to the next payment due date.</p>
                      <h4><u>Financial Institution Verification.</u></h4>
                      <p>The Business acknowledges that its Financial Institution is not required to verify that any payment
                         was issued in accordance with the particulars of this ACH Authorization, including, without limitation,
                          the amount of any payment.</p>
                      <h4><u>Agreement.</u></h4>
                      <p>By signing below, the Business’ authorized representative acknowledges he/she has read, understands,
                         and agrees to all of the terms of this ACH Authorization, and has received a copy of this ACH 
                         Authorization to keep. The Business owns the Business Account for Payments and does not require anyone
                          else’s consent to authorize ACH credits to or debits from the Business Account for Payments. 
                          The Business understands and agrees that the ACH transactions authorized under this ACH Authorization
                           must comply with all applicable law and the rules and guidelines issued from time to time by NACHA
                            (formerly known as the National Automated Clearing House Association).</p>
                    </div>
                </>
                </div>
            </div>
          </div>
          <p className="ach-text">{`Each Merchant and each Owner understands that by signing this Agreement, they are providing ‘written instructions’ to PAYRO under the Fair Credit Reporting Act, authorizing PAYRO to obtain information from their personal credit profile or other information from Experian, TransUnion, and Equifax. Each Merchant and each
           Guarantor authorizes PAYRO to obtain such information solely to conduct a pre-qualification for credit.`}</p>
          {/* <p className="ach-text">{`I hereby authorize Payro Finance LLC to debit from ${
            withdrawalBank?.bank_name
          }  ${getLastFourCharacters(
            withdrawalBank?.bank_account_number,
          )} account according to the terms of all signed contracts between ${legalCompanyName} 
          company and Payro, and if necessary, initiate adjustments for any transactions credited 
          or debited in error.`}</p> */}
        </div>
        {
        fundingTime == 'today' || fundingTime == 'tomorrow' 
        ?
          <div
            className={
              section == 'more-funding'
                ? 'funding-date-more'
                : 'funding-date'
            }
          >
            <p className="funding-date-message">
              The funds will arrive in your account {fundingTime}
            </p>
            <p className="funding-date-explanation">
              (Deadline for same-day funding is 2:00 PM EST)
            </p>
          </div>
        :
          <div
            className={
              section == 'more-funding'
                ? 'funding-date-more'
                : 'funding-date'
            }
          >
            <p className="funding-date-message">
              The funds will arrive in your account by {fundingTime && formatDate(fundingTime)}
            </p>
            <p className="funding-date-explanation">
            
            </p>
          </div>
       } 
      {showClickedConfirmLoader && (
        <Loader message="Hold on a few seconds while we process your request!" />
      )}
      {/* {
        console.log('signedAch',signedAch)
      }
      {
        console.log('depositBank',depositBank)
      }
      {
        console.log('withdrawalBank',withdrawalBank)
      } */}
      {!showClickedConfirmLoader && (
        <PayroButton
            className={
              section == 'onboarding'
                ? 'get-funded-button-onboarding'
                : 'get-funded-button-repeat-funding'
            }
            centered={true}
            onClick={() => {
              setShowClickConfirmedLoader(true);
              props.confirmButtonClick();
            }}
            buttonSize="large"
            disabled={
              signedAch == false ||
              (!depositBank || !withdrawalBank)
            }
          >
            Confirm Funding
        </PayroButton>
      )}
    </div>
    </>
  );
};

const getLastFourCharacters = (s: string | undefined) => {
  if (!s) {
    return '';
  }

  if (s.length <= 4) {
    return s;
  }

  return s.substr(s.length - 4, 4);
};

export default CashAdvanceConfirm;
