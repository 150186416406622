import { GetRepaymentDto } from '../../../../api-utils/generated-client';
import { formatDate, formatNumberAsDollars } from '../../../../utils';
import { getLoanIdToDisplay } from '../../ledger-utils';
import '../index.scss';
import { useRecoilValue } from 'recoil';
import Repayment from './repayment';
import { repaymentsState } from '../../../../recoil-state/general-states';
import detailsIcon from '../../../../common-icons/details-icon.svg';
import { useHistory } from 'react-router-dom';
import PdfStatements from '../../PdfStatements';

interface RepaymentsProps {}

const PendingPayroll = (props: RepaymentsProps) => {
  let history = useHistory();
  const repayments = useRecoilValue(repaymentsState);
  const getRepaymentDisplay = (repayment: GetRepaymentDto) => {
    return <Repayment {...repayment} key={repayment.uuid} />;
  };
  let processingRepayments: any[] = [];
  let stillInRepaymentRepayments: any[] = [];
  let completeRepayments: any[] = [];
  if (repayments) {
    repayments
      .filter((repayment) => repayment.status != 'New')
      .forEach((repayment) => {
        // if (repayment.status == 'Complete') {
        //   completeRepayments.push(getRepaymentDisplay(repayment));
        // } else 
        if (
          repayment.status == 'Request In - Review Payroll' ||
          repayment.status == 'Funding Approved, Awaiting Wire' ||
          repayment.status == 'Wire Entered, Awaiting Approval'
        ) {
          processingRepayments.push(getRepaymentDisplay(repayment));
        }
        //  else {
        //   stillInRepaymentRepayments.push(
        //     getRepaymentDisplay(repayment),
        //   );
        // }
      });
  }

  return (
    <>
        {processingRepayments.length > 0 && (
          <>
            <div id="repayments-container">
              <p
                id="still-in-repayment-title"
                className="repayment-section-title"
              >
                Pending Payroll Advances{' '}
              </p>

              <div className="still-in-repayment-header">
                <div className="ActiveRepayment-col">ID</div>
                <div className="ActiveRepaymentarr-col">Payments Made
                <img className="" src="Arrowupper.svg" height="22.01" width="22.01" style={{ marginLeft: '10px'}} />
                </div>
                <div className="ActiveRepaymentarr-col">
                  Outstanding Balance
                  <img className="" src="Arrowupper.svg" height="22.01" width="22.01" style={{ marginLeft: '10px'}} />
                  </div>
                <div className="ActiveRepayment-col">Final payment date</div>
                <div className="ActiveRepayment-col">
                  Date Funded
                </div>
                <div className="ActiveRepayment-col"> </div>
              </div>
              {processingRepayments}
            </div>
          </>
        )}
    </>
  );
};

export default PendingPayroll;
