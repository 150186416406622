import React, { useState, useRef } from 'react';
import TitleSection from '../../Header/title-section';
import './index.css';

export default function PrivacyPolicy() {
  return (
    <div id="forgot-password-page-wrapper" className="main-body">
      <div>
        <h4 className="header">Privacy Policy</h4>
        <h5 className="sub">Effective 12/01/2023</h5>
        <h5> Consumer Privacy Disclosure</h5>
        <div className="text">
          This website is owned and operated by Payro Finance, LLC.
          Payro Finance, LLC and its subsidiaries. This privacy policy
          applies to www.payrofinance.com and all websites and mobile
          apps owned and operated by Payro Finance on which this
          privacy policy is displayed as well as Payro Finance
          affiliates.
        </div>
        <h5> Alright, what data do we collect?</h5>

        <div className="text">
          a. We collect data that could identify you such as your
          name, address, telephone number, mobile number, email
          address, and Social Security Number. We may also collect
          this type of information from you about others, such as
          other owners or representatives of your company filling out
          our loan application. b. We may also collect your credit
          history, credit scores, your transaction history for bank
          accounts that you choose to connect with our software, in
          order to determine whether to open or keep open a line of
          credit for your company. c. We may also collect information
          such as demographic data, data about your online activity,
          and other information that is not used to identify you.
        </div>

        <h5>How do we manage to get that data? </h5>
        <div className="text">
          1. We collect information from you when you enter it
          directly in our software or into the software of one of our
          referral partners. We also get data if you provide it to us
          or our partners through other means, like phone or email. 2.
          We are also able to use some of the information you provide
          in ways that enable us to get even more information from
          services like credit bureaus or criminal check services who
          may have data on your financial profile or other demographic
          information. 3. We also make get information from the
          automatic connections you make with our software to pull
          data like your IP address and general geographic profile. We
          use this information for our own security.
        </div>

        <h5>What do we do with that data?</h5>
        <div className="text">
          It’s very simple. We need to know our customer to know if we
          are comfortable lending them money. We don’t collect any
          data for any non-business need.
        </div>
        <h5> Who Do We Share Your Information With? </h5>
        <div className="text">
          Payro Finance Affiliates: We may share information about you
          or provided by you with the Payro Finance affiliates.
          Referral Partners: When you come to our site having been
          referred to by one of our partners, we update our partners
          with loan amount information. Payro Finance Service
          Providers: We may share information with Service Providers
          that perform certain services on our behalf including, but
          not limited to, credit bureaus, marketing partners, or
          entities providing business analysis and/or Website or
          product support. Other Situations: We reserve the right to
          disclose your data for other reasons, such as to comply with
          law, law enforcement or government, or to exercise our own
          rights. We may also proactively disclose information to
          relevant parties to assist in protecting our business and
          employees, or to report illegal activity. Additionally, if
          Payro Finance, as a business, undergoes a transaction, it
          will likely be necessary to disclose some of the data of our
          customers. If any state, local, or federal law or regulation
          requires or requests us to disclose any information to the
          government or regulatory body, we will do that too. This may
          include the terms of the loan / advance you took.
        </div>
        <h5> Marketing and Communications</h5>
        <div className="text">
          a. Communications: You may adjust your communications
          preferences through your Payro Finance profile. You may also
          modify your preferences by clicking on an unsubscribe link
          at the bottom of certain emails sent from Payro Finance. b.
          Advertising on third party sites: We may use third-party
          tracking technology. This technology allows targeted
          advertisements to you from Payro Finance businesses.
          Although we do not currently engage in this advertising, we
          may choose to at any time, at which you can opt out on our
          website.
        </div>
        <h5> How do you access and update your data?</h5>
        <div className="text">
          The appropriate method(s) for accessing your information
          will be to log into your account on
          https://portal.payrofinance.com or email
          processing@payrofinance.com
        </div>
        <h5> How do we protect your data?</h5>
        <div className="text">
          We have a comprehensive security policy to do everything in
          our hands to protect your information from unauthorized
          access. We use best practices for security in our IT and
          Software architecture and development, undergo penetration
          tests, and have procedural policies to enhance our security.
          We follow the SOC framework.
        </div>
        <h5>
          {' '}
          Will this privacy policy change? Do I have to be on top of
          that?
        </h5>
        <div className="text">
          We may update this privacy policy at any time. When we do,
          we will revise the date at the top of this page. We
          encourage you to frequently check this page for any changes
          to stay informed about how we are helping to protect the
          information we collect. It is your responsibility to review
          this privacy policy periodically understand relevant changes
        </div>
        <h5>
          {' '}
          What about visiting our websites or using our software from
          outside the United States?{' '}
        </h5>
        <div className="text">
          This site is only for those in the United States. It may or
          may not be compliant with regulations of other countries.
        </div>
        <h5>
          {' '}
          How do we comply with Children’s Online Privacy Protection
          Rule?
        </h5>
        <div className="text">
          We do not collect information from anyone under 18. If you
          know that you or someone else has provided information on
          someone under 18, please contact processing@payrofinance.com
          so that we can promptly delete it.
        </div>
        <h5> How do I opt out of online tracking?</h5>
        <div className="text">
          Like many companies online, we use services provided by
          Google. These services rely on tracking technologies – such
          as cookies and web beacons – to collect directly from your
          device information about your browsing activities, your
          interactions with websites, and the device you are using to
          connect to the Internet. If you don’t want this, you can
          block cookies in your browser. This site shows how for a
          number of browsers:
          https://www.cookieyes.com/blog/how-to-block-cookies/ Also
          see
          https://www.businessinsider.com/guides/tech/opt-out-of-website-cookies-avoid-targetedads
          about avoiding cookies and targeted ads.
        </div>
        <h5> Still have questions?</h5>
        <div className="text">
          If you have any questions about this privacy policy, or what
          we do in general as a business in any way, don’t hesitate to
          shoot us an email processing@payrofinance.com
        </div>
      </div>
    </div>
  );
}
