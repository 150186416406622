import { useEffect, useState } from 'react';
import { components } from '../../../api-utils/swagger-schema';
import FooterButtons from '../../../Footer/footer-buttons';
import TitleSection from '../../../Header/title-section';
import { getClient } from '../../../api-utils/general-utils';
import ContactInfo, { ContactInfoFields } from './contact-info';
import Loader from '../../../widgets/Loader';
import PayroButton from '../../../widgets/PayroButton';
import PurpleAddIcon from '../../../common-icons/pruple-add.svg';
import PayroInput from '../../../widgets/PayroInput';
import PayroSelect from '../../../widgets/PayroSelect';
import StatesAbbreviations from '../CompanyInfo/states';
import {
  CreateContactDto,
  GetAccountDto,
} from '../../../api-utils/generated-client';
import { v4 as uuidv4 } from 'uuid';
import { PLACEHOLDER, contactInfoToDto } from './contact-utils';
import { useContext } from 'react';
import { MessageContext } from '../../../context';
import InfoMessage from '../../../widgets/InfoMessage';
import PayroRadioButtonGroup from '../../../widgets/PayroRadioButtonGroup';
import './index.css';
import NextIcon from '../../../common-icons/next-arrow.svg';
import { Contact } from '../../../common-types';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  contactsState,
  userContactUuidState,
  userIsOwnerState,
  contactDisableState,
} from '../../../recoil-state/application-stage-states';
import {
  accountRecordState,
  userInfoState,
} from '../../../recoil-state/general-states';
import { SlowBuffer } from 'buffer';

interface OwnerInfoProps {
  contactCrudActions: any;
  contacts: Partial<Contact>[] | null;
  setContacts: Function;
  userIsOwner: 'yes' | 'no' | undefined;
  userContactUuid: string | null;
  setUserIsOwner: Function;
}

// const noValidations = {
//     legalCompanyName: false,
//     einNumber: false,
//     homeAddressLine1: false,
//     homeCity: false,
//     homeState: false,
//     homeZip: false,
//     industryType: false,
//     website: false,
//     annualCompanyRevenue: false
// }
const noValidations = {
  title: false,
  homeAddressLine1: false,
  homeCity: false,
  homeState: false,
  homeZip: false,
};

export default (props: OwnerInfoProps) => {
  const [AccountInfo, setAccountInfo] = useRecoilState<GetAccountDto>(
    accountRecordState,
  );
  const [validationsToShow, setValidationsToShow] =
    useState<any>(noValidations);
  const [contacts, setContacts] =
    useRecoilState<Partial<Contact>[]>(contactsState);
  const [userContactUuid, setUserContactUuid] = useRecoilState<
    string | null
  >(userContactUuidState);
  const [userIsOwner, setUserIsOwner] =
    useRecoilState(userIsOwnerState);
  const [contactDisable, setContactDisable] = useRecoilState(
    contactDisableState,
  );

  const [mainContact, setMainContact] =
    useState<Partial<Contact>[]>();
  const userInfo = useRecoilValue(userInfoState);
  const contactsDisplay = contacts.map((contactInfo, idx) => (
    <ContactInfo
      key={contactInfo.uuid}
      {...contactInfo}
      uuid={contactInfo.uuid as string}
      deleteContact={() =>
        props.contactCrudActions.deleteContact(idx)
      }
      saveContact={(
        contactInfoToSave: Partial<ContactInfoFields>,
      ) => {
        props.contactCrudActions.saveContact(idx, contactInfoToSave);
      }}
      isUser={contactInfo.uuid == userContactUuid}
    />
  ));
  const contactsDisplayOwner = contacts.map((contactInfo, idx) => (
    <ContactInfo
      key={contactInfo.uuid}
      {...contactInfo}
      uuid={contactInfo.uuid as string}
      deleteContact={() =>
        props.contactCrudActions.deleteContact(idx)
      }
      saveContact={(
        contactInfoToSave: Partial<ContactInfoFields>,
      ) => {
        props.contactCrudActions.saveContact(idx, contactInfoToSave);
      }}
      isUser={contactInfo.uuid == userContactUuid}
    />
  ));

  const validationFunctions: { [key: string]: boolean } = {
    homeAddressLine1:
      !AccountInfo?.home_address ||
      AccountInfo?.home_address.length < 1,
    homeCity:
      !AccountInfo?.home_address_city ||
      AccountInfo?.home_address_city.length < 1,
    homeState:
      !AccountInfo?.home_address_state ||
      AccountInfo?.home_address_state.length < 1,
    homeZip:
      !AccountInfo?.home_address_zip ||
      AccountInfo?.home_address_zip.length < 4,
  };
  // console.log('contacts========>',contacts)
  const isEverythingValid = (): boolean => {
    if (!contacts) {
      return false;
    }
    if (contacts.length > 1) {
      for (let i = 1; i < contacts.length; i++) {
        let nonUserOwner = contacts[i];
        let requiredFields = [
          nonUserOwner.first_name,
          nonUserOwner.last_name,
          nonUserOwner.email,
          nonUserOwner.phone,
        ];
        for (let j = 0; j < requiredFields.length; j++) {
          if (!requiredFields[j]) {
            return false;
          }
          if (requiredFields[j]!.length < 1) {
            return false;
          }
        }
      }
    }
    if (userIsOwner == 'yes') {
      return true;
    }
    if (userIsOwner == 'no' && contacts.length < 2) {
      return false;
    }
    return false;
  };
  useEffect(() => {
    let contactsCopy = [...contacts];

    if (userIsOwner == 'yes') {
      const newContactInfo = { percent_of_ownership: 100 };
      contactsCopy[0] = { ...contactsCopy[0], ...newContactInfo };
      setContacts(contactsCopy);
    }
    if (userIsOwner == 'no') {
      const newContactInfo = {
        social_security_number: '',
        percent_of_ownership: 0,
      };
      contactsCopy[0] = { ...contactsCopy[0], ...newContactInfo };
      setContacts(contactsCopy);
      //console.log('ContactsUseEfffect', contacts);
    }
  }, []);

  if ((props.contacts ?? []).length < 1) {
    return <Loader />;
  }
  const setPercentOfOwnership = async () => {
    const contactInfoToSave = { percent_of_ownership: 0 };
    await props.contactCrudActions.saveContact(0, contactInfoToSave);
    // console.log('MyFinction');
    //console.log(contacts[0].percent_of_ownership);
    const client = await getClient();
    if (!client) {
      return;
    }
    client.contactsControllerUpsertAll({ contacts });
    console.log('upsert');
  };
  let allValid = isEverythingValid();

  return (
    <>
      <PayroRadioButtonGroup
        groupLabel="Are you the official owner of the business?"
        required
        options={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
        checkedValue={userIsOwner}
        onValueChange={async (e) => {
          setUserIsOwner(e.target.value as 'yes' | 'no');
          if (e.target.value == 'no') {
            // setPercentOfOwnership();
            if (!contacts || contacts.length < 2) {
              props.contactCrudActions.addContact().then((_: any) => {
                //console.log('added one');
                setPercentOfOwnership();
              });
            }
          } else if (e.target.value == 'yes') {
            setContactDisable(false); //to reset checkbox
            if (!contacts) {
              return;
            }
            if (contacts.length > 1) {
              for (let i = 0; i < contacts.length; i++) {
                if (contacts[0].uuid != userInfo.contact_uuid) {
                  props.contactCrudActions.deleteContact(i);
                }

                // console.log('deleted this one');
                // console.log(i);
              }
            }
          }
        }}
        groupName="user-is-owner"
      />
      {userIsOwner == 'no' && (
        <div className="owner-checkbox">
          <input
            type="checkbox"
            className="checkbox-owner"
            defaultChecked={contactDisable}
            onChange={() => {
              props.contactCrudActions.addContact();
              setContactDisable(!contactDisable);
              setPercentOfOwnership();
            }}
          />
          <p className="owner-information">
            I'll provide owner information later
          </p>
        </div>
      )}

      {userIsOwner == 'no' && !contactDisable && (
        <>
          <InfoMessage
            theBackgroundColor="yellow"
            messageText="We will notify the business owner and obtain their approval prior to your account being approved for funding"
          />

          <h3 id="owner-header">{'Owner Info'}</h3>
          {/* {contactsDisplay[0]} */}
          {contactsDisplay.filter((el, idx) => {
            if (idx > 0) {
              return el;
            }
          })}

          <PayroButton
            startIcon={PurpleAddIcon}
            buttonSize="small"
            variant="white"
            centered={true}
            onClick={() => props.contactCrudActions.addContact()}
          >
            Add an Owner
          </PayroButton>
        </>
      )}
      {userIsOwner && userIsOwner == 'yes' && (
        <>
          {/* <div>
            <div>
              <PayroInput
                onFocus={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    title: false,
                  })
                }
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    title: true,
                  })
                }
                error={
                  validationsToShow.title &&
                  validationFunctions.title
                }
                onChange={(e: any) =>
                  setAccountInfo({ ...AccountInfo, title: e })
                }
                required
                value={AccountInfo.title}
                placeholder="CEO, CFO"
                id="title"
                label="Title"
                variant="standard"
              />
            </div>
            <div>
              <PayroInput
                onFocus={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    percent_of_ownership: false,
                  })
                }
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    percent_of_ownership: true,
                  })
                }
                error={
                  validationsToShow.percent_of_ownership &&
                  validationFunctions.percent_of_ownership
                }
                onChange={(e: any) =>
                  setAccountInfo({ ...AccountInfo, percent_of_ownership: e })
                }
                type="number"
                required
                value={AccountInfo.percent_of_ownership}
                placeholder="100%"
                id="percentofownership"
                label="Percent of Ownership"
                variant="standard"
              />
            </div>
          </div>
          <div>
            <PayroInput
              onFocus={() =>
                setValidationsToShow({
                  ...validationsToShow,
                  homeAddressLine1: false,
                })
              }
              onBlurFunction={() =>
                setValidationsToShow({
                  ...validationsToShow,
                  homeAddressLine1: true,
                })
              }
              error={
                validationsToShow.homeAddressLine1 &&
                validationFunctions.homeAddressLine1
              }
              onChange={(e: any) =>
                setAccountInfo({ ...AccountInfo, home_address: e })
              }
              required
              value={AccountInfo.home_address}
              id="homeAddressLine1"
              label="Home Address"
              placeholder="Address"
              variant="standard"
            />
          </div>
          <div id="city-state-zip">
            <div>
              <PayroInput
                onFocus={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    homeCity: false,
                  })
                }
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    homeCity: true,
                  })
                }
                error={
                  validationsToShow.homeCity &&
                  validationFunctions.homeCity
                }
                onChange={(e: any) =>
                  setAccountInfo({
                    ...AccountInfo,
                    home_address_city: e,
                  })
                }
                required
                value={AccountInfo.home_address_city}
                id="homeCity"
                label="City"
                variant="standard"
              />
            </div>
            <div id="company-state-wrapper">
              <PayroSelect
                selectName="company-state"
                placeholderText=""
                onSelect={(e: any) =>
                  setAccountInfo({
                    ...AccountInfo,
                    home_address_state: e.target.value,
                  })
                }
                label="State"
                options={StatesAbbreviations}
                defaultSelectedValue={AccountInfo.home_address_state}
              />
            </div>
            <div>
              <PayroInput
                onFocus={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    homeZip: false,
                  })
                }
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    homeZip: true,
                  })
                }
                error={
                  validationsToShow.homeZip &&
                  validationFunctions.homeZip
                }
                onChange={(e: any) =>
                  setAccountInfo({
                    ...AccountInfo,
                    home_address_zip: e,
                  })
                }
                required
                value={AccountInfo.home_address_zip}
                id="the-company-zip"
                label="Zip"
                autoComplete="zip"
                variant="standard"
              />
            </div>
          </div> */}

          <div>{contactsDisplayOwner[0]}</div>
        </>
      )}
    </>
  );
};
