import Icon from '../../../common-icons/maintenance.svg';
import closeX from '../../../common-icons/closex.svg';
  import PayroButton from '../../../widgets/PayroButton';
  import FundedIcon from './funded-icon.svg';
import { useRecoilState, useRecoilValue } from 'recoil';
import { mainSectionState } from '../../../recoil-state/general-states';
import './index.scss';
import { useHistory } from 'react-router-dom';
import {
  fundingStepState,
} from '../../../recoil-state/request-funding-states';
const DealPending = () => {
  let history = useHistory();
  const mainSection = useRecoilValue(mainSectionState);
  const [fundingStep, setFundingStep] =
  useRecoilState(fundingStepState);
  return (
    <>
      <div id="deal-failed-wrapper">
      <img
        id={
          mainSection === 'RepeatFunding'
            ? 'funded-icon-repeat'
            : 'funded-icon'
        }
        src={FundedIcon}
      ></img>
        <h2 id="payroll-failed-title">Request Received</h2>
        <div id="payroll-failed-explanation">
          <p>
            We have received your Advance request. Our team will review the Payroll reports and approve the Advance shortly.
          </p>
        </div>
        <div className='buttons-pending'>
          <div>
            <PayroButton
            onClick={() => window.location.reload()}>
            {/* onClick={() => {history.push('/');console.log("Herre")}}> */}
              Close
            </PayroButton>
          </div>
          <div>
            {/* <PayroButton className={`cancel-request`}>
              Cancel Request
            </PayroButton> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DealPending;
