import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { pendingTransactionsState } from '../../../recoil-state/general-states';
import { formatNumberAsDollars } from '../../../utils';
import '../index.scss';
import PendingBalanceMessage from './PendingBalanceMessage';
interface PayroLineOfCreditProps {
  outstandingBalance: number;
  remainingLineOfCredit: number;
}
const CAFundingLimit = (props: PayroLineOfCreditProps) => {
  const pendingTransactions = useRecoilValue(
    pendingTransactionsState,
  );
  // console.log('props================>',props)
  return (
    <div className="individual-wrapper">
      <p className="dashboard-header-title"> Funding Limit</p>
      <div className="pending-balance-main-wrapper">
        <p className="dashboard-header-value">
          {formatNumberAsDollars(props.remainingLineOfCredit)}
        </p>
        {pendingTransactions && (
          <PendingBalanceMessage section="balance" />
        )}
      </div>
      {
        props.outstandingBalance && props.outstandingBalance > 0 
        ? 
          <div className="outstanding-wrapper">
            <p className="outstanding-amount-orange-wrapper">
              {formatNumberAsDollars(props.outstandingBalance)}
            </p>
            <p className="outstanding-text">Outstanding</p>
          </div>
        :
          ""
      }
      
    </div>
  );
};

export default CAFundingLimit;
