import { GetRepaymentDto } from '../../../../api-utils/generated-client';
import { useRecoilValue } from 'recoil';
import RepaymentCardForMobile from '../RepaymentsMobileView';
import { repaymentsState } from '../../../../recoil-state/general-states';

import ActivePayroll from '../CashAdvances/ActivePayroll';
import Repayment from './repayment';
import PaidPayroll from '../CashAdvances/PaidPayroll';
import PendingPayroll from './PendingPayroll';

interface RepaymentsProps {}

const AdvanceWrapper = () => {
  const repayments = useRecoilValue(repaymentsState);
  const getRepaymentDisplay = (repayment: GetRepaymentDto) => {
    return <Repayment {...repayment} key={repayment.uuid} />;
  };
  let processingRepayments: any[] = [];
  let stillInRepaymentRepayments: any[] = [];
  let completeRepayments: any[] = [];
  if (repayments) {
    repayments
      .filter((repayment) => repayment.status != 'New')
      .forEach((repayment) => {
        if (repayment.status == 'Complete') {
          completeRepayments.push(getRepaymentDisplay(repayment));
        } else if (
          repayment.status == 'Request In - Review Payroll' ||
          repayment.status == 'Funding Approved, Awaiting Wire' ||
          repayment.status == 'Wire Entered, Awaiting Approval'
        ) {
          processingRepayments.push(getRepaymentDisplay(repayment));
        } else {
          stillInRepaymentRepayments.push(
            getRepaymentDisplay(repayment),
          );
        }
      });
  }

  return (
    <>
      {/* <div className="dashboard-highlight-container">
        <div className='balance-progress-bar-container'>
            <div className='dashboard-balance-available-credit'>
                <div className='balance-container'>
                    <p className="outstanding-balance-title">Current Outstanding Balance</p>
                    <p className="outstanding-balance-number">$63,265.25</p>
                </div>
                <div className='available-credit-wrapper'>
                    <p className="outstanding-balance-title">Available Funding Limit</p>
                    <p className="remaining-credit-number">$86,734.75</p>
                    <p className="outstanding-balance-approve">Approved: $200,000</p>
                </div>
            </div>
            <div id="progress-bar-container-v3">
              <div className="width-50-percent" id="green-completed"></div>
              <div className="width-50-percent"></div>
              </div>
        </div>
        <div className='next-scheduled-payment-wrapper'>
            <div className='payment-icon-and-text-wrapper-no-payment'>
              <div className='payment-img'>
                <img src="Group.png" className="" width="25" height="25"/>
              </div>
              <div className='payment-text-wrapper-no-payment'>
                <p className="next-scheduled-payment-text-style">Next Scheduled Payment:</p>
                <p className="outstanding-balance-date">06-25-23 - $1,455.31</p>
                <p className="next-scheduled-payment-text-style">Auto Debiting from Chase</p>
              </div>
            </div>
        </div>
      </div> */}
      {/* {processingRepayments}   */}
      <PendingPayroll/>
      <ActivePayroll/>
      <PaidPayroll/>

    </>
  );
};

export default AdvanceWrapper;
