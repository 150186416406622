import { useEffect, useState } from 'react';
import { getClient } from '../../api-utils/general-utils';
import { Contact } from '../../common-types';
import PlaidConnector from '../../widgets/PlaidConnector';
import PayroButton from '../../widgets/PayroButton';
import FundedIcon from './funded-icon.svg';
import './index.scss';
import FundPayroll from './DashboardComponents/FundPayroll';
import NextPayroll from './DashboardComponents/NextPayroll';
import PayroLineOfCredit from './DashboardComponents/PayroLineOfCredit';
import CAFundingLimit from './DashboardComponents/CAFundingLimit';
import PayroAnnouncements from './DashboardComponents/PayroAnnouncements';
import HelpfulTools from './DashboardComponents/HelpfulTools';
import DataGetter from './DataGetter';
import RecentPayrollFunded from './DashboardComponents/RecentPayrollFunded';
import RecentCAPayrollFunded from './DashboardComponents/RecentCAPayrollFunded';
import closeX from '../Ledger/LedgerHome/closex.svg';
import RequestFunding from '../RequestFunding';
import { GetAccountDto } from '../../api-utils/generated-client';
import Loader from '../../widgets/Loader';
import { useRecoilState, useRecoilValue } from 'recoil';
import RepeatFundingWrapper from '../../pages/RequestFunding/Wrappers/repeatFunding';
// import nextArrow from '../../../common-icons/dashboard-next-arrow.svg';
import nextArrow from '../../common-icons/dashboard-next-arrow.svg';
import {
  accountRecordState,
  repaymentsState,
  userContactInfoState,
} from '../../recoil-state/general-states';

const Dashboard = () => {
  const accountDetails = useRecoilValue<GetAccountDto>(
    accountRecordState,
  );
  const userContactInfo = useRecoilValue(userContactInfoState);
  const [showHideClassName1, setShowHideClassName1] = useState(
    'modal display-none',
  );
  const repayments = useRecoilValue(repaymentsState);

  // useEffect(() => {
  //   const getholidays = async () => {
  //     const client = await getClient();
  //     if (!client) {
  //       return;
  //     }
  //     let holidays = await client.dealsControllerGetTransactionUnAvailability();
  //     console.log('holidays========>',holidays)
  //   };

  //   getholidays();
  // },[])
  const formatUsername = (username: string) => {
    const formattedUsername =
      username.charAt(0).toUpperCase() +
      username.slice(1).toLowerCase();

    return formattedUsername;
  };

  return (
    <>
      {accountDetails &&
      accountDetails.legal_address_state == 'NY' ? (
        <div
          id="dashboard-main-container"
          className="advance-dashboard"
        >
          <div id="dashboard-home-container">
            <DataGetter />
            <p className="user-first-name">
              Hi,{' '}
              {userContactInfo.first_name
                ? formatUsername(userContactInfo.first_name)
                : 'There'}
              !
            </p>
            <div className="header-highlight-container-dashboard">
              <div className="detail-container-dashboard">
                <NextPayroll />
              </div>
              <div className="detail-container-dashboard-bank">
                <PlaidConnector stage="repeat-funding" />
              </div>
              <div className="detail-container-dashboard">
                <CAFundingLimit
                  outstandingBalance={
                    accountDetails.total_outstanding_principal as number
                  }
                  remainingLineOfCredit={
                    accountDetails.credit_amount_available as number
                  }
                />
              </div>
            </div>

            <div className="dashboard-container-body">
              <div id="fund-payroll-and-recent-payrolls">
                {repayments && repayments?.length < 1 ? (
                  <>
                    <div className="payro-no-funded-container">
                      <img
                        id={`funded-icon-repeat`}
                        src={FundedIcon}
                      ></img>
                      <h2 id="payroll-failed-title">
                        You’re good to go!
                      </h2>
                      <div id="payroll-failed-explanation">
                        <p>No open payroll advances at the moment.</p>
                      </div>
                      <div className="buttons-pending">
                        <div>
                          <PayroButton
                            endIcon={nextArrow}
                            customWidth="width-259"
                            onClick={() =>
                              setShowHideClassName1(
                                'modal display-block',
                              )
                            }
                            // onClick={() =>  history.push('/request-funding')}
                          >
                            Request Funding
                          </PayroButton>{' '}
                        </div>
                        <div>
                          {/* <PayroButton className={`cancel-request`}>
                        Cancel Request
                      </PayroButton> */}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={'fund-payroll-container'}>
                      <FundPayroll />
                    </div>
                    <div className="recent-payroll-funded-container ">
                      <RecentCAPayrollFunded />
                    </div>
                  </>
                )}
              </div>
              <div className="helpful-tools-container">
                <HelpfulTools />
              </div>
            </div>

            <div className="announcements-container">
              <PayroAnnouncements />
            </div>
          </div>
          <div id="myModal" className={showHideClassName1}>
            <div className="modal-content-repay">
              <span
                onClick={() => {
                  setShowHideClassName1('modal display-none');
                }}
                className="close"
              >
                &times;
              </span>
              <RepeatFundingWrapper popupContainer={true} />
            </div>
          </div>
        </div>
      ) : (
        <div id="dashboard-main-container">
          <div id="dashboard-home-container">
            <DataGetter />
            <p className="user-first-name">
              Hi,{' '}
              {userContactInfo.first_name
                ? formatUsername(userContactInfo.first_name)
                : 'There'}
              !
            </p>

            <div className="header-highlight-container-dashboard">
              <div className="detail-container-dashboard">
                <NextPayroll />
              </div>
              <div className="detail-container-dashboard-bank">
                <PlaidConnector stage="repeat-funding" />
              </div>
              <div className="detail-container-dashboard">
                <PayroLineOfCredit
                  outstandingBalance={
                    accountDetails.total_outstanding_amount as number
                  }
                  remainingLineOfCredit={
                    accountDetails.credit_amount_available as number
                  }
                />
              </div>
            </div>

            <div className="dashboard-container-body">
              <div id="fund-payroll-and-recent-payrolls">
                <div className={'fund-payroll-container'}>
                  <FundPayroll />
                </div>
                {repayments && repayments?.length < 1 ? (
                  ''
                ) : (
                  <div className="recent-payroll-funded-container ">
                    <RecentPayrollFunded />
                  </div>
                )}
              </div>
              <div className="helpful-tools-container">
                <HelpfulTools />
              </div>
            </div>

            <div className="announcements-container">
              <PayroAnnouncements />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Dashboard;
