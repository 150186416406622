import { GetRepaymentDto } from '../../../../api-utils/generated-client';
import { formatDate, formatNumberAsDollars } from '../../../../utils';
import { getLoanIdToDisplay } from '../../ledger-utils';
import '../index.scss';
import { useRecoilValue } from 'recoil';
import Repayment from './repayment';
import { repaymentsState } from '../../../../recoil-state/general-states';
import detailsIcon from '../../../../common-icons/details-icon.svg';
import { useHistory } from 'react-router-dom';
import PdfStatements from '../../PdfStatements';

interface RepaymentsProps {}

const ActivePayroll = (props: RepaymentsProps) => {
  let history = useHistory();
  const repayments = useRecoilValue(repaymentsState);
  const getRepaymentDisplay = (repayment: GetRepaymentDto) => {
    return <Repayment {...repayment} key={repayment.uuid} />;
  };
  let activeRepayments: any[] = [];
  let stillInRepaymentRepayments: any[] = [];
  let completeRepayments: any[] = [];
  if (repayments) {
    repayments
      .filter((repayment) => repayment.status != 'New')
      .forEach((repayment) => {
        // if (repayment.status == 'Complete') {
        //   completeRepayments.push(getRepaymentDisplay(repayment));
        // } else 
        if (
          repayment.status == 'Active'
        ) {
          activeRepayments.push(getRepaymentDisplay(repayment));
        }
        //  else {
        //   stillInRepaymentRepayments.push(
        //     getRepaymentDisplay(repayment),
        //   );
        // }
      });
  }

  return (
    <>
        {activeRepayments.length > 0 && (
          <>
            <div id="repayments-container">
              <p
                id="still-in-repayment-title"
                className="repayment-section-title"
              >
                Active Payroll Advances{' '}
              </p>

              <div className="still-in-repayment-header">
                <div className="ActiveRepayment-col">ID</div>
                <div className="ActiveRepaymentarr-col">Payments Made
                <img className="" src="Arrowupper.svg" height="22.01" width="22.01" style={{ marginLeft: '10px'}} />
                </div>
                <div className="ActiveRepaymentarr-col">
                  Outstanding Balance
                  <img className="" src="Arrowupper.svg" height="22.01" width="22.01" style={{ marginLeft: '10px'}} />
                  </div>
                <div className="ActiveRepayment-col">Final payment date</div>
                <div className="ActiveRepayment-col">
                  Date Funded
                </div>
                <div className="ActiveRepayment-col"> </div>
              </div>
              {activeRepayments}
            </div>
          </>
        )}
        {/* {stillInRepaymentRepayments.length > 0 && (
          <>
            <p
              id="still-in-repayment-title"
              className="repayment-section-title"
            >
              Open Loans
            </p>
            <div id="still-in-repayment-header">
              <div className="repayment-col">Loan Id</div>
              <div className="repayment-col">Payments Made</div>
              <div className="outstanding-header repayment-col">
                Outstanding
              </div>
              <div className="status repayment-col">Status</div>
              <div className="date-funded-header repayment-col">
                Funded Date
              </div>
              <div className="funding-amount-header repayment-col">
                Funding Amount
              </div>

              <div className="payback-date-header repayment-col">
                Payback Date
              </div>
            </div>
            {stillInRepaymentRepayments}
          </>
        )}
        {completeRepayments.length > 0 && (
          <>
            <p id="paid-up-title" className="repayment-section-title">
              Paid Loans
            </p>
            <div id="paid-up-repayment-header">
              <div className="repayment-col">Loan Id</div>
              <div className="repayment-col">Payments Made</div>
              <div className="outstanding-header repayment-col">
                Outstanding
              </div>
              <div className="status repayment-col">Status</div>
              <div className="date-funded-header repayment-col">
                Funded Date
              </div>
              <div className="repayment-col status">
                Funding Amount
              </div>
              <div className="date-funded-header repayment-col">
                Payback Date
              </div>
            </div>
            {completeRepayments}
          </>
        )} */}
    </>

// --------- Static Page code

    // <div className="Activerepayment-wrapper" onClick={() => {}}>
    //   <>
    //     <div className="Advance-repayment-heading">
    //     <p className='Active-Payroll'>Active Payroll Advances</p>
    //       {/* <p className="repayment-section-title">Open Loans</p> */}
    //         <div className="still-in-repayment-header">
    //           <div className="ActiveRepayment-col">ID</div>
    //           <div className="ActiveRepaymentarr-col">Payments Made
    //           <img className="" src="Arrowupper.svg" height="22.01" width="22.01"/>
    //           </div>
    //           <div className="ActiveRepaymentarr-col">
    //             Outstanding Balance
    //             <img className="" src="Arrowupper.svg" height="22.01" width="22.01"/></div>
    //           <div className="ActiveRepayment-col">Final payment date</div>
    //           <div className="ActiveRepayment-col">
    //             Date Funded
    //           </div>
    //           <div className="ActiveRepayment-col"> </div>
    //         </div>
    //         <div className="still-in-repayment-data">
    //           <div className="Details-col">
    //             <img className="loan-image" src="Money.svg" height="22.01" width="22.01"/>
    //             <a href="#" className="details">1544</a>
    //             </div>
    //           {/* <div className="ActiveRepayment-col-simple"> </div> */}
    //           <div className="ActiveRepayment-simple">$0.00
    //           <div className='progress-barcontainer'></div>
    //             <div className="header-pg-barwrapper">
    //               <div>
    //                 <div className="progress-barcontainer-v2"></div>
    //               </div>
    //               <div className="repayment-infoitemgreen">62%</div>
    //             </div>
    //           </div>
    //           <div className="ActiveRepayment-colred">$65,245.24</div>
    //           <div className="ActiveRepayment-col-simple">06/31/23</div>
    //           <div className="ActiveRepayment-col-simple">06/01/23</div>
    //           {/* <div className="Details-col">Details</div> */}
    //           <div className="Details-col">
    //             <img className="loan-image" src="/static/media/details-icon.9daa014e.svg" height="22.01" width="22.01"/>
    //             <a href="/ledger-detail/6721b02b-1884-6b61-ce2e-a8addcb6aff9" className="details">Details</a>
    //             </div>
    //         </div>
    //         <div className="still-in-repayment-data">
    //           <div className="Details-col">
    //             <img className="loan-image" src="Money.svg" height="22.01" width="22.01"/>
    //             <a href="#" className="details">1544</a>
    //             </div>
    //           {/* <div className="ActiveRepayment-col-simple"> </div> */}
    //           <div className="ActiveRepayment-simple">$0.00
    //           <div className='progress-barcontainer'></div>
    //             <div className="header-pg-barwrapper">
    //               <div>
    //                 <div className="progress-barcontainer-v2"></div>
    //               </div>
    //               <div className="repayment-infoitemred">0%</div>
    //             </div>
    //           </div>
    //           <div className="ActiveRepayment-colred">$0.00</div>
    //           <div className="ActiveRepayment-col-simple">06/31/23</div>
    //           <div className="ActiveRepayment-col-simple">06/01/23</div>
    //           {/* <div className="Details-col">Details</div> */}
    //           <div className="Details-col">
    //             <img className="loan-image" src="/static/media/details-icon.9daa014e.svg" height="22.01" width="22.01"/>
    //             <a href="/ledger-detail/6721b02b-1884-6b61-ce2e-a8addcb6aff9" className="details">Details</a>
    //             </div>
    //         </div>
    //         <div className="still-in-repayment-data">
    //           <div className="Details-col">
    //             <img className="loan-image" src="Money.svg" height="22.01" width="22.01"/>
    //             <a href="#" className="details">1544</a>
    //             </div>
    //           {/* <div className="ActiveRepayment-col-simple"> </div> */}
    //           <div className="ActiveRepayment-simple">$0.00
    //           <div className='progress-barcontainer'></div>
    //             <div className="header-pg-barwrapper">
    //               <div>
    //                 <div className="progress-barcontainer-v2"></div>
    //               </div>
    //               <div className="repayment-infoitemgreen">62%</div>
    //             </div>
    //           </div>
    //           <div className="ActiveRepayment-colred">$65,245.24</div>
    //           <div className="ActiveRepayment-col-simple">06/31/23</div>
    //           <div className="ActiveRepayment-col-simple">06/01/23</div>
    //           {/* <div className="Details-col">Details</div> */}
    //           <div className="Details-col">
    //             <img className="loan-image" src="/static/media/details-icon.9daa014e.svg" height="22.01" width="22.01"/>
    //             <a href="/ledger-detail/6721b02b-1884-6b61-ce2e-a8addcb6aff9" className="details">Details</a>
    //             </div>
    //         </div>
    //     </div>
    //   </>
    // </div>
  );
};

export default ActivePayroll;
