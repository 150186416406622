import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useScript } from 'use-script';
import {
  BankAccount,
  UpdateAccountDto,
  UpdateBankAccountDto,
  CreateBankAccountDto,
} from '../../api-utils/generated-client';
import { getClient } from '../../api-utils/general-utils';
import Loader from '../../widgets/Loader';

import {
  bankAccountsState,
  isManualBankState,
  bankAccountToUpdateState,
  bankAccountToCreateState,
  chaseBankAccountsArrayState,
} from '../../recoil-state/request-funding-states';
import PayroInput from '../PayroInput';
import checkCircle from '../FinchConnector/check-circle.png';
import InfoIcon from '../../common-icons/info-icon.svg';
import { isNextButtonDisabledState, isSaveDisableState } from '../../recoil-state/application-stage-states';
import RequestFundingTitle from '../../pages/RequestFunding/request-funding-title';
import { useHistory } from 'react-router-dom';
import './index.css';
import PayroButton from '../PayroButton';

const noValidations = {
  BankName: false,
  AccountNumber: false,
  RoutingNumber: false,
};


const ManualBankForm = ( ) => {
  let history = useHistory();
  const [bankAccounts, setBankAccounts] =
    useRecoilState(bankAccountsState);

  const [bankAccountToUpdate, setBankAccountToUpdate] = useRecoilState<UpdateBankAccountDto>(bankAccountToUpdateState);
  const [bankAccountToCreate, setBankAccountToCreate] = useRecoilState<CreateBankAccountDto>(bankAccountToCreateState);
  const [retypeBankAccount, setRetypeBankAccount] = useState<string>();
  const [loader, setLoader] = useState<boolean>(false);
  const [retypeBankRouting, setRetypeBankRouting] = useState<string>();
  const [chaseBankAccountsArray, setChaseBankAccountsArray] =
    useRecoilState(chaseBankAccountsArrayState);
  const [validationsToShow, setValidationsToShow] =
  useState<any>(noValidations);
  const [isManual, setIsManual] = useRecoilState(
    isManualBankState,
  );
  const [isNextDisabled, setIsNextDisabled] = useRecoilState(
    isNextButtonDisabledState,
  );
  const [isave, setIsSave] = useRecoilState(
    isSaveDisableState,
  );
  // const [isave, setIsSave] = useState<boolean>(true);

  const isNineNumbers = /^[0-9]{9}$/;
  const isOnlyNumbers = /^[0-9]/;

  const index = chaseBankAccountsArray.findIndex(
    (bankItem) => bankItem.uuid === bankAccountToUpdate.uuid,
  );
  const settingNextState = () => {
    if(!bankAccountToCreate?.bank_name ||
      !bankAccountToCreate?.bank_account_number || bankAccountToCreate?.bank_account_number?.length < 5 ||
      !retypeBankAccount || retypeBankAccount != bankAccountToCreate?.bank_account_number ||
      !bankAccountToCreate?.bank_routing_number || bankAccountToCreate?.bank_routing_number?.length < 9 ||
      !retypeBankRouting || retypeBankRouting != bankAccountToCreate?.bank_routing_number
      ){
      setIsNextDisabled(true)
    }else{
      setIsNextDisabled(false)
    }
    return true;
  }
  const replaceItemAtIndex = (
    arr: any,
    index: any,
    newValue: any,
  ) => {
    return [
      ...arr.slice(0, index),
      newValue,
      ...arr.slice(index + 1),
    ];
  };
  if(!isManual){
    setIsNextDisabled(
      !chaseBankAccountsArray[index]?.bankRoutingNumber ||
        !chaseBankAccountsArray[index]?.bankAccountNumber ||
        chaseBankAccountsArray[index]?.bankRoutingNumber !=
          chaseBankAccountsArray[index]?.bankRoutingNumberConfirm ||
        chaseBankAccountsArray[index]?.bankAccountNumber !=
          chaseBankAccountsArray[index]?.bankAccountNumberConfirm,
    );
  }else{
    if(!bankAccountToCreate?.bank_name ||
      !bankAccountToCreate?.bank_account_number || bankAccountToCreate?.bank_account_number?.length < 5 ||
      !retypeBankAccount || retypeBankAccount != bankAccountToCreate?.bank_account_number ||
      !bankAccountToCreate?.bank_routing_number || bankAccountToCreate?.bank_routing_number?.length < 9 ||
      !retypeBankRouting || retypeBankRouting != bankAccountToCreate?.bank_routing_number)
    {
      // setIsNextDisabled(true)
      setIsSave(true)
    }else{
      // setIsNextDisabled(false)
      setIsSave(false)
    }
  }
  // console.log('bankAccountToCreate=========>',bankAccountToCreate);
  const p = chaseBankAccountsArray.filter(
    (b: any) => b.uuid == bankAccountToUpdate.uuid,
  );
  const saveBanks = async() => {
    setLoader(true)
    if(bankAccountToCreate){
      const apiClient = await getClient();
      if (!apiClient) {
        setLoader(false)
        return;
      }
      try{
        await apiClient.bankAccountsControllermanualCreate(bankAccountToCreate);
        const b = await apiClient.bankAccountsControllerFindAll();
        await setBankAccounts(b.data);
        setIsManual(false)
        setBankAccountToCreate({
          ...bankAccountToCreate,
          bank_name:'',
          bank_account_number:'',
          bank_routing_number:'',
        })
        setRetypeBankAccount('')
        setRetypeBankRouting('')
      }catch(err){
        console.log(err)
        setLoader(false)
      }
    }
    
    setLoader(false)
  }
  if (loader) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  //console.log(p, 'p');
  // console.log('chaseBankAccountsArray',chaseBankAccountsArray)
  // console.log('bankAccountToUpdate',bankAccountToUpdate)

  const validationFunctions: { [key: string]: boolean } = {
    BankName:
      !bankAccountToCreate?.bank_name || bankAccountToCreate?.bank_name.length < 1,
    RoutingNumber:
      !bankAccountToCreate?.bank_routing_number || bankAccountToCreate?.bank_routing_number.length < 1,
    ReRoutingNumber:
    !retypeBankRouting || retypeBankRouting != bankAccountToCreate?.bank_routing_number,
    AccountNumber:
      !bankAccountToCreate?.bank_account_number || bankAccountToCreate?.bank_account_number.length < 1,
    ReAccountNumber:
    !retypeBankAccount || retypeBankAccount != bankAccountToCreate?.bank_account_number,
  };

  return (
    <> 
      {
      isManual
      ?
        <>
          <div id="account-number-section">
            <div className="bank-info-input-wrapper left-side">
              <PayroInput
                label={`Bank Name`}
                placeholder="Enter Bank Name"
                value={bankAccountToCreate?.bank_name}
                onFocus={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    BankName: false,
                  })
                }
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    BankName: true,
                  })
                }
                onChange={async (e) => {
                  await setBankAccountToCreate({
                    ...bankAccountToCreate,
                    bank_name: e.toString(),
                  });
                  let ts:any = settingNextState();
                  // const newList: any = replaceItemAtIndex(
                  //   chaseBankAccountsArray,
                  //   index,
                  //   {
                  //     ...account,
                  //     bankname: e,
                  //   },
                  // );
                  // setChaseBankAccountsArray(newList);
                }}
                error={ (validationsToShow.BankName && validationFunctions.BankName) }
                // error={
                //   !bankAccountToCreate?.bank_name || bankAccountToCreate?.bank_name?.length < 1
                // }
                helperText={
                  (!bankAccountToCreate?.bank_name || bankAccountToCreate?.bank_name?.length < 1)
                    ? 'A valid bank name is required'
                    : ''
                }
                onCopy={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
                onPaste={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </div>
          </div>
          <div id="account-number-section">
            <div className="bank-info-input-wrapper left-side">
              <PayroInput
                label={`Account Number`}
                type="number"
                placeholder="Enter Account Number"
                value={bankAccountToCreate?.bank_account_number}
                onFocus={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    AccountNumber: false,
                  })
                }
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    AccountNumber: true,
                  })
                }
                onChange={async (e) => {
                  await setBankAccountToCreate({
                    ...bankAccountToCreate,
                    bank_account_number: e.toString(),
                  });
                  let ts:any = settingNextState();

                  // const newList: any = replaceItemAtIndex(
                  //   chaseBankAccountsArray,
                  //   index,
                  //   {
                  //     ...account,
                  //     bankAccountNumber: e,
                  //   },
                  // );
                  // setChaseBankAccountsArray(newList);
                }}
                error={ (validationsToShow.AccountNumber && validationFunctions.AccountNumber) }
                // error={
                //   !bankAccountToCreate?.bank_account_number || bankAccountToCreate?.bank_account_number?.length > 1 &&
                //   !isOnlyNumbers.test(
                //     bankAccountToCreate?.bank_account_number,
                //   )
                // }
                helperText={
                  !isOnlyNumbers.test(
                    bankAccountToCreate?.bank_account_number,
                  ) ||
                  (bankAccountToCreate?.bank_account_number?.length > 1 &&
                    bankAccountToCreate?.bank_account_number?.length < 5)
                    ? 'A valid number is required'
                    : ''
                }
                onCopy={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
                onPaste={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </div>
            <div className="bank-info-input-wrapper right-side">
              <PayroInput
                label=""
                type="number"
                placeholder="Retype Account Number"
                value={retypeBankAccount}
                onFocus={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    ReAccountNumber: false,
                  })
                }
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    ReAccountNumber: true,
                  })
                }
                onChange={ (e) => {
                  setRetypeBankAccount(e)
                  let ts:any = settingNextState();
                }}

                //   const newList: any = replaceItemAtIndex(
                //     chaseBankAccountsArray,
                //     index,
                //     {
                //       ...account,
                //       bankAccountNumberConfirm: e,
                //     },
                //   );

                //   setChaseBankAccountsArray(newList);
                // }
                // onBlurFunction={(e: any) =>
                //   e.stopPropagation()
                // }
                error={ (validationsToShow.ReAccountNumber && validationFunctions.ReAccountNumber) }
                // error={
                //   !retypeBankAccount || retypeBankAccount !=
                //   bankAccountToCreate?.bank_account_number
                // }
                helperText={
                  !retypeBankAccount || retypeBankAccount !=
                  bankAccountToCreate?.bank_account_number
                    ? 'Numbers must match'
                    : ''
                }
                onCopy={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
                onPaste={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </div>{' '}
          </div>
          <div id="routing-number-section">
            <div className="bank-info-input-wrapper left-side">
              <PayroInput
                label={`Routing Number`}
                type="number"
                value={bankAccountToCreate?.bank_routing_number}
                wrapperAdditionalClasses="full-width-left-side"
                // onBlurFunction={(e: any) =>
                //   e.stopPropagation()
                // }
                onFocus={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    RoutingNumber: false,
                  })
                }
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    RoutingNumber: true,
                  })
                }
                onChange={async (e) => {
                  await setBankAccountToCreate({
                    ...bankAccountToCreate,
                    bank_routing_number: e.toString(),
                  });
                  let ts:any = settingNextState();

                  // const newList: any = replaceItemAtIndex(
                  //   chaseBankAccountsArray,
                  //   index,
                  //   {
                  //     ...account,
                  //     bankRoutingNumber: e,
                  //   },
                  // );

                  // setChaseBankAccountsArray(newList);
                }}
                error={ (validationsToShow.RoutingNumber && validationFunctions.RoutingNumber) }
                // error={
                //   !bankAccountToCreate?.bank_routing_number || bankAccountToCreate?.bank_routing_number?.length < 1
                // }
                helperText={
                  !isNineNumbers.test(
                    bankAccountToCreate?.bank_routing_number,
                  )
                    ? 'A 9 digit number is required'
                    : ''
                }
                placeholder="Enter Routing Number"
              />
            </div>{' '}
            <div className="bank-info-input-wrapper right-side">
              <PayroInput
                wrapperAdditionalClasses="full-width-right-side"
                label=""
                type="number"
                placeholder="Retype Routing Number"
                value={retypeBankRouting}
                onFocus={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    ReRoutingNumber: false,
                  })
                }
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    ReRoutingNumber: true,
                  })
                }
                onChange={ (e) => {
                  setRetypeBankRouting(e)
                  let ts:any = settingNextState();
                }}
                //   const newList: any = replaceItemAtIndex(
                //     chaseBankAccountsArray,
                //     index,
                //     {
                //       ...account,
                //       bankRoutingNumberConfirm: e,
                //     },
                //   );

                //   setChaseBankAccountsArray(newList);
                // }
                // onBlurFunction={(e: any) =>
                //   e.stopPropagation()
                // }
                error={ (validationsToShow.ReRoutingNumber && validationFunctions.ReRoutingNumber) }
                // error={
                //   !retypeBankRouting || retypeBankRouting !=
                //   bankAccountToCreate?.bank_routing_number
                // }
                helperText={
                  !retypeBankRouting || retypeBankRouting !=
                  bankAccountToCreate?.bank_routing_number
                    ? 'Numbers must match'
                    : ''
                }
                // error={
                //   account.bankRoutingNumber !=
                //   account.bankRoutingNumberConfirm
                // }
                // helperText={
                //   account.bankRoutingNumber !=
                //   account.bankRoutingNumberConfirm
                //     ? 'Numbers must match'
                //     : ''
                // }
                onCopy={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
                onPaste={(e: any) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </div>
          </div>
          <PayroButton onClick={() => saveBanks()} disabled={isave}>
            Save
          </PayroButton>
        </>
      :
        <div
          key={bankAccountToUpdate.uuid}
          id="chase-details-wrapper"
          className="connected-account-container-chase "
        >
          {chaseBankAccountsArray
            .filter((b: any) => b.uuid == bankAccountToUpdate.uuid)
            .map((account: any) => {
              return (
                <>
                  <div id="account-number-section">
                    <div className="bank-info-input-wrapper left-side">
                      <PayroInput
                        label={`Bank Name`}
                        placeholder="Enter Bank Name"
                        value={account.bankname}
                        onChange={async (e) => {
                          await setBankAccountToCreate({
                            ...bankAccountToCreate,
                            bank_name: e.toString(),
                          });
                          const newList: any = replaceItemAtIndex(
                            chaseBankAccountsArray,
                            index,
                            {
                              ...account,
                              bankname: e,
                            },
                          );
                          setChaseBankAccountsArray(newList);
                        }}
                        error={
                          !account?.bankname || account?.bankname?.length < 1
                        }
                        helperText={
                          (!account?.bankname || account?.bankname?.length < 1)
                            ? 'A valid bank name is required'
                            : ''
                        }
                        onCopy={(e: any) => {
                          e.preventDefault();
                          return false;
                        }}
                        onPaste={(e: any) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    </div>
                  </div>
                  <div id="account-number-section">
                    <div className="bank-info-input-wrapper left-side">
                      <PayroInput
                        label={`Account Number`}
                        placeholder="Enter Account Number"
                        value={account.bankAccountNumber}
                        onChange={async (e) => {
                          await setBankAccountToCreate({
                            ...bankAccountToCreate,
                            bank_account_number: e.toString(),
                          });
                          const newList: any = replaceItemAtIndex(
                            chaseBankAccountsArray,
                            index,
                            {
                              ...account,
                              bankAccountNumber: e,
                            },
                          );
                          setChaseBankAccountsArray(newList);
                        }}
                        // error={
                        //   account.bankAccountNumber.length > 1 &&
                        //   !isOnlyNumbers.test(
                        //     account.bankAccountNumber,
                        //   )
                        // }
                        helperText={
                          !isOnlyNumbers.test(
                            account.bankAccountNumber,
                          ) ||
                          (account.bankAccountNumber?.length > 1 &&
                            account.bankAccountNumber?.length < 5)
                            ? 'A valid number is required'
                            : ''
                        }
                        onCopy={(e: any) => {
                          e.preventDefault();
                          return false;
                        }}
                        onPaste={(e: any) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    </div>
                    <div className="bank-info-input-wrapper right-side">
                      <PayroInput
                        label=""
                        placeholder="Retype Account Number"
                        value={account.bankAccountNumberConfirm}
                        onChange={(e) => {
                          const newList: any = replaceItemAtIndex(
                            chaseBankAccountsArray,
                            index,
                            {
                              ...account,
                              bankAccountNumberConfirm: e,
                            },
                          );

                          setChaseBankAccountsArray(newList);
                        }}
                        onBlurFunction={(e: any) =>
                          e.stopPropagation()
                        }
                        error={
                          account.bankAccountNumber !=
                          account.bankAccountNumberConfirm
                        }
                        helperText={
                          account.bankAccountNumber !=
                          account.bankAccountNumberConfirm
                            ? 'Numbers must match'
                            : ''
                        }
                        onCopy={(e: any) => {
                          e.preventDefault();
                          return false;
                        }}
                        onPaste={(e: any) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    </div>{' '}
                  </div>
                  <div key={account.uuid} id="routing-number-section">
                    <div className="bank-info-input-wrapper left-side">
                      <PayroInput
                        label={`Routing Number`}
                        value={account.bankRoutingNumber}
                        wrapperAdditionalClasses="full-width-left-side"
                        onBlurFunction={(e: any) =>
                          e.stopPropagation()
                        }
                        onChange={async (e) => {
                          await setBankAccountToCreate({
                            ...bankAccountToCreate,
                            bank_routing_number: e.toString(),
                          });
                          const newList: any = replaceItemAtIndex(
                            chaseBankAccountsArray,
                            index,
                            {
                              ...account,
                              bankRoutingNumber: e,
                            },
                          );

                          setChaseBankAccountsArray(newList);
                        }}
                        error={
                          account?.bankRoutingNumber && account.bankRoutingNumber?.length > 1 &&
                          !isNineNumbers.test(
                            account.bankRoutingNumber,
                          )
                        }
                        helperText={
                          !isNineNumbers.test(
                            account.bankRoutingNumber,
                          )
                            ? 'A 9 digit number is required'
                            : ''
                        }
                        placeholder="Enter Routing Number"
                      />
                    </div>{' '}
                    <div className="bank-info-input-wrapper right-side">
                      <PayroInput
                        wrapperAdditionalClasses="full-width-right-side"
                        label=""
                        placeholder="Retype Routing Number"
                        value={account.bankRoutingNumberConfirm}
                        onChange={(e) => {
                          const newList: any = replaceItemAtIndex(
                            chaseBankAccountsArray,
                            index,
                            {
                              ...account,
                              bankRoutingNumberConfirm: e,
                            },
                          );

                          setChaseBankAccountsArray(newList);
                        }}
                        onBlurFunction={(e: any) =>
                          e.stopPropagation()
                        }
                        error={
                          account.bankRoutingNumber !=
                          account.bankRoutingNumberConfirm
                        }
                        helperText={
                          account.bankRoutingNumber !=
                          account.bankRoutingNumberConfirm
                            ? 'Numbers must match'
                            : ''
                        }
                        onCopy={(e: any) => {
                          e.preventDefault();
                          return false;
                        }}
                        onPaste={(e: any) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      }
    </>
  );
};

export default ManualBankForm;
