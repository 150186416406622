import { useEffect, useState } from 'react';
import icon from '../../../common-icons/payro-announcements-icon.svg';
import backArrow from '../../../common-icons/dashboard-back-arrow.svg';
import forwardArrow from '../../../common-icons/dashboard-forward-arrow.svg';
import '../index.scss';
import axios from 'axios';
import ReactSimplyCarousel from 'react-simply-carousel';
const PayroAnnouncements = () => {
  const [announcements, setAnnouncements] = useState<any>([]);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/announcements/future`)
      .then((res:any) => {
        const actualAnnouncements: any[] = res.data.announcements;
        // var annoucementdisplay: any = '';
        if (actualAnnouncements.length > 0) {
          setAnnouncements(actualAnnouncements)
          
        }
      })
      .catch((err:any) => {
        console.log(err)
      });

  }, []);
  return (
    <>
    <div>
      <div id="half-circle-thick"></div>
      <div id="half-circle-one"></div>
      <div id="half-circle-two"></div>
      <div id="half-circle-three"></div>
      <div id="half-circle-small-one"></div>
      <div id="half-circle-small-two"></div>
      <div className="payro-announcements-header-wrapper">
        <img src={icon} />
        <p className="payro-announcements-title">
          Payro Announcements:
        </p>
      </div>
      <div className="announcements-arrow-wrapper">
        {
          announcements && announcements.length > 0
          ?
            <ReactSimplyCarousel
              activeSlideIndex={activeSlideIndex}
              onRequestChange={setActiveSlideIndex}
              itemsToShow={1}
              itemsToScroll={1}
              forwardBtnProps={{
                //here you can also pass className, or any other button element attributes
                style: {
                  alignSelf: 'center',
                  background: 'transparent',
                  border: 'none',
                  borderRadius: '50%',
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '20px',
                  height: 50,
                  lineHeight: 1,
                  textAlign: 'center',
                  width: 50,
                  marginLeft:'50px'
                },
                children: <span><img className="arrow-wrapper" src={forwardArrow} /></span>,
              }}
              backwardBtnProps={{
                //here you can also pass className, or any other button element attributes
                style: {
                  alignSelf: 'center',
                  background: 'transparent',
                  border: 'none',
                  borderRadius: '50%',
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '20px',
                  height: 50,
                  lineHeight: 1,
                  textAlign: 'center',
                  width: 50,
                  marginRight:'50px'
                },
                children: <span><img className="arrow-wrapper" src={backArrow} /></span>,
              }}
              responsiveProps={[
                {
                  itemsToShow: 1,
                  itemsToScroll: 1,
                  minWidth: 768,
                },
              ]}
              speed={400}
              easing="linear"
            >
              {
                announcements.map((announcement:any) => (
                  <>
                  <div className='announcement-parts'>
                    {/* <h1 className='annoucement-title'>{announcement.title}</h1> */}
                    <p className='annoucement-body'>{announcement.body}</p>
                  </div>
                  </>
                ))
              }
            </ReactSimplyCarousel>
          :
            <>
              <img className="arrow-wrapper" src={backArrow} />
              <p className="no-announcements-style">
                No current announcements. Check back soon.
              </p>
              <img className="arrow-wrapper" src={forwardArrow} />
            </>
        }
      </div>
    </div>
    </>
  );
};

export default PayroAnnouncements;
