import './howitworks.scss'

const HowItWorks = () => {
    return (<div id="d-container" className="main-body">
      {/* <section className="payroll-funding-section">
        <div className="container">
            <h1 className="section-title aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">High-speed, low cost payroll funding</h1>
            <p className="section-subtitle aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">Everything you need to know about PAYRO’s payroll financing program</p>
            
            <div className="row">
                            <div className="col-md-6 pb-5 pb-md-0">
                    <div className="payroll-card-box aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <h5 className="heading">Terms</h5>
                        <ul className="list-unstyled payroll-list">
                                                    <li>
                                <span className="m-tick-icon">
                                    <img src="https://payrofinance.com/wp-content/themes/payrofinance/assets/images/circle-tick-black.svg" alt="circle tick" />
                                </span>
                                <span className="d-tick-icon">✓</span>
                                Rates as low as 1.5%                            </li>
                                                    <li>
                                <span className="m-tick-icon">
                                    <img src="https://payrofinance.com/wp-content/themes/payrofinance/assets/images/circle-tick-black.svg" alt="circle tick" />
                                </span>
                                <span className="d-tick-icon">✓</span>
                                Financing up to $500,000                            </li>
                                                    <li>
                                <span className="m-tick-icon">
                                    <img src="https://payrofinance.com/wp-content/themes/payrofinance/assets/images/circle-tick-black.svg" alt="circle tick" />
                                </span>
                                <span className="d-tick-icon">✓</span>
                                Same-day funding                            </li>
                                                </ul>
                    </div>
                </div>
                                <div className="col-md-6 ">
                    <div className="payroll-card-box aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <h5 className="heading">Prequalifications</h5>
                        <ul className="list-unstyled payroll-list">
                                                    <li>
                                <span className="m-tick-icon">
                                    <img src="https://payrofinance.com/wp-content/themes/payrofinance/assets/images/circle-tick-black.svg" alt="circle tick" />
                                </span>
                                <span className="d-tick-icon">✓</span>
                                2 years in business                            </li>
                                                    <li>
                                <span className="m-tick-icon">
                                    <img src="https://payrofinance.com/wp-content/themes/payrofinance/assets/images/circle-tick-black.svg" alt="circle tick" />
                                </span>
                                <span className="d-tick-icon">✓</span>
                                At least 5 employees                            </li>
                            
                            <li><small>NOTE: This loan can only be used for payroll.</small></li>
                                                </ul>
                    </div>
                </div>
                            </div>
        </div>
      </section> */}
      {/* <section className="how-it-work-section">
        <div className="container">
            <h2 className="section-heading aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">How it works</h2>
                        <div className="hiw-block block1">
                <div className="row align-items-md-center">
                    <div className="col-md-6 img-col pb-5 pb-md-0">
                        <div className="img-box aos-init aos-animate" data-aos="zoom-in" data-aos-duration="1000">
                        <img src="https://payrofinance.com/wp-content/uploads/2022/02/pay-tomorrow-img1-1.svg" className="attachment-large size-large" alt="" decoding="async" loading="lazy" />                        </div>
                    </div>
                    <div className="col-md-6 content-col">
                        <div className="content-box aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                            <div className="heading-box">
                                <span className="count">1</span>
                                <h3 className="heading">Approval</h3>
                            </div>
                            <p>Following your application, a PAYRO underwriter will reach out within 1 business day to gather some documents and process your application.</p>
<p>PAYRO will review and analyze your documents and will have a decision within 1-2 business days.</p>
                            <div className="highlighted-note-box">
                                <strong className="text-green">Documents you’ll need:</strong>
                                <span><p>6 months of bank statements (financials are not required).</p>
</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                        
            <div className="hiw-block block2">
                <div className="row align-items-md-center">
                    <div className="col-md-6 img-col order-1 order-md-2 pb-5 pb-md-0">
                        <div className="img-box aos-init aos-animate" data-aos="zoom-in" data-aos-duration="1000">
                        <img src="https://payrofinance.com/wp-content/uploads/2022/02/pay-tomorrow-img2-1.svg" className="attachment-large size-large" alt="" decoding="async" loading="lazy" />                         </div>
                    </div>
                    <div className="col-md-6 content-col order-2 order-md-1">
                        <div className="content-box aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                            <div className="heading-box">
                                <span className="count">2</span>
                                <h3 className="heading">Funding</h3>
                            </div>
                            <p>Once your business is approved, financing will be fully integrated within your payroll service.</p>
<p>You can also request funding through the PAYRO app.</p>
                            <div className="highlighted-note-box">
                                <strong className="text-green">Timeline:</strong>
                                <span><p>PAYRO will process your request and wire the funds directly into your account same-day to ensure your payroll is covered on time.</p>
</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                        <div className="hiw-block block3">
                <div className="row align-items-md-center">
                    <div className="col-md-6 img-col pb-5 pb-md-0">
                        <div className="img-box aos-init aos-animate" data-aos="zoom-in" data-aos-duration="1000">
                        <img src="https://payrofinance.com/wp-content/uploads/2022/02/pay-tomorrow-img3-1.svg" className="attachment-large size-large" alt="" decoding="async" loading="lazy" />                         </div>
                    </div>
                    <div className="col-md-6 content-col">
                        <div className="content-box aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                            <div className="heading-box">
                                <span className="count">3</span>
                                <h3 className="heading">Repayment</h3>
                            </div>
                            <p>You have up to 4 weeks (28 days) to repay the funds you’ve borrowed.</p>
<p>Simply tell PAYRO the date you want to pay back your loan within those 4 weeks and we’ll draw the entire amount plus relevant fees on the date you requested.</p>
                            <div className="highlighted-note-box">
                                <strong className="text-green">Early Payments:</strong>
                                <span><p>There’s no ‘early payment’ penalty.You can choose to repay the loan at any time throughout the 4 week period.</p>
</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                         
        </div>
    </section> */}
    </div>
    
    )
}

export default HowItWorks