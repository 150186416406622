const intercom = (firstName, email, uuid, phone, userJWT) => {
  const APP_ID = 'ugisjp0n';

  window.intercomSettings = {
    app_id: APP_ID,
    email: email,
    name: firstName,
    user_id: uuid,
    user_hash: uuid,
    phone: phone,
    custom_launcher_selector: '#my_custom_link',
  };

  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${APP_ID}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();

  if (userJWT) {
    window.intercomSettings = {
      name: firstName,
      email: email,
      user_hash: uuid,
    };
    (function () {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${APP_ID}`;
          const x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }
  var _fs_intercom_frustration = (function (exports) {
    'use strict';

    /**
     * Executes a callback function when the condition evaluates to true.
     * If the condition is false, the function will be checked again after doubling
     * the wait time until a maximum time is reached.
     *
     * @param condition function to evaluate and decide if callback should run
     * @param callback function to run when the condition is true
     * @param maxWait time to allow retrying the condition; defaults to 4000 ms
     * @param failureMsg message sent to FS.log as a result of timing out
     */
    function retryWithBackoff(
      condition,
      callback,
      maxWait,
      failureMsg,
    ) {
      if (maxWait === void 0) {
        maxWait = 4000;
      }
      var wait = 64;
      var resultFn = function () {
        if (condition()) {
          callback();
          return;
        }
        wait *= 2;
        if (wait > maxWait) {
          var fs = window[window['_fs_namespace']];
          if (fs && failureMsg) {
            fs.log('warn', failureMsg);
          }
          return;
        }
        setTimeout(resultFn, wait);
      };
      return resultFn;
    }

    // TODO update threshold to number of ragehook events needed to show Messenger
    // default is 0, which disables showing Intercom Messenger on frustration
    var threshold = 0;
    // TODO update the message to engage the user
    var prepopulatedMessage =
      "If you're having difficulty, feel free to let us know by messaging our team.";
    var errorMessage =
      'FullStory-Intercom frustration events enabled, but Intercom is not defined';
    var storageKey = '_fs_intercomFrustrationCount';
    /**
     * Returns true if Intercom is defined.
     * @param printError when true prints error message to console.error
     */
    function intercomOnPage(printError) {
      var onPage =
        window['Intercom'] !== undefined &&
        typeof window['Intercom'] === 'function';
      if (!onPage && printError) {
        console.error(errorMessage);
      }
      return onPage;
    }
    /**
     * Adds frustration event listeners.
     */
    function addEventListeners() {
      if (intercomOnPage(true)) {
        ['fullstory/rageclick'].forEach(function (event) {
          window.addEventListener(event, trackFrustrationEvent);
          window.addEventListener(event, showPrepopulatedMessage);
        });
      }
    }
    /**
     * Executes Intercom.trackEvent API when frustration events occur. Event
     * metadata contains the session link similar to the "New FullStory Session"
     * event.
     * @param customEvent the browser CustomEvent emitted by FullStory
     */
    function trackFrustrationEvent(event) {
      var type = event.type;
      var eventReplayUrlAtCurrentTime =
        event.detail.eventReplayUrlAtCurrentTime;
      var count = parseInt(sessionStorage.getItem(storageKey));
      sessionStorage.setItem(
        storageKey,
        isNaN(count) ? '1' : (count + 1).toString(),
      );
      if (intercomOnPage(true)) {
        window.Intercom('trackEvent', type, {
          'Session Link': {
            value: 'Play in FullStory',
            url: eventReplayUrlAtCurrentTime,
          },
        });
      }
    }
    /**
     * Opens the Messenger as if a new conversation was just created.
     * https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomshownewmessage
     */
    function showPrepopulatedMessage() {
      if (
        intercomOnPage(true) &&
        threshold.toString() == sessionStorage.getItem(storageKey)
      ) {
        window.Intercom('showNewMessage', prepopulatedMessage);
      }
    }
    function run() {
      retryWithBackoff(
        intercomOnPage,
        addEventListeners,
        4000,
        errorMessage,
      )();
    }

    exports.addEventListeners = addEventListeners;
    exports.intercomOnPage = intercomOnPage;
    exports.run = run;
    exports.showPrepopulatedMessage = showPrepopulatedMessage;
    exports.trackFrustrationEvent = trackFrustrationEvent;

    return exports;
  })({});

  _fs_intercom_frustration.run();
};
export { intercom };
