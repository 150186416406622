import { GetRepaymentDto } from '../../../../api-utils/generated-client';
import { formatDate, formatNumberAsDollars } from '../../../../utils';
import { getLoanIdToDisplay } from '../../ledger-utils';
import '../index.scss';
import { useRecoilValue } from 'recoil';
import Repayment from './repayment';
import { repaymentsState } from '../../../../recoil-state/general-states';
import detailsIcon from '../../../../common-icons/details-icon.svg';
import { useHistory } from 'react-router-dom';
import PdfStatements from '../../PdfStatements';

const PaidPayroll = () => {
  let history = useHistory();

  const repayments = useRecoilValue(repaymentsState);
  // console.log('repayments',repayments)
  const getRepaymentDisplay = (repayment: GetRepaymentDto) => {
    return <Repayment {...repayment} key={repayment.uuid} />;
  };
  let processingRepayments: any[] = [];
  let stillInRepaymentRepayments: any[] = [];
  let completeRepayments: any[] = [];
  if (repayments) {
    repayments
      .filter((repayment) => repayment.status != 'New')
      .forEach((repayment) => {
        if (repayment.status == 'Complete') {
          completeRepayments.push(getRepaymentDisplay(repayment));
        // } else if (
        //   repayment.status == 'Request In - Review Payroll' ||
        //   repayment.status == 'Funding Approved, Awaiting Wire' ||
        //   repayment.status == 'Wire Entered, Awaiting Approval'
        // ) {
        //   processingRepayments.push(getRepaymentDisplay(repayment));
        // } else {
        //   stillInRepaymentRepayments.push(
        //     getRepaymentDisplay(repayment),
        //   );
        }
      });
  }

  return (

    <>
        {completeRepayments.length > 0 && (
          <>
            <div id="repayments-container">
              <p
                id="still-in-repayment-title"
                className="repayment-section-title"
              >
                Paid Payroll Advances{' '}
              </p>

              <div className="still-in-repayment-header">
                <div className="ActiveRepayment-col">ID</div>
                <div className="ActiveRepaymentarr-col">Payments Made
                <img className="" src="Arrowupper.svg" height="22.01" width="22.01" style={{ marginLeft: '10px'}}/>
                </div>
                <div className="ActiveRepaymentarr-col">
                  Outstanding Balance
                  <img className="" src="Arrowupper.svg" height="22.01" width="22.01" style={{ marginLeft: '10px'}}/></div>
                <div className="ActiveRepayment-col">Final payment date</div>
                <div className="ActiveRepayment-col">
                  Date Funded
                </div>
                <div className="ActiveRepayment-col"> </div>
              </div>
              {completeRepayments}
            </div>
          </>
        )}
      </>

    // --------- Static Page code
    // <div className="Activerepayment-wrapper" onClick={() => {}}>
    //   <>
    //   <div className="Advance-repayment-heading">
    //     <p className='Active-Payroll'>Paid Payroll Advances</p>
    //       {/* <p className="repayment-section-title">Open Loans</p> */}
    //         <div className="still-in-repayment-header">
    //           <div className="ActiveRepayment-col">ID</div>
    //           <div className="ActiveRepaymentarr-col">Payments Made
    //           <img className="" src="Arrowupper.svg" height="22.01" width="22.01"/></div>
    //           <div className="ActiveRepaymentarr-col">Outstanding Balance
    //           <img className="" src="Arrowupper.svg" height="22.01" width="22.01"/></div>
    //           <div className="ActiveRepayment-col">Final payment date</div>
    //           <div className="ActiveRepayment-col">Date Funded</div>
    //           <div className="ActiveRepayment-col"> </div>
    //         </div>
    //         <div className="still-in-repayment-data">
    //           <div className="Details-col">
    //             <img className="loan-image" src="Money.svg" height="22.01" width="22.01"/>
    //             <a href="#" className="details">1544</a>
    //             </div>
    //           {/* <div className="ActiveRepayment-col-simple"> </div> */}
    //           <div className="ActiveRepayment-simple">$0.00
    //           <div className='progress-barcontainer'></div>
    //             <div className="header-pg-barwrapper">
    //               <div>
    //                 <div className="progress-barcontainer-v2"></div>
    //               </div>
    //               <div className="repayment-infoitemgreen">100%</div>
    //             </div>
    //           </div>
    //           <div className="ActiveRepayment-colred">$0.00</div>
    //           <div className="ActiveRepayment-col-simple">06/31/23</div>
    //           <div className="ActiveRepayment-col-simple">06/01/23</div>
    //           {/* <div className="Details-col">Details</div> */}
    //           <div className="Details-col">
    //             <img className="loan-image" src="/static/media/details-icon.9daa014e.svg" height="22.01" width="22.01"/>
    //             <a href="/ledger-detail/6721b02b-1884-6b61-ce2e-a8addcb6aff9" className="details">Details</a>
    //             </div>
    //         </div>
    //         <div className="still-in-repayment-data">
    //           <div className="Details-col">
    //             <img className="loan-image" src="Money.svg" height="22.01" width="22.01"/>
    //             <a href="#" className="details">1544</a>
    //             </div>
    //           {/* <div className="ActiveRepayment-col-simple"> </div> */}
    //           <div className="ActiveRepayment-simple">$0.00
    //           <div className='progress-barcontainer'></div>
    //             <div className="header-pg-barwrapper">
    //               <div>
    //                 <div className="progress-barcontainer-v2"></div>
    //               </div>
    //               <div className="repayment-infoitemgreen">100%</div>
    //             </div>
    //           </div>
    //           <div className="ActiveRepayment-colred">$0.00</div>
    //           <div className="ActiveRepayment-col-simple">06/31/23</div>
    //           <div className="ActiveRepayment-col-simple">06/01/23</div>
    //           {/* <div className="Details-col">Details</div> */}
    //           <div className="Details-col">
    //             <img className="loan-image" src="/static/media/details-icon.9daa014e.svg" height="22.01" width="22.01"/>
    //             <a href="/ledger-detail/6721b02b-1884-6b61-ce2e-a8addcb6aff9" className="details">Details</a>
    //             </div>
    //         </div>
    //         <div className="still-in-repayment-data">
    //           <div className="Details-col">
    //             <img className="loan-image" src="Money.svg" height="22.01" width="22.01"/>
    //             <a href="#" className="details">1544</a>
    //             </div>
    //           {/* <div className="ActiveRepayment-col-simple"> </div> */}
    //           <div className="ActiveRepayment-simple">$0.00
    //           <div className='progress-barcontainer'></div>
    //             <div className="header-pg-barwrapper">
    //               <div>
    //                 <div className="progress-barcontainer-v2"></div>
    //               </div>
    //               <div className="repayment-infoitemgreen">100%</div>
    //             </div>
    //           </div>
    //           <div className="ActiveRepayment-colred">$0.00</div>
    //           <div className="ActiveRepayment-col-simple">06/31/23</div>
    //           <div className="ActiveRepayment-col-simple">06/01/23</div>
    //           {/* <div className="Details-col">Details</div> */}
    //           <div className="Details-col">
    //             <img className="loan-image" src="/static/media/details-icon.9daa014e.svg" height="22.01" width="22.01"/>
    //             <a href="/ledger-detail/6721b02b-1884-6b61-ce2e-a8addcb6aff9" className="details">Details</a>
    //             </div>
    //         </div>
    //         <div className="still-in-repayment-data">
    //           <div className="Details-col">
    //             <img className="loan-image" src="Money.svg" height="22.01" width="22.01"/>
    //             <a href="#" className="details">1544</a>
    //             </div>
    //           {/* <div className="ActiveRepayment-col-simple"> </div> */}
    //           <div className="ActiveRepayment-simple">$0.00
    //           <div className='progress-barcontainer'></div>
    //             <div className="header-pg-barwrapper">
    //               <div>
    //                 <div className="progress-barcontainer-v2"></div>
    //               </div>
    //               <div className="repayment-infoitemgreen">100%</div>
    //             </div>
    //           </div>
    //           <div className="ActiveRepayment-colred">$0.00</div>
    //           <div className="ActiveRepayment-col-simple">06/31/23</div>
    //           <div className="ActiveRepayment-col-simple">06/01/23</div>
    //           {/* <div className="Details-col">Details</div> */}
    //           <div className="Details-col">
    //             <img className="loan-image" src="/static/media/details-icon.9daa014e.svg" height="22.01" width="22.01"/>
    //             <a href="/ledger-detail/6721b02b-1884-6b61-ce2e-a8addcb6aff9" className="details">Details</a>
    //             </div>
    //         </div>
    //         <div className="still-in-repayment-data">
    //           <div className="Details-col">
    //             <img className="loan-image" src="Money.svg" height="22.01" width="22.01"/>
    //             <a href="#" className="details">1544</a>
    //             </div>
    //           {/* <div className="ActiveRepayment-col-simple"> </div> */}
    //           <div className="ActiveRepayment-simple">$0.00
    //           <div className='progress-barcontainer'></div>
    //             <div className="header-pg-barwrapper">
    //               <div>
    //                 <div className="progress-barcontainer-v2"></div>
    //               </div>
    //               <div className="repayment-infoitemgreen">100%</div>
    //             </div>
    //           </div>
    //           <div className="ActiveRepayment-colred">$0.00</div>
    //           <div className="ActiveRepayment-col-simple">06/31/23</div>
    //           <div className="ActiveRepayment-col-simple">06/01/23</div>
    //           {/* <div className="Details-col">Details</div> */}
    //           <div className="Details-col">
    //             <img className="loan-image" src="/static/media/details-icon.9daa014e.svg" height="22.01" width="22.01"/>
    //             <a href="/ledger-detail/6721b02b-1884-6b61-ce2e-a8addcb6aff9" className="details">Details</a>
    //             </div>
    //         </div>
    //     </div>
    //   </>
    // </div>
  );
};

export default PaidPayroll;
