import './EnrollNow.scss';
import CheckIcon from './Icons/Check.svg';
import enrollIcon from './Icons/enrollIcon.svg';
import approvalIcon from './Icons/approvalIcon.svg';
import fundedIcon from './Icons/fundedIcon.svg';
import star from './Icons/star.svg';
import payroUser from './Icons/payroUser.svg';
import quoteIcon from './Icons/quoteIcon.svg';
import ippaLogo from './Icons/ippaLogo.svg';
import apaLogo from './Icons/apaLogo.svg';
import outerCircle from './Icons/OuterCircle.svg';
export const EnrollNowDetails = () => {
  return (
    <>
      <div className="enroll-main-wrapper">
        <div className="enroll-header-wrapper">
          <p className="enroll-now-text ">Enroll Now</p>
          <p className="enroll-explanation">
            Get instant access to funding for payroll, whenever you
            need it
          </p>
        </div>
        <div className="benefits-wrapper ">
          <div className="approval-icon-and-text-wrapper">
            <div className="image-container">
              <img id="benefits-check-circle" src={outerCircle} />
            </div>
            <div className="image-container-two">
              <img src={CheckIcon} />
            </div>
            <p className="benefits-list-style ">
              High approval rates
            </p>
          </div>
          <div className="approval-icon-and-text-wrapper">
            <div className="image-container">
              <img id="benefits-check-circle" src={outerCircle} />
            </div>
            <div className="image-container-two">
              <img src={CheckIcon} />
            </div>
            <p className="benefits-list-style ">
              Same day funding up to $500k
            </p>
          </div>
          <div className="approval-icon-and-text-wrapper">
            <div className="image-container">
              <img id="benefits-check-circle" src={outerCircle} />
            </div>
            <div className="image-container-two">
              <img src={CheckIcon} />
            </div>
            <p className="benefits-list-style ">Free to enroll</p>
          </div>
          <div className="approval-icon-and-text-wrapper">
            <div className="image-container">
              <img id="benefits-check-circle" src={outerCircle} />
            </div>
            <div className="image-container-two">
              <img src={CheckIcon} />
            </div>
            <p className="benefits-list-style ">
              No impact on credit
            </p>
          </div>
          <div className="approval-icon-and-text-wrapper">
            <div className="image-container">
              <img id="benefits-check-circle" src={outerCircle} />
            </div>
            <div className="image-container-two">
              <img src={CheckIcon} />
            </div>
            <p className="benefits-list-style ">
              No paperwork needed
            </p>
          </div>
        </div>
        <div className="info-boxes-wrapper">
          <div className="info-box">
            <p id="info-boxes-circle">
              <span className="circle-content-style">1</span>
            </p>
            <div className="info-box-text-and-icon-wrapper">
              <div>
                <p className="info-box-title">Enroll</p>
                <p className="info-box-subtitle">Under 5 minutes</p>
              </div>
              <div>
                {' '}
                <img src={enrollIcon} />
              </div>
            </div>
          </div>
          <div className="info-box">
            <p id="info-boxes-circle">
              <span className="circle-content-style">2</span>
            </p>
            <div className="info-box-text-and-icon-wrapper">
              <div>
                <p className="info-box-title">Get approved</p>
                <p className="info-box-subtitle">1-2 business days</p>
              </div>
              <div>
                {' '}
                <img src={approvalIcon} />
              </div>
            </div>{' '}
          </div>

          <div className="info-box">
            <p id="info-boxes-circle">
              <span className="circle-content-style">3</span>
            </p>
            <div className="info-box-text-and-icon-wrapper">
              <div>
                <p className="info-box-title"> Get funded</p>
                <p className="info-box-subtitle">
                  Same-day you request it
                </p>
              </div>
              <div>
                {' '}
                <img src={fundedIcon} />
              </div>
            </div>
          </div>
        </div>
        <div className="purple-info-container">
          <div>
            <span>
              <img src={star} />
              <img src={star} className="star-icon" />
              <img src={star} className="star-icon" />
              <img src={star} className="star-icon" />
              <img src={star} className="star-icon" />
            </span>
            <p className="purple-container-text">
              “It’s like our free payroll insurance. If I run into a
              situation where I have to make up the deficit for
              payroll, I have Payro as a backup. It’s an excellent
              stopgap measure at a very fair rate.”
            </p>
          </div>
          <div className="user-and-desc-wrapper">
            <div>
              <img src={payroUser} />
              <p id="white-circle">
                <img src={quoteIcon} />
              </p>
            </div>
            <div>
              <p className="payro-user">Vernon Pothemont</p>
              <p className="payro-user-desc">CMS LAW</p>
            </div>
          </div>
        </div>
        <div>
          <p className="trusted-text-style ">
            TRUSTED BY ASSOCIATIONS ACROSS THE US{' '}
          </p>
          <div className="footer-logo">
            <ul>
              <li>
                <a href="#">
                  <img src={ippaLogo} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={apaLogo} alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="/AICPA-logo.png" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};


export default EnrollNowDetails;
