import Icon from '../../../common-icons/maintenance.svg';
import closeX from '../../../common-icons/closex.svg';
import imp_img from '../../../common-icons/important.png'
import frontarrow from '../../../common-icons/arrowimg_dark.svg'
import PayroButton from '../../../widgets/PayroButton';
import { useRecoilState, useRecoilValue } from 'recoil';
import { mainSectionState } from '../../../recoil-state/general-states';
import {
  fundingStepState,
} from '../../../recoil-state/request-funding-states';
import './index.scss';
const ImportantUpdate = () => {
  const mainSection = useRecoilValue(mainSectionState);
  const [fundingStep, setFundingStep] =
  useRecoilState(fundingStepState);
  return (
    <>
      <div id="deal-failed-wrapper">
        <img
          id={
            mainSection === 'RepeatFunding'
              ? 'failed-icon-repeat'
              : 'imp-icon'
          }
          src={imp_img}
        ></img>
        <h2 id="payroll-imp-title">Important Update!</h2>
        <div id="payroll-failed-explanation">
          <p>
            {' '}
            We have launched a brand-new funding option designed for businesses in New York. Please note that the terms and rates for this product are different from what is currently displayed on our website.
          </p>
        </div>
        <div id="payroll-imp-update">
          {' '}
          <p>
            Feel free to get in touch with us at 833.271.4499 or email <a href="processing@payrofinance.com">processing@payrofinance.com</a> for additional information.
          </p>
        </div>
        <PayroButton
            className={`continue-btn`}
            centered={true}
            onClick={() => {
              // setFundingStep('cash-advance-agreement')
              window.location.reload()
            }}
            buttonSize="large"
            endIcon={frontarrow}
          >
            Continue
        </PayroButton>
      </div>
    </>
  );
};

export default ImportantUpdate;
