import TitleSection from '../../../Header/title-section';
import FinchConnector from '../../../widgets/FinchConnector';
import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { isFeatureOn } from '../../../utils';
import { getClient } from '../../../api-utils/general-utils';

import PayroInput from '../../../widgets/PayroInput';
import PayroSelect from '../../../widgets/PayroSelectv2';
import Loader from '../../../widgets/Loader';

import companyList from './companies';
import { GetAccountDto } from '../../../api-utils/generated-client';
import { useRecoilValue, useRecoilState } from 'recoil';
import { isNextButtonDisabledState } from '../../../recoil-state/application-stage-states';
import {
  finchPayrollInfoState,
  payrollCompanyState,
  payrollAmountState,
  numOfEmployeesState,
  payrollFrequencyState,
  opportunityRecordState,
  checkedNotUploadingPayrollState,
} from '../../../recoil-state/finch-states';
import {
  accountRecordState,
  opportunityState,
} from '../../../recoil-state/general-states';
import VerifyPayrollUploader from '../../Dashboard/RequestMoreCredit/VerifyPayrollUploader';
import { uploadedFilesState } from '../../../recoil-state/request-funding-states';

//gets from api in for "2021-04-15"
//needs to be stored in same form, but datepicker sets as date object and needs to be initialized as date object
const noValidations = {
  payrollAmount: false,
};
const PayrollInfo = () => {
  const finchFeatureOn: boolean = isFeatureOn('Finch');
  const [showPayrollPickers, setShowPayrollPickers] =
    useState<boolean>(true);
  const [loadedFinch, setLoadedFinch] =
    useState<boolean>(finchFeatureOn);
  const [validationsToShow, setValidationsToShow] =
    useState<any>(noValidations);
  const [finchPayrollInfo, setFinchPayrollInfo] = useRecoilState<any>(
    finchPayrollInfoState,
  );
  const [checkISO, setcheckISO] = useState(true);
  const [opportunityRecord, setOpportunityRecord] =
    useRecoilState<any>(opportunityRecordState);
  const [accountInfo, setAccountInfo] = useState<GetAccountDto>();
  const [isNextDisabled, setIsNextDisabled] = useRecoilState(
    isNextButtonDisabledState,
  );
  const [payrollCompany, setPayrollCompany] = useRecoilState<any>(
    payrollCompanyState,
  );
  const [payrollAmount, setPayrollAmount] = useRecoilState<any>(
    payrollAmountState,
  );
  const [numOfEmployees, setNumOfEmployees] = useRecoilState<any>(
    numOfEmployeesState,
  );
  const [payrollFrequency, setPayrollFrequency] = useRecoilState<any>(
    payrollFrequencyState,
  );
  const [uploadedFiles, setUploadedFiles] = useRecoilState(
    uploadedFilesState,
  );
  const [checkedNotUploadingPayroll, setCheckedNotUploadingPayroll] =
    useRecoilState<any>(checkedNotUploadingPayrollState);
  const isUnderwriting = true;
  const AccountInfo = useRecoilValue(accountRecordState);
  const [gotApi, setGotApi] = useState(false);
  const opportunities = useRecoilValue(opportunityState);
  const payrollCompanyOptions = companyList.map((company) => {
    return {
      value: company.id,
      label: company.name,
    };
  });
  const companies = payrollCompanyOptions.map(
    (allInfo) => allInfo.label,
  );

  useEffect(() => {
    const getApiInfo = async () => {
      const apiClient = await getClient();

      if (!apiClient) {
        return;
      }
      if (!payrollCompany && !AccountInfo.payroll_company) {
        let company_id = localStorage.getItem('rs');
        if (company_id) {
          const getPayrollCompany = async (uuid: string) => {
            const res = await axios.post(
              `${process.env.REACT_APP_API}/contacts/get-Payroll-Company/`,
              {
                company_id: uuid,
              },
            );
            return res;
          };

          getPayrollCompany(company_id).then((res) => {
            let cmpyname = res?.data?.PayrollISO_Type__c;
            if (
              (cmpyname && cmpyname == 'Payroll') ||
              cmpyname != 'ISO'
            ) {
              setPayrollCompany(res?.data?.Name);
              setcheckISO(false);
            } else {
              setcheckISO(false);
            }
          });
        } else {
          setcheckISO(false);
        }
      } else {
        setPayrollCompany(AccountInfo.payroll_company);
        setcheckISO(false);
      }
      if (!opportunities) return;
      const mostRecentOpportunity = opportunities[0];
      setOpportunityRecord(mostRecentOpportunity);
      setPayrollAmount(
        mostRecentOpportunity.average_payroll_amount?.toString(),
      );
      setNumOfEmployees(
        mostRecentOpportunity.number_of_employees_range,
      );
      if (mostRecentOpportunity.how_often_do_you_run_payroll) {
        setPayrollFrequency(
          mostRecentOpportunity.how_often_do_you_run_payroll
            .toLowerCase()
            .replaceAll(' ', ''),
        );
      }
    };

    getApiInfo().then(() => setGotApi(true));
  }, []);

  useEffect(() => {
    if (AccountInfo && AccountInfo.legal_address_state == 'NY') {
      setIsNextDisabled(
        (!finchFeatureOn || (loadedFinch && showPayrollPickers)) &&
          (!payrollFrequency ||
            !numOfEmployees ||
            !payrollAmount ||
            (uploadedFiles.length < 1 &&
              checkedNotUploadingPayroll == false) ||
            (gotApi && !payrollCompany)),
      );
    } else {
      setIsNextDisabled(
        (!finchFeatureOn || (loadedFinch && showPayrollPickers)) &&
          (!payrollFrequency ||
            !numOfEmployees ||
            !payrollAmount ||
            (uploadedFiles.length < 1 &&
              checkedNotUploadingPayroll == false) ||
            (gotApi && !payrollCompany)),
      );
    }
  });

  // if (!gotApi) {
  //   return <Loader />;
  // }
  if (!gotApi || checkISO) {
    return <Loader />;
  }
  const validationFunctions: { [key: string]: boolean } = {
    payrollAmount: !payrollAmount || payrollAmount.length < 1,
  };
  return (
    <>
      {AccountInfo.legal_address_state &&
      AccountInfo.legal_address_state == 'NY' ? (
        <div>
          <TitleSection
            pageTitle="Payroll Info"
            pageNumAndOutOf="2/4"
            title="Tell us about your payroll and employees"
          />
          {finchFeatureOn && (
            <FinchConnector
              associatedPhase="opportunity"
              refresh="no"
              isConnectedCallback={() => {
                setShowPayrollPickers(false);
                setLoadedFinch(true);
              }}
              isDisconnectedCallback={() => setLoadedFinch(true)}
              onDisconnect={() => {
                setShowPayrollPickers(true);
                window.location.reload();
              }}
            />
          )}

          {(!finchFeatureOn ||
            (loadedFinch && showPayrollPickers)) && (
            <>
              {}
              {AccountInfo &&
              AccountInfo.legal_address_state == 'NY' ? (
                <PayroInput
                  label="What payroll company do you use?"
                  value={payrollCompany}
                  onChange={(selectedCompany: any) => {
                    setPayrollCompany(selectedCompany);
                  }}
                  onBlurFunction={(e: any) => e.stopPropagation()}
                  isSearch={true}
                  searchOptions={companies}
                  placeholder="Search Payroll Companies"
                  variant="white"
                  error={payrollCompany == ''}
                />
              ) : (
                <PayroInput
                  label="What payroll company do you use?"
                  value={payrollCompany}
                  onChange={(selectedCompany: any) => {
                    setPayrollCompany(selectedCompany);
                  }}
                  onBlurFunction={(e: any) => e.stopPropagation()}
                  isSearch={true}
                  searchOptions={companies}
                  placeholder="Search Payroll Companies"
                  variant="white"
                  error={payrollCompany == ''}
                />
              )}
              {/* <PayroSelect
                options={payrollCompanyOptions}
                disabled={finchPayrollInfo ? true : false}
                defaultSelectedValue={payrollCompany}
                placeholderText="Select Payroll Companies"
                onSelect={(selectedCompany: any) => {
                  // setPayrollCompany(selectedCompany);
                  setPayrollCompany(selectedCompany);
                }}
                selectName="payrollcompany"
                label="What payroll company do you use?"
              /> */}
              {/* {
                console.log('payrollCompany',payrollCompany) 
              } */}
              <PayroSelect
                options={[
                  { label: 'Less than 5k', value: 'less than 5k' },
                  { label: '5 - 20k', value: '5 - 20k' },
                  { label: '21 - 50k', value: '21 - 50k' },
                  { label: '51 - 500k', value: '51 - 500k' },
                ]}
                disabled={finchPayrollInfo ? true : false}
                defaultSelectedValue={
                  payrollAmount ? payrollAmount : ''
                }
                placeholderText="Select a range"
                onSelect={(e: any) => setPayrollAmount(e)}
                selectName="payrollAmount"
                label="What is your average payroll amount?"
              />

              <PayroSelect
                options={
                  //"1-5" | "6-20" | "21-50" | "51-100" | "101-200" | "moreThan200"
                  [
                    { label: '1 - 5', value: '1-5' },
                    { label: '6 - 20', value: '6-20' },
                    { label: '21 - 50', value: '21-50' },
                    { label: '51 - 100', value: '51-100' },
                    { label: '101 - 200', value: '101-200' },
                    { label: 'More than 200', value: 'moreThan200' },
                  ]
                }
                disabled={finchPayrollInfo ? true : false}
                defaultSelectedValue={
                  numOfEmployees ? numOfEmployees : ''
                }
                placeholderText="Select a range"
                onSelect={(e: any) => setNumOfEmployees(e)}
                selectName="numOfEmployees"
                label="How many employees do you have?"
              />

              <PayroSelect
                disabled={finchPayrollInfo ? true : false}
                options={[
                  { label: 'Weekly', value: 'weekly' },
                  { label: 'Bi-weekly', value: 'biweekly' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Bi-monthly', value: 'bimonthly' },
                ]}
                defaultSelectedValue={
                  payrollFrequency ? payrollFrequency : ''
                }
                placeholderText="Select how often"
                onSelect={(e: any) => setPayrollFrequency(e)}
                selectName="payrollFrequency"
                label="How often do you run payroll?"
              />
              {/* <PayroInput
                onFocus={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    payrollAmount: false,
                  })
                }
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    payrollAmount: true,
                  })
                }
                error={
                  validationsToShow.payrollAmount &&
                  validationFunctions.payrollAmount
                }
                // type='currency'
                onChange={(e: any) =>
                  setPayrollAmount(e)
                }
                required
                value={payrollAmount}
                placeholder="Average Payroll Amount"
                id="payrollAmount"
                label="What’s your average payroll amount?"
                variant="standard"
              /> */}
              {/* <PayroSelect
                options={[
                  { label: 'Less than 5k', value: 'less than 5k' },
                  { label: '5 - 20k', value: '5 - 20k' },
                  { label: '21 - 50k', value: '21 - 50k' },
                  { label: '51 - 500k', value: '51 - 500k' },
                ]}
                disabled={finchPayrollInfo ? true : false}
                defaultSelectedValue={payrollAmount ? payrollAmount : ''}
                placeholderText="Select a range"
                onSelect={(e: any) => setPayrollAmount(e)}
                selectName="payrollAmount"
                label="What is your average payroll amount?"
              /> */}

              {
                <VerifyPayrollUploader
                  isUnderwriting={isUnderwriting}
                />
              }
              {/* <div>
                <div className="input-container">
                  <span className="input-label required">What’s your average payroll amount?</span>
                    <div className="input-wrapper">
                    <img src="Avgamt.svg" className="" alt=""/>
                    <input placeholder=""
                    type="text" className="payro-actual-input not-focused" />
                  <div></div>
                  </div>
                </div>
              </div> */}
            </>
          )}
        </div>
      ) : (
        <div>
          <TitleSection
            pageTitle="Payroll Info"
            pageNumAndOutOf="2/4"
            title="Tell us about your payroll and employees"
          />
          {finchFeatureOn && (
            <FinchConnector
              associatedPhase="opportunity"
              refresh="no"
              isConnectedCallback={() => {
                setShowPayrollPickers(false);
                setLoadedFinch(true);
              }}
              isDisconnectedCallback={() => setLoadedFinch(true)}
              onDisconnect={() => {
                setShowPayrollPickers(true);
                window.location.reload();
              }}
            />
          )}

          {(!finchFeatureOn ||
            (loadedFinch && showPayrollPickers)) && (
            <>
              {}
              {/* <PayroInput
              label="What payroll company do you use?"
              value={payrollCompany}
              onChange={(selectedCompany: any) => {
                setPayrollCompany(selectedCompany);
              }}
              onBlurFunction={(e: any) => e.stopPropagation()}
              isSearch={true}
              searchOptions={companies}
              placeholder="Search Payroll Companies"
              variant="white"
              error={payrollCompany == ''}
            /> */}

              <PayroInput
                label="What payroll company do you use?"
                value={payrollCompany}
                onChange={(selectedCompany: any) => {
                  setPayrollCompany(selectedCompany);
                }}
                onBlurFunction={(e: any) => e.stopPropagation()}
                isSearch={true}
                searchOptions={companies}
                placeholder="Search Payroll Companies"
                variant="white"
                error={payrollCompany == ''}
              />

              <PayroSelect
                options={[
                  { label: 'Less than 5k', value: 'less than 5k' },
                  { label: '5 - 20k', value: '5 - 20k' },
                  { label: '21 - 50k', value: '21 - 50k' },
                  { label: '51 - 500k', value: '51 - 500k' },
                ]}
                disabled={finchPayrollInfo ? true : false}
                defaultSelectedValue={payrollAmount}
                placeholderText="Select a range"
                onSelect={(e: any) => setPayrollAmount(e)}
                selectName="payrollAmount"
                label="What is your typical payroll amount?"
              />
              <PayroSelect
                options={
                  //"1-5" | "6-20" | "21-50" | "51-100" | "101-200" | "moreThan200"
                  [
                    { label: 'Less than 5', value: 'Less than 5' },
                    { label: '6 - 20', value: '5-20' },
                    { label: '21 - 50', value: '21-50' },
                    { label: '51 - 100', value: '51-100' },
                    { label: '101 - 200', value: '101-200' },
                    { label: 'More than 200', value: 'moreThan200' },
                  ]
                }
                disabled={finchPayrollInfo ? true : false}
                defaultSelectedValue={
                  numOfEmployees ? numOfEmployees : ''
                }
                placeholderText="Select a range"
                onSelect={(e: any) => setNumOfEmployees(e)}
                selectName="numOfEmployees"
                label="How many employees do you have?"
              />

              <PayroSelect
                disabled={finchPayrollInfo ? true : false}
                options={[
                  { label: 'Weekly', value: 'weekly' },
                  { label: 'Bi-weekly', value: 'biweekly' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Bi-monthly', value: 'bimonthly' },
                ]}
                defaultSelectedValue={
                  payrollFrequency ? payrollFrequency : ''
                }
                placeholderText="Select how often"
                onSelect={(e: any) => setPayrollFrequency(e)}
                selectName="payrollFrequency"
                label="How often do you run payroll?"
              />
              {
                <VerifyPayrollUploader
                  isUnderwriting={isUnderwriting}
                />
              }
            </>
          )}
          <>{}</>
        </div>
      )}
    </>
  );
};

export default PayrollInfo;
