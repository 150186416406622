import { useEffect, useState } from 'react';
import PayroInput from '../../../widgets/PayroInput';
import PayroButton from '../../../widgets/PayroButton';
import PayroSelect from '../../../widgets/PayroSelect';

import './contact-info.css';

import { PLACEHOLDER } from './contact-utils';
import { useContext } from 'react';
import { MessageContext } from '../../../context';

import { Contact } from '../../../common-types';
import { useRecoilState } from 'recoil';
import {
  contactsState,
  userIsOwnerState,
} from '../../../recoil-state/application-stage-states';
import StatesAbbreviations from '../CompanyInfo/states';
import {
  CreateContactDto,
  GetAccountDto,
  UserObject,
} from '../../../api-utils/generated-client/';
import {
  accountRecordState,
  allAccountUsersContactInfoState,
  userInfoState,
} from '../../../recoil-state/general-states';


const noValidations = {
  first_name: false,
  last_name: false,
  phone: false,
  email: false,
  percent_of_ownership: false,
  mailing_address: false,
  mailing_address_city: false,
  mailing_address_state: false,
  mailing_address_zip: false,
  birthdate: false,
};

export interface ContactInfoFields {
  first_name?: string;
  last_name?: string;
  percent_of_ownership?: number;
  phone?: string;
  email?: string;
  uuid: string;
  social_security_number?: string;
  title?: string;
  isUser: boolean;
  birthdate?: string;
  mailing_address?: string;
  mailing_address_city?: string;
  mailing_address_state?: string;
  mailing_address_zip?: string;
}

export interface ContactInfoProps extends ContactInfoFields {
  deleteContact: () => any;
  saveContact: (contactInfoToSave: Partial<ContactInfoFields>) => any;
}

export default (props: ContactInfoProps) => {
  const [contacts, setContacts] =
    useRecoilState<Partial<Contact>[]>(contactsState);

  const [userIsOwner, setUserIsOwner] =
    useRecoilState(userIsOwnerState);

  const hasOwnershipPercent =
    props.percent_of_ownership && props.percent_of_ownership > 0;

  const messageContext = useContext(MessageContext);
  const [AccountInfo, setAccountInfo] = useRecoilState<GetAccountDto>(
    accountRecordState,
  );
  const [validationsToShow, setValidationsToShow] =
    useState<any>(noValidations);

  const contactInfo = { ...props };

  const getSocialSecurityAsNumbersArray = (
    ssNum: string | undefined,
  ): Array<number | undefined> | undefined => {
    if (!ssNum) {
      return;
    }
    return ssNum.split('').map((el) => {
      if (el == '?') {
        return undefined;
      } else {
        return parseInt(el);
      }
    });
  };
  const isTenNumbers = /^[0-9]{10}$/;

  return (
    <div className="contact-form-wrapper">
      {contactInfo.isUser && (
        <>
          {/* <div>
            <PayroInput
              required
              value={
                props.title != PLACEHOLDER ? props.title : ''
              }
              id="title"
              label="Title"
              variant="standard"
              placeholder="CEO, CFO"
              onChange={(eventValue: any) => {
                const newContactInfo = { title: eventValue };
                props.saveContact(newContactInfo);
              }}
              onBlurFunction={(e: any) => {}}
              error={!props.title || props.title?.length < 1}
            />
          </div> */}
          <div>
          
          <PayroInput
            type='number'
            value={
              props.percent_of_ownership ? props.percent_of_ownership : ''
            }
            id="percent_of_ownership"
            label="Percent of Ownership"
            variant="standard"
            placeholder="%"
            onChange={(eventValue: any) => {
              const newContactInfo = { percent_of_ownership: eventValue };

              props.saveContact(newContactInfo);
            }}
            onBlurFunction={() =>
              setValidationsToShow({
                ...validationsToShow,
                percent_of_ownership: true,
              })
            }
            
            error={validationsToShow.percent_of_ownership && !props.percent_of_ownership}
          />
          </div>
          <div>
            <PayroInput
              required
              value={
                props.mailing_address != PLACEHOLDER ? props.mailing_address : ''
              }
              id="mailing_address"
              label="Home Address"
              variant="standard"
              placeholder="Address"
              onChange={(eventValue: any) => {
                const newContactInfo = { mailing_address: eventValue };
                props.saveContact(newContactInfo);
              }}
              
              onBlurFunction={() =>
                setValidationsToShow({
                  ...validationsToShow,
                  mailing_address: true,
                })
              }
              error={ validationsToShow.mailing_address && (!props.mailing_address || props.mailing_address?.length < 1)}
            />
          </div>
          <div>
            <div id="city-state-zip">
                <div>
                  <PayroInput
                    value={
                      props.mailing_address_city != PLACEHOLDER ? props.mailing_address_city : ''
                    }
                    id="City"
                    label="City"
                    variant="standard"
                    placeholder="City"
                    onChange={(eventValue: any) => {
                      const newContactInfo = { mailing_address_city: eventValue };
      
                      props.saveContact(newContactInfo);
                    }}
                    required
                    onBlurFunction={() =>
                      setValidationsToShow({
                        ...validationsToShow,
                        mailing_address_city: true,
                      })
                    }
                    error={ validationsToShow.mailing_address_city && (!props.mailing_address_city || props.mailing_address_city?.length < 1)}
                  />
                </div>
                <div id="company-state-wrapper">
                  <PayroSelect
                    selectName="home-state"
                    placeholderText=""
                    required
                    onSelect={(e: any) =>
                      {
                        const newContactInfo = { mailing_address_state: e.target.value };
                        props.saveContact(newContactInfo);
                        setValidationsToShow({
                          ...validationsToShow,
                          mailing_address_state: true
                        })
                      }
                    }
                    wrapperAdditionalClasses = {validationsToShow.mailing_address_state ? !props.mailing_address_state || props.mailing_address_state?.length < 1 ? `error` : '' : '' }
                    label="State"
                    options={StatesAbbreviations}
                    defaultSelectedValue={props.mailing_address_state}
                  />
                </div>
                <div>
                  <PayroInput
                    value={
                      props.mailing_address_zip != PLACEHOLDER ? props.mailing_address_zip : ''
                    }
                    id="mailing_address_zip"
                    label="Zip"
                    variant="standard"
                    placeholder="Zip"
                    onChange={(eventValue: any) => {
                      const newContactInfo = { mailing_address_zip: eventValue };
                      props.saveContact(newContactInfo);
                    }}
                    required
                    onBlurFunction={() =>
                      setValidationsToShow({
                        ...validationsToShow,
                        mailing_address_zip: true,
                      })
                    }
                    error={ validationsToShow.mailing_address_zip && (!props.mailing_address_zip || props.mailing_address_zip?.length < 1)}
                  />
                </div>
            </div>
            <div>
              <PayroInput
                value={
                  props.birthdate ? props.birthdate : ''
                }
                required
                type='date'
                id="birthdate"
                label="Date of Birth"
                variant="standard"
                placeholder="Date of Birth"
                onChange={(eventValue: any) => {
                  const newContactInfo = { birthdate: eventValue };
                  props.saveContact(newContactInfo);
                }}
                error={validationsToShow.birthdate && (!props.birthdate || props?.birthdate.length < 1)}
                onBlurFunction={() =>
                  setValidationsToShow({
                    ...validationsToShow,
                    birthdate: true,
                  })
                }
              />
              <span id='background-checks'>Required for background checks and to facilitate a quicker decision</span>
            </div>
            {/* <div className="input-container">
              <span className="input-label required">Date of Birth</span>
                <div className="input-wrapper">
                  <PayroInput
                    value={
                      props.birthdate ? props.birthdate : ''
                    }
                    type='date'
                    id="birthdate"
                    label="Date of Birth"
                    variant="standard"
                    placeholder="Zip"
                    onChange={(eventValue: any) => {
                      const newContactInfo = { birthdate: eventValue };
                      props.saveContact(newContactInfo);
                    }}
                    onBlurFunction={(e: any) => {}}
                    // error={!props.mailing_address_zip || props.mailing_address_zip?.length < 1}
                  />
                  <input 
                    placeholder="DD/MM/YYYY" 
                    type="input"
                    className="payro-actual-input not-focused" 
                    onChange={(e) => {
                      const newContactInfo = { birthdate: e.target.value };
                      props.saveContact(newContactInfo);
                    }}
                    value={props.birthdate ? props.birthdate : ''}
                  />
              </div>
            </div> */}
          </div>
        </>
      )}

      {!contactInfo.isUser && (
        <>
          <div className="owner-info-name-inputs">
            <PayroInput
              required
              value={
                props.first_name != PLACEHOLDER ? props.first_name : ''
              }
              id="first-name"
              label="Owner's First Name"
              variant="standard"
              placeholder="First Name"
              onChange={(eventValue: any) => {
                const newContactInfo = { first_name: eventValue };

                props.saveContact(newContactInfo);
              }}
              onBlurFunction={() =>
                setValidationsToShow({
                  ...validationsToShow,
                  first_name: true,
                })
              }
              error={validationsToShow.first_name && (props.first_name == PLACEHOLDER || !props.first_name || props.first_name?.length < 1)}
            />
            
            <PayroInput
              required
              value={
                props.last_name != PLACEHOLDER ? props.last_name : ''
              }
              id="last-name"
              label="Last Name"
              variant="standard"
              placeholder="Last Name"
              onChange={(eventValue: any) => {
                const newContactInfo = { last_name: eventValue };

                props.saveContact(newContactInfo);
              }}
              onBlurFunction={() =>
                setValidationsToShow({
                  ...validationsToShow,
                  last_name: true,
                })
              }
              error={validationsToShow.last_name && (props.last_name == PLACEHOLDER || !props.last_name || props.last_name?.length < 1)}
            />
          </div>
          <div>
            <PayroInput
              value={
                props.birthdate ? props.birthdate : ''
              }
              required
              type='date'
              id="birthdate"
              label="Date of Birth"
              variant="standard"
              placeholder="Date of Birth"
              onChange={(eventValue: any) => {
                const newContactInfo = { birthdate: eventValue };
                props.saveContact(newContactInfo);
              }}
              
              onBlurFunction={() =>
                setValidationsToShow({
                  ...validationsToShow,
                  birthdate: true,
                })
              }
              error={validationsToShow.birthdate && (!props.birthdate || props.birthdate?.length < 1)}
            />
          </div>
        </>
      )}

      {!contactInfo.isUser && (
        <>
          <div className="form-field">
            <PayroInput
              required
              value={props.email}
              id="email"
              label="Owner's Email"
              variant="standard"
              placeholder="example@domain.com"
              onChange={(eventValue: any) => {
                const newContactInfo = { email: eventValue };

                props.saveContact(newContactInfo);
              }}
              
              onBlurFunction={() =>
                setValidationsToShow({
                  ...validationsToShow,
                  email: true,
                })
              }
              error={
                validationsToShow.email
                &&
                (!props.email ||
                props.email.length < 5 ||
                props.email.indexOf('@') < 1 ||
                props.email.indexOf('.') < 3)
              }
            />
            <PayroInput
              required
              value={props.phone?.replaceAll('+1', '')}
              id="phone"
              label="Owner's Phone"
              variant="standard"
              isPhone={true}
              placeholder="Phone Number"
              onChange={(eventValue: any) => {
                const newContactInfo = { phone: eventValue };

                props.saveContact(newContactInfo);
              }}
              onBlurFunction={() =>
                setValidationsToShow({
                  ...validationsToShow,
                  phone: true,
                })
              }
              
              error={validationsToShow.phone && !props.phone}
            />
            <PayroInput
              type='number'
              value={
                props.percent_of_ownership ? props.percent_of_ownership : ''
              }
              id="percent_of_ownership"
              label="Percent of Ownership"
              variant="standard"
              placeholder="%"
              onChange={(eventValue: any) => {
                const newContactInfo = { percent_of_ownership: eventValue };

                props.saveContact(newContactInfo);
              }}
              onBlurFunction={() =>
                setValidationsToShow({
                  ...validationsToShow,
                  percent_of_ownership: true,
                })
              }
              // onBlurFunction={(e: any) => {}}
              error={!props.percent_of_ownership}
            />
          </div>
          <div>
            <PayroInput
              required
              value={
                props.mailing_address != PLACEHOLDER ? props.mailing_address : ''
              }
              id="mailing_address"
              label="Home Address"
              variant="standard"
              placeholder="Address"
              onChange={(eventValue: any) => {
                const newContactInfo = { mailing_address: eventValue };
                props.saveContact(newContactInfo);
              }}
              onBlurFunction={() =>
                setValidationsToShow({
                  ...validationsToShow,
                  mailing_address: true,
                })
              }
              error={ validationsToShow.mailing_address && (!props.mailing_address || props.mailing_address?.length < 1)}
            />
          </div>
          <div>
            <div id="city-state-zip">
                <div>
                  <PayroInput
                    value={
                      props.mailing_address_city != PLACEHOLDER ? props.mailing_address_city : ''
                    }
                    id="City"
                    label="City"
                    variant="standard"
                    placeholder="City"
                    onChange={(eventValue: any) => {
                      const newContactInfo = { mailing_address_city: eventValue };
      
                      props.saveContact(newContactInfo);
                    }}
                    onBlurFunction={(e: any) => {}}
                    // error={!props.mailing_address_city || props.mailing_address_city?.length < 1}
                  />
                </div>
                <div id="company-state-wrapper">
                  <PayroSelect
                    selectName="home-state"
                    placeholderText=""
                    onSelect={(e: any) =>
                      {
                        const newContactInfo = { mailing_address_state: e.target.value };
                        props.saveContact(newContactInfo);
                      }
                    }
                    label="State"
                    options={StatesAbbreviations}
                    defaultSelectedValue={props.mailing_address_state}
                  />
                </div>
                <div>
                  <PayroInput
                    value={
                      props.mailing_address_zip != PLACEHOLDER ? props.mailing_address_zip : ''
                    }
                    id="mailing_address_zip"
                    label="Zip"
                    variant="standard"
                    placeholder="Zip"
                    onChange={(eventValue: any) => {
                      const newContactInfo = { mailing_address_zip: eventValue };
                      props.saveContact(newContactInfo);
                    }}
                    onBlurFunction={(e: any) => {}}
                    // error={!props.mailing_address_zip || props.mailing_address_zip?.length < 1}
                  />
                </div>
            </div>
          </div>
        </>
      )}

      {!contactInfo.isUser && (
        <PayroButton
          variant="white"
          disabled={contacts?.length == 2}
          onClick={() => props.deleteContact()}
        >
          Remove Owner
        </PayroButton>
      )}

      {(!contactInfo.isUser || userIsOwner === 'yes') && (
        <div className="form-field">
          {/* <PayroInput
                        // onChange={(e: any) => props.setContactInfo({ ...contactInfo, social_security_number: e })}
                        required
                        value={props.social_security_number}
                        id="social-security"
                        label="Social Security Number"
                        variant="standard"

                        onBlurFunction={(e: any) => {
                            const newContactInfo = {social_security_number: e.target.value}
                            //props.setContactInfo(newContactInfo)
                            props.saveContact(newContactInfo)
                        }}
                    /> */}
          {/* <div className="form-field">
                    <PayroInput
                        key={`${contactInfo.uuid}-ssn`}
                        // onChange={(e: any) => props.setContactInfo({ ...contactInfo, percent_of_ownership: e })}
                        value={contactInfo.social_security_number ?? undefined}
                        id="percent-ownership"
                        label="Social Security Number"
                        isSocialSecurity={true}
                        type="text"

                        onBlurFunction={(e: any) => {
                            const newContactInfo = { social_security_number: e.target.value.replaceAll('-', '') }
                            props.saveContact(newContactInfo)
                        }}
                    />
                </div> */}
          {/* <div className="social-security-wrapper">

                        <span className="input-label"> Social Security Number</span>
                        <FiniteNumbersGroup
                            key={`${contactInfo.uuid}-ssn`}
                            customKeyPrefix={contactInfo.uuid}
                            amountOfInputs={9}
                            isSocialSecurity={true}
                            originalVal={contactInfo.social_security_number ?? undefined}
                            onChangeFunction={(e: any) => {
                                console.log('i am the new e', e)
                                console.log(' I got blurred')
                                const newContactInfo: Partial<ContactInfoFields> = {
                                    social_security_number: e.toString()
                                }
                               props.saveContact(newContactInfo)
                            }} />
                    </div> */}
        </div>
      )}

      {/*
            (!contactInfo.isUser || props.userIsOwner === 'yes') &&
                <div className="form-field">
                    <PayroInput
                        // onChange={(e: any) => props.setContactInfo({ ...contactInfo, percent_of_ownership: e })}
                        value={props.percent_of_ownership}
                        id="percent-ownership"
                        label="Percentage Of Ownership"
                        type="number"

                        onBlurFunction={(e: any) => {
                            let newVal
                            try {
                                newVal = parseInt(e.target.value)
                            } catch (err) {
                                newVal = 0
                            }
                            const newContactInfo = { percent_of_ownership: newVal }
                            props.saveContact(newContactInfo)
                        }}
                    />
                </div>
                    */}
      {/* {(contactInfo.isUser && props.userIsOwner === "no") &&
                <div className="form-field">
                    <PayroInput
                        required
                        value={props.title}
                        id="contact-title"
                        label="Title"
                        variant="standard"
                        placeholder="CFO, CTO..."

                        onBlurFunction={(e: any) => {
                            console.log('I am being blurred')
                            const newContactInfo = { title: e.target.value }                        //props.setContactInfo(newContactInfo)                   props.saveContact(newContactInfo)
                        }}
                    />
                </div>
            } */}
    </div>
  );
};
