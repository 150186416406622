import { formatDate, formatNumberAsDollars } from '../../../utils';
import {
  getLoanIdLedgerDetail,
  getLoanIdToDisplayLedgerDetail,
} from '../ledger-utils';
import './index.scss';
import {
  GetRepaymentDto,
  LoanPayment,
  GetAccountDto,
  NewLoanDebitPayment,
} from '../../../api-utils/generated-client';
import schedulePayment from '../../../common-icons/schedule-payment.png';
import downloadicon from '../../../common-icons/Downlaodicn.svg';
import detailbreakdownicon from '../../../common-icons/ledger-detail-breakdown-icon.svg';
import ledgerdetailicon from '../../../common-icons/ledger-detail-payroll-icon.svg';

import timelineicon from '../../../common-icons/ledger-detail-loan-timeline-icon.svg';

import downloadcontracticon from '../../../common-icons/Paperdownload.svg';
import remitPaymentIcon from '../../../common-icons/remit-payment-icon.svg';
import remitPaymentPurpleIcon from '../../../common-icons/remit-payment-purple-icon.svg';
import checkIcon from '../../../common-icons/check-circle.svg';
import TimelineIconOne from '../../../common-icons/timeline-icon-one.svg';
import TimelineIconTwo from '../../../common-icons/timeline-icon-two.svg';
import TimelineIconThree from '../../../common-icons/timeline-icon-three.svg';
import PayroButton from '../../../widgets/PayroButton';
import { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import backIcon from '../../../common-icons/back-arrow-purple.svg';
import balanceIcon from '../../../common-icons/ledger-detail-balance-icon.svg';
import breakdownIcon from '../../../common-icons/ledger-detail-breakdown-icon.svg';
import payrollIcon from '../../../common-icons/ledger-detail-payroll-icon.svg';
import timelineIcon from '../../../common-icons/ledger-detail-loan-timeline-icon.svg';
import noPaymentsImage from '../../../common-icons/no-payroll-loans-icon.svg';
import completedPaymentsIcon from '../../../common-icons/completed-payments-check-icon.svg';
import pendingPaymentsIcon from '../../../common-icons/pending-payments-check-icon.svg';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getClient } from '../../../api-utils/general-utils';
import Loader from '../../../widgets/Loader';
import SchedulePaymentModal from './SchedulePaymentModal';
import ScheduleAdvancePaymentModal from './ScheduleAdvancePaymentModal';
import LoanDetailItem from './LoanTimelineItem/loan-timeline-item';
// eslint-disable-next-line max-len
import NextScheduledPayment from '../../Dashboard/DashboardComponents/NextScheduledPayment';
import NextScheduledCAPayment from '../../Dashboard/DashboardComponents/NextScheduledCAPayment';
import NextScheduledCashAdvancePayment from '../../Dashboard/DashboardComponents/NextScheduledCashAdvancePayment';
import { MessageContext } from '../../../context';

import {
  pendingTransactionsState,
  repaymentsState,
  accountRecordState,
} from '../../../recoil-state/general-states';
import PendingBalanceMessage from '../../Dashboard/DashboardComponents/PendingBalanceMessage';
// import Remitpayschedule from '../../Remit/Remitpayschedule';
import Remitpayment from '../../Remit/Remitpayment';
import {
  BankAccount,
  TransactionAvailability,
  UpdateBankAccountDto,
} from '../../../api-utils/generated-client';
import { fundingStepState } from '../../../recoil-state/request-funding-states';
import PayroSelect from '../../../widgets/PayroSelectv2';
import {
  scheduledPaymentsListRecoil,
  showHideClassNameState,
  bankAccountsState,
  dealDraftState,
  sectionState,
  transactionAvailabilityState,
  needsManualFormState,
  showHideClassNameModalManualFormState,
  bankAccountToUpdateState,
  chaseBankAccountsArrayState,
} from '../../../recoil-state/request-funding-states';
import { Value } from 'sass';

const LedgerDetail = () => {
  let history = useHistory();
  const id = useParams();
  const loanId = JSON.stringify(id);
  const [repayment, setRepayment] = useState<GetRepaymentDto>();
  const [bankAccounts, setBankAccounts] =
    useRecoilState(bankAccountsState);
  const [gotData, setGotData] = useState(false);
  const [scheduledPaymentsList, setScheduledPaymentsList] =
    useRecoilState<LoanPayment[]>(scheduledPaymentsListRecoil);
  const [failedPaymentsList, setFailedPaymentsList] = useRecoilState<
    LoanPayment[]
  >(scheduledPaymentsListRecoil);
  const [fees, setFees] = useState<any>('');
  const [discount, setDiscount] = useState<any>('');

  const [accountDetails, setAccountDetails] =
    useRecoilState<GetAccountDto>(accountRecordState);
  const [allPaymentsList, setAllPaymentsList] =
    useState<LoanPayment[]>();
  const [payrollObject, setPayrollObject] = useState<any>();
  const [completedPaymentsList, setCompletedPaymentsList] =
    useState<LoanPayment[]>();
  const [showHideClassName, setShowHideClassName] = useRecoilState(
    showHideClassNameState,
  );
  const [clientState, setClient] = useState<any>();
  const [revenuePercentage, setRevenuePercentage] =
    useState<number>(0);
  const [revenueFigure, setRevenueFigure] = useState<number>(0);
  const [repaymentID, setRepaymentID] = useState<any>();
  const messageContext = useContext(MessageContext);

  const repayments = useRecoilValue(repaymentsState);
  const pendingTransactions = useRecoilValue(
    pendingTransactionsState,
  );

  const [totalPaid, setTotalPaid] = useState<number>(0);
  const [bankdetail, setBankDetail] = useState<string>('');

  const getAccountLastFour = (b: any) => {
    if (
      b.account_last_four != '' ||
      b.account_last_four != undefined ||
      b.account_last_four != null
    ) {
      return b.account_last_four;
    } else {
      getLastFourCharacters(b.bank_account_number);
    }
  };
  const getLastFourCharacters = (s: string | undefined) => {
    if (!s) {
      return '';
    }

    if (s.length <= 4) {
      return s;
    }

    return s.substr(s.length - 4, 4);
  };

  useEffect(() => {
    // console.log('repayment',repayment)
    if (bankAccounts && repayment && repayment.bank_account_number) {
      let bankacc = String(repayment.bank_account_number);
      if (bankacc) {
        setBankDetail(getLastFourCharacters(bankacc));
      }
    }
    if (
      repayment &&
      accountDetails?.monthlyrevenue &&
      accountDetails?.monthlyrevenue > 0
    ) {
      // let weeks:any = repayment?.NumberofWeeks;
      let weeks: any = 4;
      let figure: any =
        accountDetails?.monthlyrevenue / parseInt(weeks);
      let paybackamount = repayment?.WeeklyPaymentAmount;
      let percentage: any =
        paybackamount && (paybackamount * 100) / figure;
      if (percentage) {
        setRevenuePercentage(percentage?.toFixed());
        setRevenueFigure(figure?.toFixed());
      }
    }
  }, [bankAccounts, repayment, accountDetails]);
  const section = useRecoilValue(sectionState);
  const [fundingStep, setFundingStep] =
    useRecoilState(fundingStepState);
  const [showHideClassName1, setShowHideClassName1] = useState(
    'modal display-none',
  );

  useEffect(() => {
    const getData = async () => {
      const client = await getClient();
      if (!client) {
        return;
      }

      const loanIdString = getLoanIdLedgerDetail(loanId);
      setRepaymentID(loanIdString);
      const repaymentsInfo = repayments;

      if (!repaymentsInfo) return;

      const repayment = await repaymentsInfo.filter(
        (r) => r.uuid == loanIdString,
      );
      setRepayment(repayment[0]);
      setPayrollObject(repayment[0].payroll_record__r);

      const repayment_id = repayment[0].uuid;
      setFees(0);
      setDiscount(0);
      const paymentsForRepayment =
        await client.paymentsControllerGetPaymentsForRepayment(
          repayment_id,
        );
      let feevalue = 0;
      let discountvalue = 0;
      paymentsForRepayment?.data.map((p) => {
        if (
          p.status == 'Fees Applied' &&
          p.transaction_type == 'Fee'
        ) {
          feevalue = feevalue + p.transaction_amount;
        } else if (
          p.status == 'Settled' &&
          p.transaction_type == 'Adjustment'
        ) {
          discountvalue = discountvalue + p.transaction_amount;
        }
      });
      setFees(feevalue);
      setDiscount(discountvalue);

      setFailedPaymentsList(
        paymentsForRepayment.data
          .filter(
            (p) =>
              p.transaction_type == 'Payment' &&
              p.status === 'failed',
          )
          .sort((a, b) => {
            if (a.effective_date > b.effective_date) {
              return 1;
            } else {
              return -1;
            }
          }),
      );
      setScheduledPaymentsList(
        paymentsForRepayment.data &&
          paymentsForRepayment.data
            .filter(
              (p) =>
                p.transaction_type == 'Payment' &&
                p.status === 'Scheduled',
            )
            .sort((a, b) => {
              if (a.effective_date > b.effective_date) {
                return 1;
              } else {
                return -1;
              }
            }),
      );
      setCompletedPaymentsList(
        paymentsForRepayment.data
          .filter(
            (p) =>
              (p.transaction_type == 'Payment' &&
                p.status == 'Settled') ||
              p.status === 'Pending',
          )
          .sort((a, b) => {
            if (a.effective_date < b.effective_date) {
              return 1;
            } else {
              return -1;
            }
          }),
      );
      setAllPaymentsList(
        paymentsForRepayment.data
          .filter(
            (p) =>
              p.transaction_type == 'Payment' &&
              (p.status == 'Scheduled' ||
                p.status == 'Pending' ||
                p.status == 'Settled' ||
                'Failed'),
          )

          .sort((a, b) => {
            if (a.effective_date > b.effective_date) {
              return 1;
            } else {
              return -1;
            }
          }),
      );
      setClient(client);
    };

    getData().then((res) => {
      setGotData(true);
    });
  }, []);
  useEffect(() => {
    let value = 0;
    completedPaymentsList &&
      completedPaymentsList.map((p) => {
        if (
          (p.transaction_type == 'Payment' && p.status == 'Pending',
          p.status == 'Settled')
        ) {
          value = value + p.transaction_amount;
        }
      });
    setTotalPaid(value);
  }, [completedPaymentsList]);
  if (!gotData) {
    return (
      <Loader message="Getting you the latest details..."></Loader>
    );
  }
  const onDownloadContract = async () => {
    const urlresult =
      await clientState.documentsControllerGetAgreementDownloadUrl({
        repaymentId: repaymentID && repaymentID,
      });
    if (urlresult.data == '') {
      messageContext.addMessage({
        level: 'info',
        message:
          // eslint-disable-next-line max-len
          'We are unable to download your contract at this time. Please contact support with any questions 1-833-271-4499.',
      });
      // navigate to the URL if it exists
    } else {
      window.location.href = urlresult.data;
    }
  };
  const onDownloadClick = async () => {
    const urlresult =
      await clientState.documentsControllerGetPdfStatement(
        repaymentID && repaymentID,
      );
    if (urlresult.data == '') {
      messageContext.addMessage({
        level: 'info',
        message:
          // eslint-disable-next-line max-len
          'We are unable to download your statement at this time. Please contact support with any questions 1-833-271-4499.',
      });
      // navigate to the URL if it exists
    } else {
      window.location.href = urlresult.data;
    }
  };

  const EnableRefunding = () => {
    setFundingStep('funding-amount');
    setShowHideClassName1('modal display-block');
  };
  const ledgerdetail_advance_cost =
    Math.round(repayment?.TotalAdvanceCost ?? 0) +
    (repayment?.funding_amount ?? 0) +
    150;

  return (
    <>
      {repayment &&
      accountDetails &&
      accountDetails.legal_address_state !== 'NY' ? (
        <>
          <SchedulePaymentModal
            key={repayment?.uuid}
            repaymentUuid={repayment ? repayment.uuid : ''}
            repaymentPaybackDate={
              repayment ? repayment.next_hit_date : ''
            }
            loanId={getLoanIdToDisplayLedgerDetail(loanId)}
            repaymentBalance={repayment?.total_outstanding_amount}
            nextScheduledPaymentBankName={
              repayment?.withdrawl_bank_name
            }
            // eslint-disable-next-line max-len
            nextScheduledPaymentBankNumber={
              repayment?.bank_account_number
                ? repayment.bank_account_number.toString()
                : ''
            }
            repaymentSfId={repayment?.salesforce_id}
            className={showHideClassName}
          ></SchedulePaymentModal>
          <div className="main-wrapper">
            <div className="advance-header-wrapper">
              <PayroButton
                customWidth="width-130"
                variant="purple-white"
                startIcon={backIcon}
                onClick={() => {
                  history.goBack();
                }}
              >
                {''}
              </PayroButton>
              <p className="advance-design-color">Advance</p>
              <p className="loan-id-design">
                /{' '}
                <span className="loan-id-padding">
                  {getLoanIdToDisplayLedgerDetail(loanId)}
                </span>
              </p>
            </div>
          </div>
          <div id={'ledger-detail-highlight-container'}>
            <div className="main-wrapper">
              <div
                className={
                  repayment?.status == 'Active'
                    ? 'bold-numbers-wrapper-active'
                    : 'bold-numbers-wrapper'
                }
              >
                <div className="outstanding-balance-ledger-detail">
                  <p id="outstanding-balance-title">
                    Total Loan Amount
                  </p>
                  <p
                    id={
                      repayment?.status == 'Complete'
                        ? 'outstanding-balance-number-complete'
                        : 'outstanding-balance-number'
                    }
                  >
                    {formatNumberAsDollars(repayment?.funding_amount)}
                  </p>
                </div>
                <div
                  className={
                    repayment?.status == 'Complete'
                      ? 'remaining-wrapper-ledger-detail-complete'
                      : 'remaining-wrapper-ledger-detail'
                  }
                >
                  <p id="outstanding-balance-title">
                    Outstanding Balance
                  </p>
                  <div className="pending-balance-main-wrapper">
                    {' '}
                    <p
                      id={
                        repayment?.status == 'Complete'
                          ? 'outstanding-balance-number-complete'
                          : 'outstanding-balance-ledger-detail'
                      }
                    >
                      {formatNumberAsDollars(
                        repayment?.total_outstanding_amount,
                      )}
                    </p>
                    {pendingTransactions && <PendingBalanceMessage />}
                  </div>
                </div>
                <div
                  className={
                    repayment?.status == 'Complete'
                      ? 'date-wrapper-ledger-detail-complete'
                      : 'date-wrapper-ledger-detail'
                  }
                >
                  <p id="outstanding-balance-title">
                    Final Payoff Date
                  </p>
                  <p
                    id={
                      repayment?.status == 'Complete'
                        ? 'payoff-date-complete'
                        : 'outstanding-balance-number'
                    }
                  >
                    {formatDate(repayment?.next_hit_date)}
                  </p>
                </div>
              </div>
              {repayment?.status == 'Active' && (
                <>
                  <div className="payment-and-remit-payment-wrapper">
                    <NextScheduledPayment
                      repayment_id={repayment?.uuid}
                    />
                    <div className="remit-payment-wrapper purple-background-color">
                      <div
                        id="myBtn"
                        className="button-wrapper-remit-payment"
                      >
                        <PayroButton
                          customWidth="width-182"
                          customHeight="48"
                          startIcon={remitPaymentIcon}
                          onClick={() => {
                            setShowHideClassName(
                              'modal display-block',
                            );
                          }}
                        >
                          Remit Payment
                        </PayroButton>
                      </div>
                      <p className="remit-payment-subtitle">
                        Payro gives you the ability to pay off your
                        <br></br> advancement to save on fees.
                      </p>
                    </div>
                  </div>{' '}
                </>
              )}
              {repayment?.status == 'Complete' && (
                <div id="paid-off-container">
                  <img
                    className="check-image"
                    src={checkIcon}
                    alt="check"
                    height={25.01}
                    width={25.01}
                  ></img>
                  <p className="paid-off-text">Paid Off</p>
                </div>
              )}
            </div>
          </div>
          <div id="additional-details-text-container">
            <p className="additional-details-text">
              Additional Details
            </p>
          </div>

          <div
            id={
              repayment?.status == 'Active'
                ? 'loan-details-main-container-active'
                : 'loan-details-main-container'
            }
          >
            <div
              id="additional-detail-highlight-container-one"
              className="loan-details-section"
            >
              <div className="ledger-details-subtitle">
                <img
                  className="ledger-details-subtitle-image"
                  src={balanceIcon}
                  height={22.1}
                  width={22.1}
                  id="images-background-color"
                ></img>
                <p className="ledger-details-subtitle-text">
                  Balance
                </p>
              </div>
              <div className="balance-wrapper">
                <p className="balance-container-balance-number">
                  {formatNumberAsDollars(
                    repayment?.total_outstanding_amount,
                  )}
                </p>

                <div
                  className={
                    'status-green-ledger-detail repayment-info-item'
                  }
                >
                  {repayment?.payoff == null ? 0 : repayment?.payoff}%
                </div>
              </div>
              <div>
                <div id="progress-bar-container-ledger-detail">
                  <div
                    id="green-completed"
                    className={
                      repayment != undefined
                        ? parseInt(repayment.payoff) <= 5
                          ? 'width-5-percent'
                          : parseInt(repayment.payoff) <= 10
                          ? 'width-10-percent'
                          : parseInt(repayment.payoff) <= 15
                          ? 'width-15-percent'
                          : parseInt(repayment.payoff) <= 20
                          ? 'width-20-percent'
                          : parseInt(repayment.payoff) <= 25
                          ? 'width-25-percent'
                          : parseInt(repayment.payoff) <= 30
                          ? 'width-30-percent'
                          : parseInt(repayment.payoff) <= 35
                          ? 'width-35-percent'
                          : parseInt(repayment.payoff) <= 40
                          ? 'width-40-percent'
                          : parseInt(repayment.payoff) <= 45
                          ? 'width-45-percent'
                          : parseInt(repayment.payoff) <= 50
                          ? 'width-50-percent'
                          : parseInt(repayment.payoff) <= 55
                          ? 'width-55-percent'
                          : parseInt(repayment.payoff) <= 60
                          ? 'width-60-percent'
                          : parseInt(repayment.payoff) <= 65
                          ? 'width-65-percent'
                          : parseInt(repayment.payoff) <= 70
                          ? 'width-70-percent'
                          : parseInt(repayment.payoff) <= 75
                          ? 'width-75-percent'
                          : parseInt(repayment.payoff) <= 80
                          ? 'width-80-percent'
                          : parseInt(repayment.payoff) <= 85
                          ? 'width-85-percent'
                          : parseInt(repayment.payoff) <= 90
                          ? 'width-90-percent'
                          : parseInt(repayment.payoff) <= 95
                          ? 'width-95-percent'
                          : parseInt(repayment.payoff) <= 100
                          ? 'width-100-percent'
                          : 'width-1-percent'
                        : 'width-1-percent'
                    }
                  ></div>
                </div>
              </div>
              <div
                className={
                  scheduledPaymentsList?.length > 0
                    ? 'scheduled-payments-wrapper'
                    : 'scheduled-payments-wrapper-none'
                }
              >
                <div>
                  {scheduledPaymentsList?.length > 0 ? (
                    <p className="scheduled-payments-header-text">
                      Scheduled Payments:{' '}
                    </p>
                  ) : (
                    ''
                  )}
                  {scheduledPaymentsList?.length
                    ? scheduledPaymentsList.map((p) => (
                        <>
                          <div
                            key={p.uuid}
                            className="scheduled-payment-wrapper"
                          >
                            <div>
                              <p className="scheduled-payments-subheader-text">
                                Date:
                              </p>
                              <p className="scheduled-payments-data">
                                {formatDate(p.effective_date)}
                              </p>{' '}
                            </div>
                            <div className="scheduled-payments-info-message-two">
                              <p className="scheduled-payments-subheader-text">
                                Bank:
                              </p>
                              <p className="scheduled-payments-data">
                                {/* {p.bank_name_from_repayment}{' '} */}
                                Account ***
                                {p.bank_account_from_repayment
                                  ? p.bank_account_from_repayment.slice(
                                      p.bank_account_from_repayment
                                        .length - 4,
                                    )
                                  : ''}
                              </p>
                            </div>
                            <div className="scheduled-payments-info-message-three">
                              <p className="scheduled-payments-subheader-text">
                                Amount:
                              </p>
                              <p className="scheduled-payments-data">
                                {formatNumberAsDollars(
                                  p.transaction_amount,
                                )}
                              </p>
                            </div>{' '}
                          </div>
                        </>
                      ))
                    : ''}
                </div>
              </div>

              <div id="full-remaining-balance-wrapper">
                {repayment?.status != 'Complete' &&
                  repayment?.next_hit_date && (
                    <p className="full-remaining-text">
                      The full remaining balance {''}
                      {repayment?.full_remaining_balance_incl_interest &&
                      parseInt(
                        repayment?.full_remaining_balance_incl_interest.toString(),
                      ) > 1 ? (
                        <>
                          of {''}
                          <span className="bold">
                            {formatNumberAsDollars(
                              parseInt(
                                repayment?.full_remaining_balance_incl_interest.toString(),
                              ),
                            )}
                            {''}
                          </span>
                        </>
                      ) : (
                        ''
                      )}
                      {''} will be debited out of your account on{' '}
                      <span className="bold">
                        {formatDate(repayment?.next_hit_date)}.
                      </span>{' '}
                    </p>
                  )}
                {repayment?.status == 'Active' && (
                  <div id="remit-payment-white-button">
                    <PayroButton
                      customWidth="width-250"
                      variant="white"
                      startIcon={remitPaymentPurpleIcon}
                      onClick={() => {
                        setShowHideClassName('modal display-block');
                      }}
                    >
                      Remit Payment
                    </PayroButton>
                  </div>
                )}
              </div>
              {
                <div
                  id={
                    repayment?.status == 'Active'
                      ? 'completed-payments-wrapper-active'
                      : completedPaymentsList?.length
                      ? 'completed-payments-wrapper'
                      : 'completed-payments-wrapper-none'
                  }
                >
                  <p className="scheduled-payments-header-text">
                    Completed Payments:{' '}
                  </p>
                  <div>
                    {completedPaymentsList?.length ? (
                      completedPaymentsList.map((p) => (
                        <>
                          <div key={p.uuid}>
                            <p className="scheduled-payments-subheader-text">
                              Date:
                            </p>
                            <p className="scheduled-payments-data">
                              {formatDate(p.effective_date)}
                            </p>
                          </div>
                          <div className="scheduled-payments-info-message-two">
                            <p className="scheduled-payments-subheader-text">
                              Bank:
                            </p>
                            <p className="scheduled-payments-data">
                              {/* {p.bank_name_from_repayment} {''} */}{' '}
                              Account ***
                              {p.bank_account_from_repayment
                                ? p.bank_account_from_repayment.slice(
                                    p.bank_account_from_repayment
                                      .length - 4,
                                  )
                                : ''}
                            </p>
                          </div>
                          <div className="completed-payments-info-message-three">
                            <p className="scheduled-payments-subheader-text">
                              Amount:
                            </p>
                            <p className="scheduled-payments-data">
                              {formatNumberAsDollars(
                                p.transaction_amount,
                              )}
                            </p>
                            <div className="completed-payments-icon-column">
                              <img
                                src={
                                  p.status == 'Settled'
                                    ? completedPaymentsIcon
                                    : pendingPaymentsIcon
                                }
                                alt="completed payments icon"
                                height={20.1}
                                width={20.1}
                              ></img>{' '}
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <>
                        <img
                          id="no-repayments-image"
                          src={noPaymentsImage}
                        />
                        <br />
                        <p className="no-payments-text">
                          No Payments Yet
                        </p>
                      </>
                    )}
                  </div>
                </div>
              }
            </div>
            <div
              id="additional-detail-highlight-container-two"
              className="loan-details-section"
            >
              <div className="ledger-details-subtitle">
                <img
                  className="ledger-details-subtitle-image"
                  src={breakdownIcon}
                  alt="breakdown icon"
                  height={22.1}
                  width={22.1}
                  id="images-background-color"
                ></img>
                <p className="ledger-details-subtitle-text">
                  Breakdown
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">
                  Funding Amount:
                </p>
                <p className="breakdown-values-text">
                  {formatNumberAsDollars(repayment?.funding_amount)}
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">Factor Rate:</p>
                <p className="breakdown-values-text">1.5%</p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">Weeks:</p>

                <p className="breakdown-values-text">
                  {repayment?.number_of_pay_periods}
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">Total Cost:</p>
                <p className="breakdown-values-text">
                  {formatNumberAsDollars(
                    repayment?.expected_total_payback,
                  )}
                </p>
              </div>

              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">
                  Total Paid Off:
                </p>
                <p className="breakdown-values-text">
                  {formatNumberAsDollars(
                    repayment?.total_amount_settled,
                  )}
                </p>
              </div>
              <div className="ledger-details-subtitle">
                <img
                  className="ledger-details-subtitle-text-image"
                  src={payrollIcon}
                  alt="payroll icon"
                  height={22.1}
                  width={22.1}
                  id="images-background-color"
                ></img>
                <p className="ledger-details-subtitle-text">
                  Payroll Information
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">
                  {payrollObject != undefined ? 'Payroll Date:' : ''}
                </p>

                <p className="breakdown-values-text">
                  {payrollObject
                    ? formatDate(payrollObject['PayDate__c'])
                    : ''}
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">
                  {payrollObject != undefined
                    ? 'Total Cash Due:'
                    : ''}
                </p>

                <p className="breakdown-values-text">
                  {payrollObject
                    ? formatNumberAsDollars(
                        payrollObject['Total_Payroll_Liability__c'],
                      )
                    : ''}
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">
                  Payroll Provider:
                </p>
                <p className="breakdown-values-text">
                  {repayment?.merchant_payroll_company}
                </p>
              </div>
            </div>

            <div
              id="additional-detail-highlight-container-three"
              className="loan-details-section"
            >
              <div className="ledger-details-subtitle">
                <img
                  className="ledger-details-subtitle-image"
                  src={timelineIcon}
                  alt="timeline icon"
                  height={22.1}
                  width={22.1}
                  id="images-background-color"
                ></img>
                <p className="ledger-details-subtitle-text">
                  {' '}
                  Loan Timeline
                </p>
              </div>
              <div className="timeline-details-container">
                <div>
                  <LoanDetailItem
                    detailText="Initial Advancement Funded"
                    detailAmount={repayment?.funding_amount}
                    date={repayment?.funded_date}
                    image={TimelineIconOne}
                  ></LoanDetailItem>

                  {allPaymentsList?.length
                    ? allPaymentsList.map((p) => (
                        <LoanDetailItem
                          key={p.uuid}
                          detailText={
                            p.status == 'Settled'
                              ? 'Payment Debited'
                              : p.status == 'Pending'
                              ? 'Payment Pending'
                              : 'Payment Scheduled'
                          }
                          detailAmount={p.transaction_amount}
                          date={p.effective_date}
                          image={
                            p.status == 'Settled'
                              ? `${TimelineIconTwo}`
                              : `${TimelineIconThree}`
                          }
                        ></LoanDetailItem>
                      ))
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </>
      // ) : repayment && repayment.recordtypeid != '012E10000003sYXIAY' ? ( // dev
      ) : repayment && repayment.recordtypeid != '012AJ0000004LS1YAM' ? ( // live
        
        <>
          <SchedulePaymentModal
            key={repayment?.uuid}
            repaymentUuid={repayment ? repayment.uuid : ''}
            repaymentPaybackDate={
              repayment ? repayment.next_hit_date : ''
            }
            loanId={getLoanIdToDisplayLedgerDetail(loanId)}
            repaymentBalance={repayment?.total_outstanding_amount}
            nextScheduledPaymentBankName={
              repayment?.withdrawl_bank_name
            }
            // eslint-disable-next-line max-len
            nextScheduledPaymentBankNumber={
              repayment?.bank_account_number
                ? repayment.bank_account_number.toString()
                : ''
            }
            repaymentSfId={repayment?.salesforce_id}
            className={showHideClassName}
          ></SchedulePaymentModal>
          <div className="main-wrapper">
            <div className="advance-header-wrapper">
              <PayroButton
                customWidth="width-130"
                variant="purple-white"
                startIcon={backIcon}
                onClick={() => {
                  history.goBack();
                }}
              >
                {''}
              </PayroButton>
              <p className="advance-design-color">Advance</p>
              <p className="loan-id-design">
                /{' '}
                <span className="loan-id-padding">
                  {getLoanIdToDisplayLedgerDetail(loanId)}
                </span>
              </p>
            </div>
          </div>
          <div id={'ledger-detail-highlight-container'}>
            <div className="main-wrapper">
              <div
                className={
                  repayment?.status == 'Active'
                    ? 'bold-numbers-wrapper-active'
                    : 'bold-numbers-wrapper'
                }
              >
                <div className="outstanding-balance-ledger-detail">
                  <p id="outstanding-balance-title">
                    Total Loan Amount
                  </p>
                  <p
                    id={
                      repayment?.status == 'Complete'
                        ? 'outstanding-balance-number-complete'
                        : 'outstanding-balance-number'
                    }
                  >
                    {formatNumberAsDollars(repayment?.funding_amount)}
                  </p>
                </div>
                <div
                  className={
                    repayment?.status == 'Complete'
                      ? 'remaining-wrapper-ledger-detail-complete'
                      : 'remaining-wrapper-ledger-detail'
                  }
                >
                  <p id="outstanding-balance-title">
                    Outstanding Balance
                  </p>
                  <div className="pending-balance-main-wrapper">
                    {' '}
                    <p
                      id={
                        repayment?.status == 'Complete'
                          ? 'outstanding-balance-number-complete'
                          : 'outstanding-balance-ledger-detail'
                      }
                    >
                      {formatNumberAsDollars(
                        repayment?.total_outstanding_amount,
                      )}
                    </p>
                    {pendingTransactions && <PendingBalanceMessage />}
                  </div>
                </div>
                <div
                  className={
                    repayment?.status == 'Complete'
                      ? 'date-wrapper-ledger-detail-complete'
                      : 'date-wrapper-ledger-detail'
                  }
                >
                  <p id="outstanding-balance-title">
                    Final Payoff Date
                  </p>
                  <p
                    id={
                      repayment?.status == 'Complete'
                        ? 'payoff-date-complete'
                        : 'outstanding-balance-number'
                    }
                  >
                    {formatDate(repayment?.next_hit_date)}
                  </p>
                </div>
              </div>
              {repayment?.status == 'Active' && (
                <>
                  <div className="payment-and-remit-payment-wrapper">
                    <NextScheduledPayment
                      repayment_id={repayment?.uuid}
                    />
                    <div className="remit-payment-wrapper purple-background-color">
                      <div
                        id="myBtn"
                        className="button-wrapper-remit-payment"
                      >
                        <PayroButton
                          customWidth="width-182"
                          customHeight="48"
                          startIcon={remitPaymentIcon}
                          onClick={() => {
                            setShowHideClassName(
                              'modal display-block',
                            );
                          }}
                        >
                          Remit Payment
                        </PayroButton>
                      </div>
                      <p className="remit-payment-subtitle">
                        Payro gives you the ability to pay off your
                        <br></br> advancement to save on fees.
                      </p>
                    </div>
                  </div>{' '}
                </>
              )}
              {repayment?.status == 'Complete' && (
                <div id="paid-off-container">
                  <img
                    className="check-image"
                    src={checkIcon}
                    alt="check"
                    height={25.01}
                    width={25.01}
                  ></img>
                  <p className="paid-off-text">Paid Off</p>
                </div>
              )}
            </div>
          </div>
          <div id="additional-details-text-container">
            <p className="additional-details-text">
              Additional Details
            </p>
          </div>
          <div
            id={
              repayment?.status == 'Active'
                ? 'loan-details-main-container-active'
                : 'loan-details-main-container'
            }
          >
            <div
              id="additional-detail-highlight-container-one"
              className="loan-details-section"
            >
              <div className="ledger-details-subtitle">
                <img
                  className="ledger-details-subtitle-image"
                  src={balanceIcon}
                  height={22.1}
                  width={22.1}
                  id="images-background-color"
                ></img>
                <p className="ledger-details-subtitle-text">
                  Balance
                </p>
              </div>
              <div className="balance-wrapper">
                <p className="balance-container-balance-number">
                  {formatNumberAsDollars(
                    repayment?.total_outstanding_amount,
                  )}
                </p>

                <div
                  className={
                    'status-green-ledger-detail repayment-info-item'
                  }
                >
                  {repayment?.payoff == null ? 0 : repayment?.payoff}%
                </div>
              </div>
              <div>
                <div id="progress-bar-container-ledger-detail">
                  <div
                    id="green-completed"
                    className={
                      repayment != undefined
                        ? parseInt(repayment.payoff) <= 5
                          ? 'width-5-percent'
                          : parseInt(repayment.payoff) <= 10
                          ? 'width-10-percent'
                          : parseInt(repayment.payoff) <= 15
                          ? 'width-15-percent'
                          : parseInt(repayment.payoff) <= 20
                          ? 'width-20-percent'
                          : parseInt(repayment.payoff) <= 25
                          ? 'width-25-percent'
                          : parseInt(repayment.payoff) <= 30
                          ? 'width-30-percent'
                          : parseInt(repayment.payoff) <= 35
                          ? 'width-35-percent'
                          : parseInt(repayment.payoff) <= 40
                          ? 'width-40-percent'
                          : parseInt(repayment.payoff) <= 45
                          ? 'width-45-percent'
                          : parseInt(repayment.payoff) <= 50
                          ? 'width-50-percent'
                          : parseInt(repayment.payoff) <= 55
                          ? 'width-55-percent'
                          : parseInt(repayment.payoff) <= 60
                          ? 'width-60-percent'
                          : parseInt(repayment.payoff) <= 65
                          ? 'width-65-percent'
                          : parseInt(repayment.payoff) <= 70
                          ? 'width-70-percent'
                          : parseInt(repayment.payoff) <= 75
                          ? 'width-75-percent'
                          : parseInt(repayment.payoff) <= 80
                          ? 'width-80-percent'
                          : parseInt(repayment.payoff) <= 85
                          ? 'width-85-percent'
                          : parseInt(repayment.payoff) <= 90
                          ? 'width-90-percent'
                          : parseInt(repayment.payoff) <= 95
                          ? 'width-95-percent'
                          : parseInt(repayment.payoff) <= 100
                          ? 'width-100-percent'
                          : 'width-1-percent'
                        : 'width-1-percent'
                    }
                  ></div>
                </div>
              </div>
              <div
                className={
                  scheduledPaymentsList &&
                  scheduledPaymentsList.length > 0
                    ? 'scheduled-payments-wrapper'
                    : 'scheduled-payments-wrapper-none'
                }
              >
                <div>
                  {scheduledPaymentsList &&
                  scheduledPaymentsList.length > 0 ? (
                    <p className="scheduled-payments-header-text">
                      Scheduled Payments:{' '}
                    </p>
                  ) : (
                    ''
                  )}
                  {scheduledPaymentsList?.length
                    ? scheduledPaymentsList.map((p) => (
                        <>
                          <div
                            key={p.uuid}
                            className="scheduled-payment-wrapper"
                          >
                            <div>
                              <p className="scheduled-payments-subheader-text">
                                Date:
                              </p>
                              <p className="scheduled-payments-data">
                                {formatDate(p.effective_date)}
                              </p>{' '}
                            </div>
                            <div className="scheduled-payments-info-message-two">
                              <p className="scheduled-payments-subheader-text">
                                Bank:
                              </p>
                              <p className="scheduled-payments-data">
                                {/* {p.bank_name_from_repayment}{' '} */}
                                Account ***
                                {p.bank_account_from_repayment
                                  ? p.bank_account_from_repayment.slice(
                                      p.bank_account_from_repayment
                                        .length - 4,
                                    )
                                  : ''}
                              </p>
                            </div>
                            <div className="scheduled-payments-info-message-three">
                              <p className="scheduled-payments-subheader-text">
                                Amount:
                              </p>
                              <p className="scheduled-payments-data">
                                {formatNumberAsDollars(
                                  p.transaction_amount,
                                )}
                              </p>
                            </div>{' '}
                          </div>
                        </>
                      ))
                    : ''}
                </div>
              </div>

              <div id="full-remaining-balance-wrapper">
                {repayment?.status != 'Complete' && (
                  <p className="full-remaining-text">
                    The full remaining balance {''}
                    {repayment?.full_remaining_balance_incl_interest &&
                    parseInt(
                      repayment?.full_remaining_balance_incl_interest.toString(),
                    ) > 1 ? (
                      <>
                        of {''}
                        <span className="bold">
                          {formatNumberAsDollars(
                            parseInt(
                              repayment?.full_remaining_balance_incl_interest.toString(),
                            ),
                          )}
                          {''}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                    {''} will be debited out of your account on{' '}
                    <span className="bold">
                      {formatDate(repayment?.next_hit_date)}.
                    </span>{' '}
                  </p>
                )}
                {repayment?.status == 'Active' && (
                  <div id="remit-payment-white-button">
                    <PayroButton
                      customWidth="width-250"
                      variant="white"
                      startIcon={remitPaymentPurpleIcon}
                      onClick={() => {
                        setShowHideClassName('modal display-block');
                      }}
                    >
                      Remit Payment
                    </PayroButton>
                  </div>
                )}
              </div>
              {
                <div
                  id={
                    repayment?.status == 'Active'
                      ? 'completed-payments-wrapper-active'
                      : completedPaymentsList?.length
                      ? 'completed-payments-wrapper'
                      : 'completed-payments-wrapper-none'
                  }
                >
                  <p className="scheduled-payments-header-text">
                    Completed Payments:{' '}
                  </p>
                  <div>
                    {completedPaymentsList?.length ? (
                      completedPaymentsList.map((p) => (
                        <>
                          <div key={p.uuid}>
                            <p className="scheduled-payments-subheader-text">
                              Date:
                            </p>
                            <p className="scheduled-payments-data">
                              {formatDate(p.effective_date)}
                            </p>
                          </div>
                          <div className="scheduled-payments-info-message-two">
                            <p className="scheduled-payments-subheader-text">
                              Bank:
                            </p>
                            <p className="scheduled-payments-data">
                              {/* {p.bank_name_from_repayment} {''} */}{' '}
                              Account ***
                              {p.bank_account_from_repayment
                                ? p.bank_account_from_repayment.slice(
                                    p.bank_account_from_repayment
                                      .length - 4,
                                  )
                                : ''}
                            </p>
                          </div>
                          <div className="completed-payments-info-message-three">
                            <p className="scheduled-payments-subheader-text">
                              Amount:
                            </p>
                            <p className="scheduled-payments-data">
                              {formatNumberAsDollars(
                                p.transaction_amount,
                              )}
                            </p>
                          </div>
                        </>
                      ))
                    ) : (
                      <>
                        <img
                          id="no-repayments-image"
                          src={noPaymentsImage}
                        />
                        <br />
                        <p className="no-payments-text">
                          No Payments Yet
                        </p>
                      </>
                    )}
                  </div>
                </div>
              }
            </div>
            <div
              id="additional-detail-highlight-container-two"
              className="loan-details-section"
            >
              <div className="ledger-details-subtitle">
                <img
                  className="ledger-details-subtitle-image"
                  src={breakdownIcon}
                  alt="breakdown icon"
                  height={22.1}
                  width={22.1}
                  id="images-background-color"
                ></img>
                <p className="ledger-details-subtitle-text">
                  Breakdown
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">
                  Funding Amount:
                </p>
                <p className="breakdown-values-text">
                  {formatNumberAsDollars(repayment?.funding_amount)}
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">Factor Rate:</p>
                <p className="breakdown-values-text">1.5%</p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">Weeks:</p>

                <p className="breakdown-values-text">
                  {repayment?.number_of_pay_periods}
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">Total Cost:</p>
                <p className="breakdown-values-text">
                  {formatNumberAsDollars(
                    repayment?.expected_total_payback,
                  )}
                </p>
              </div>

              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">
                  Total Paid Off:
                </p>
                <p className="breakdown-values-text">
                  {formatNumberAsDollars(
                    repayment?.total_amount_settled,
                  )}
                </p>
              </div>
              <div className="ledger-details-subtitle">
                <img
                  className="ledger-details-subtitle-text-image"
                  src={payrollIcon}
                  alt="payroll icon"
                  height={22.1}
                  width={22.1}
                  id="images-background-color"
                ></img>
                <p className="ledger-details-subtitle-text">
                  Payroll Information
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">
                  {payrollObject != undefined ? 'Payroll Date:' : ''}
                </p>

                <p className="breakdown-values-text">
                  {payrollObject
                    ? formatDate(payrollObject['PayDate__c'])
                    : ''}
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">
                  {payrollObject != undefined
                    ? 'Total Cash Due:'
                    : ''}
                </p>

                <p className="breakdown-values-text">
                  {payrollObject
                    ? formatNumberAsDollars(
                        payrollObject['Total_Payroll_Liability__c'],
                      )
                    : ''}
                </p>
              </div>
              <div className="breakdown-values-wrapper">
                <p className="breakdown-header-text">
                  Payroll Provider:
                </p>
                <p className="breakdown-values-text">
                  {repayment?.merchant_payroll_company}
                </p>
              </div>
            </div>

            <div
              id="additional-detail-highlight-container-three"
              className="loan-details-section"
            >
              <div className="ledger-details-subtitle">
                <img
                  className="ledger-details-subtitle-image"
                  src={timelineIcon}
                  alt="timeline icon"
                  height={22.1}
                  width={22.1}
                  id="images-background-color"
                ></img>
                <p className="ledger-details-subtitle-text">
                  {' '}
                  Loan Timeline
                </p>
              </div>
              <div className="timeline-details-container">
                <div>
                  <LoanDetailItem
                    detailText="Initial Advancement Funded"
                    detailAmount={repayment?.funding_amount}
                    date={repayment?.funded_date}
                    image={TimelineIconOne}
                  ></LoanDetailItem>

                  {allPaymentsList?.length
                    ? allPaymentsList.map((p) => (
                        <LoanDetailItem
                          key={p.uuid}
                          detailText={
                            p.status == 'Settled'
                              ? 'Payment Debited'
                              : 'Payment Scheduled'
                          }
                          detailAmount={p.transaction_amount}
                          date={p.effective_date}
                          image={
                            p.status == 'Settled'
                              ? `${TimelineIconTwo}`
                              : `${TimelineIconThree}`
                          }
                        ></LoanDetailItem>
                      ))
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ScheduleAdvancePaymentModal
            key={repayment?.uuid}
            repaymentUuid={repayment ? repayment.uuid : ''}
            repaymentPaybackDate={
              repayment ? repayment.next_hit_date : ''
            }
            loanId={getLoanIdToDisplayLedgerDetail(loanId)}
            repaymentBalance={repayment?.total_outstanding_amount}
            nextScheduledPaymentBankName={
              repayment?.withdrawl_bank_name
            }
            nextScheduledPaymentBankNumber={
              repayment?.bank_account_number
                ? repayment.bank_account_number.toString()
                : ''
            }
            repaymentSfId={repayment?.salesforce_id}
            className={showHideClassName}
          ></ScheduleAdvancePaymentModal>
          <div className="main-wrapper-advance">
            <div className="ledger-header-wrapper">
              <div className="advances advance-header-wrapper-new">
                <PayroButton
                  customWidth="width-130"
                  variant="purple-white"
                  startIcon={backIcon}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {''}
                </PayroButton>
                <p className="advance-design-color">Advance</p>
                <p className="loan-id-design">
                  /{' '}
                  <span className="loan-id-padding">
                    {getLoanIdToDisplayLedgerDetail(loanId)}
                  </span>
                </p>
              </div>
              <div className="Download-Contract">
                <PayroButton
                  customWidth="width-182"
                  customHeight="48"
                  startIcon={downloadcontracticon}
                  onClick={() => onDownloadContract()}
                >
                  Download Contract
                </PayroButton>
                {/* <button className="downloadcontract"><img src='./Paperdownload.svg' alt=""/>Download Contract</button> */}
              </div>
            </div>
          </div>
          <div id="ledger-detail-highlight-container-active-new">
            <div className="main-wrapper">
              <div
                className={
                  repayment?.status == 'Active'
                    ? 'bold-numbers-wrapper-active'
                    : 'bold-numbers-wrapper'
                }
              >
                <div className="outstanding-balance-ledger-detail">
                  <p id="outstanding-balance-title">Total advance</p>
                  <p
                    id={
                      repayment?.status == 'Complete'
                        ? 'outstanding-balance-number-complete'
                        : 'outstanding-balance-number'
                    }
                  >
                    {formatNumberAsDollars(repayment?.funding_amount)}
                  </p>
                </div>
                <div
                  className={
                    repayment?.status == 'Complete'
                      ? 'remaining-wrapper-ledger-detail-complete'
                      : 'remaining-wrapper-ledger-detail'
                  }
                >
                  <p id="outstanding-balance-title">
                    Outstanding Balance
                  </p>{' '}
                  {repayment?.status ==
                  'Request In - Review Payroll' ? (
                    <p id={`outstanding-balance-ledger-detail`}>
                      {formatNumberAsDollars(
                        repayment?.TotalAmountPayback,
                      )}
                    </p>
                  ) : (
                    <p
                      id={
                        repayment?.status == 'Complete'
                          ? 'outstanding-balance-number-complete'
                          : 'outstanding-balance-ledger-detail'
                      }
                    >
                      {formatNumberAsDollars(
                        repayment?.total_outstanding_amount,
                      )}
                    </p>
                  )}
                  {pendingTransactions && <PendingBalanceMessage />}
                </div>
                <div
                  className={
                    repayment?.status == 'Complete'
                      ? 'date-wrapper-ledger-detail-complete'
                      : 'date-wrapper-ledger-detail'
                  }
                >
                  <p id="outstanding-balance-title">
                    Final Payoff Date
                  </p>
                  <p
                    id={
                      repayment?.status == 'Complete'
                        ? 'payoff-date-complete'
                        : 'outstanding-balance-number'
                    }
                  >
                    {formatDate(repayment?.FinalPaymentDate)}
                  </p>
                </div>
              </div>
              
              {repayment?.status == 'Active' && (
                <>
                  <div className="payment-and-remit-payment-wrapper">
                    <NextScheduledCashAdvancePayment
                      repayment_id={repayment?.uuid}
                      repayment={repayment}
                      isCashAdvance={true}
                    />
                    <div className="remit-payment-wrapper purple-background-color">
                      <div
                        id="myBtn"
                        className="button-wrapper-remit-payment"
                      >
                        <PayroButton
                          customWidth="width-182"
                          customHeight="48"
                          startIcon={remitPaymentIcon}
                          onClick={() => {
                            setShowHideClassName(
                              'modal display-block',
                            );
                          }}
                        >
                          Remit Payment
                        </PayroButton>
                      </div>
                      <p className="remit-payment-subtitle">
                        Pay early and save on fees.
                        <br></br> Contact us to discuss early payment
                        options.
                      </p>
                    </div>
                  </div>{' '}
                </>
              )}
              {repayment?.status == 'Complete' && (
                <div id="paid-off-container">
                  <img
                    className="check-image"
                    src={checkIcon}
                    alt="check"
                    height={25.01}
                    width={25.01}
                  ></img>
                  <p className="paid-off-text">Paid Off</p>
                </div>
              )}
            </div>
            {/* <div className='next-scheduled-payment-wrapper-new'>
                    <div className='payment-icon-and-text-wrapper-no-payment-one'>
                      <div className='payment-imgg'>
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAgCAYAAADqgqNBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOaSURBVHgB7VdNTttQEJ6xHdzSRekJ6pygcIKGExTUTYEiQOqm4qdEKqCIRcKiQqSVQguom0oBocCqAm6QnoD0BJgTNKtASPymM04M+XH6EkHFoh0pfs57Y38z7818Mwb4VwV1CuuZrGP22Rki+LEyN76p00/v5N4B4UipUh5NxWeKf9I1QCeW5QDBCFv5AroQZGAeYrZlDWp1wybXt/ZjpmGyBxC8wKmPLmjBaYAQB3gs8ugygusp73NifjKvBf+4c5gkohTcsSBiaml2bK0jePpLbhoMzMo9G7CmrKtds9IXq8/lPbM8owOxPPuIAAY5RkbRIJcUHxliUtY88oYbd8BqssTAKRJggPjK/IQfXJlM9rhi9GUU4c/E2xlXgw0bW7kT9shRVaOQiI+LfoHnxPOkiaYYkQ/3fPtAsIEj9YkuUnsRdmCgErF/yf3y3Pg1Zmi03yWwSLzD+/Sp9hflXsEtncLG1gGTBrUTBmKxG8ZrEJd6BUeEDF+dsLUPO7n86uxEAboQDuIh6BXcq+CwYanp1nlF4K4udAcsEhbEWvBEfMyVZ6ELCWgZFcUaKLbAlu4tL0zs9gzejaQ4j/sjD/h4aFoYigH9eTGAB2HIWHr78Hmpchlv3AFttKczuUFmqJRPvR2k37KzNWD2lGmZaTgqZMJHFvUUCSW7st4fsY8an9OCYwSzPjczv9civ8U4MQphhB12vaoxxLScEhpe/5p15MgSvN0SNzUDILaxfbDYNbgib028kd9FtZxvUzBqRUMpWqvHh/B7yvTss8BYmeei4hclPpBk8Kj2zFfmJ495OA5b8zm7VuuLiaaAQse/cm0PZqSapbdzfN448Onb96fv37w8vxXDXd50K6Eph4b5rOk/oK9XuShFZbwVuDKqxfpbnaYFUj4IgVrkSnkm2RD2fCu4KxfJV+hC+q/8KC4yitMIsLLwevM6yutbL40oSdqxlKvVQhs4B9WejFz0s6KsA5dSSYQncv+wz842rkkMcLpFS9WrqOS2GXlQDzTcDXK9qZmoF/1TuGkY82GgCEZhae5VvObRoWNG1KkEEs9vBvOByI48ithJ9tpPMcn9ICvaGkh5mWFRiklqCjqI5DRXtGjwf2Nrn/s0MyAQl1+bJ1LngOZjg7zpOtOBhzSTmL3Jio4fDWJxp95bzqy1UIjRVoT85rFVn0HyVeaL1vZZ+8XSq/AXyyB5FAPxlulWIRXCevb/cm/yG1fBqWYcpMI5AAAAAElFTkSuQmCC" className="" width="25" height="25"/>
                      </div>
                      <div className='payment-text-wrapper-no-payment-new'>
                        <p className="next-scheduled-payment-text-style">Next Scheduled Payment:</p>
                        <p className="outstanding-balance-date">06-25-23 - $1,455.31</p>
                        <p className="next-scheduled-payment-text-style">Auto Debiting from Chase</p>
                      </div>
                    </div>
                    <div className='Remi-payment-bg'>
                    <div>
                    <PayroButton
                        customWidth="width-182"
                        customHeight="48"
                        startIcon={remitPaymentIcon}
                        onClick={() => accountDetails && accountDetails.legal_address_state == "NY" ? EnableRefunding() : history.push('/ledger-detail')}
                      >
                        Remit Payment
                      </PayroButton>
                    </div>
                    <div id="myModal" className={showHideClassName1}>
                      <div className="modal-content-repay">
                        <span
                          onClick={() => {
                            setShowHideClassName1('modal display-none');
                          }}
                          className="close"
                        >
                          &times;
                        </span>
                        <Remitpayment/>
                      </div>
                    </div>
                    <div>
                      <p>Pay early and save on fees. Contact us to discuss early payment options.</p>
                    </div>
                    </div>
                </div> */}
          </div>
          <div id="additional-details-text-container">
            <p className="additional-details-text">
              Additional Details
            </p>
          </div>
          <div className="main-comp">
            <div
              id="additional-detail-highlight-container-one"
              className="loan-details-section"
            >
              <div className="Original-heading-one">
                <img
                  src={detailbreakdownicon}
                  className="img-bg"
                  width="25"
                  height="25"
                />
                <p id="Original-Funding">
                  Original Funding Breakdown
                </p>
              </div>
              <div className="Funding-Amount">
                <p id="Funding">Funding Amount</p>
                <p id="Amount">
                  {formatNumberAsDollars(repayment?.funding_amount)}
                </p>
              </div>

              <div className="outerbox-outer-Advance">
                <div className="outer-Advance">
                  <div>
                    <p id="Advance-Cost">Total Advance Cost:</p>
                    <p id="Advance-Cost-amount">
                      {formatNumberAsDollars(
                        ledgerdetail_advance_cost,
                      )}
                    </p>
                  </div>
                  <div>
                    <p id="Advance-Cost-factor">
                      {repayment?.FactorRate}%
                    </p>
                    <p id="Advance-Cost">Factor Rate</p>
                  </div>
                </div>

                <div className="outer-Advance">
                  <div className="">
                    <p id="Advance-Cost">Weekly Estimated Payment:</p>
                    <p id="Advance-Cost-amount">
                      {formatNumberAsDollars(
                        repayment?.WeeklyPaymentAmount,
                      )}
                    </p>
                    <p id="Advance-Cost">
                      Based on your weekly sales of <br />
                      {revenueFigure &&
                        formatNumberAsDollars(revenueFigure)}{' '}
                      - {revenuePercentage}% of the Revenue
                    </p>
                  </div>
                  <div>
                    <p id="Advance-Cost-factor">
                      {repayment?.NumberofWeeks}
                    </p>
                    <p id="Advance-Cost">Payments</p>
                  </div>
                </div>
              </div>

              <div className="type-outer">
                <p id="type-stand-head">Type</p>
                <p id="standar">{repayment?.PlanType}</p>
              </div>
              <div className="type-outer">
                <p id="type-stand-head">
                  Estimated Final Payment Date
                </p>
                <p id="stand-Payment-Date">
                  {formatDate(repayment?.EstFinalPaymentDate)}
                </p>
              </div>
              <div className="type-outer">
                <p id="type-stand-head">Funds sent to Bank</p>
                <p id="chase-n">
                  {repayment?.withdrawl_bank_name} - {bankdetail}
                </p>
              </div>
              <div className="type-outer">
                <p id="type-stand-head">Repayment Bank</p>
                <p id="chase-n">
                  {repayment?.withdrawl_bank_name} - {bankdetail}
                </p>
              </div>

              <div className="Original-heading-one">
                <img
                  src={ledgerdetailicon}
                  className="img-bg"
                  width="25"
                  height="25"
                />
                <p id="Original-Funding">Payroll Information</p>
              </div>
              {payrollObject &&
              payrollObject?.PayDate__c &&
              payrollObject?.Total_Payroll_Liability__c ? (
                <>
                  <div className="type-outer">
                    <p id="type-stand-head">Payroll Date</p>
                    <p id="chase-n">
                      {formatDate(payrollObject?.PayDate__c)}
                    </p>
                  </div>
                  <div className="type-outer">
                    <p id="type-stand-head">Total Cash Due</p>
                    <p id="chase-n">
                      {formatNumberAsDollars(
                        payrollObject?.Total_Payroll_Liability__c,
                      )}
                    </p>
                  </div>
                </>
              ) : (
                ''
              )}

              <div className="type-outer">
                <p id="type-stand-head">Payroll Service Bureau</p>
                <p id="chase-n">
                  {repayment?.merchant_payroll_company}
                </p>
              </div>
            </div>
            <div
              id="additional-detail-highlight-container-two-new"
              className="loan-details-section"
            >
              <div className="Original-heading">
                <div className="Original-heading">
                  <img
                    src={timelineicon}
                    className="img-bg"
                    width="25"
                    height="25"
                  />
                  <p id="Original-Funding">Advance Activity</p>
                </div>
                <div className="header-button-wrapper">
                  <PayroButton
                    customWidth="width-130"
                    className="download-statement"
                    variant="purple-white"
                    startIcon={downloadicon}
                    onClick={() => {
                      onDownloadClick();
                    }}
                  >
                    Download Statement
                  </PayroButton>
                  {/* <button type="button" className="download-statement">
                        <img className="button-start-image" src={downloadicon} />
                          
                          <a style={{textDecoration:'none',color:'rgb(0 0 0)'}} href='#' onClick={() => onDownloadClick()}>Download Statement</a>
                      </button>  */}
                </div>
              </div>

              <div className="Funding-Amount-two-border">
                <div>
                  <div className="Funding-Amount-two">
                    <p id="stand-Payment-Date">
                      {totalPaid
                        ? formatNumberAsDollars(totalPaid)
                        : formatNumberAsDollars(0)}{' '}
                      paid
                    </p>
                    <p>
                      {repayment?.payoff == null
                        ? 0
                        : repayment?.payoff}
                      %
                    </p>
                  </div>
                  <div id="progress-bar-container-ledger-detail">
                    <div
                      id="green-completed"
                      className={
                        repayment != undefined
                          ? parseInt(repayment.payoff) <= 5
                            ? 'width-5-percent'
                            : parseInt(repayment.payoff) <= 10
                            ? 'width-10-percent'
                            : parseInt(repayment.payoff) <= 15
                            ? 'width-15-percent'
                            : parseInt(repayment.payoff) <= 20
                            ? 'width-20-percent'
                            : parseInt(repayment.payoff) <= 25
                            ? 'width-25-percent'
                            : parseInt(repayment.payoff) <= 30
                            ? 'width-30-percent'
                            : parseInt(repayment.payoff) <= 35
                            ? 'width-35-percent'
                            : parseInt(repayment.payoff) <= 40
                            ? 'width-40-percent'
                            : parseInt(repayment.payoff) <= 45
                            ? 'width-45-percent'
                            : parseInt(repayment.payoff) <= 50
                            ? 'width-50-percent'
                            : parseInt(repayment.payoff) <= 55
                            ? 'width-55-percent'
                            : parseInt(repayment.payoff) <= 60
                            ? 'width-60-percent'
                            : parseInt(repayment.payoff) <= 65
                            ? 'width-65-percent'
                            : parseInt(repayment.payoff) <= 70
                            ? 'width-70-percent'
                            : parseInt(repayment.payoff) <= 75
                            ? 'width-75-percent'
                            : parseInt(repayment.payoff) <= 80
                            ? 'width-80-percent'
                            : parseInt(repayment.payoff) <= 85
                            ? 'width-85-percent'
                            : parseInt(repayment.payoff) <= 90
                            ? 'width-90-percent'
                            : parseInt(repayment.payoff) <= 95
                            ? 'width-95-percent'
                            : parseInt(repayment.payoff) <= 100
                            ? 'width-100-percent'
                            : 'width-1-percent'
                          : 'width-1-percent'
                      }
                    ></div>
                  </div>
                </div>
              </div>
              <div className="outer-Advance">
                <div>
                  <p id="stand-Payment-Date">Payroll Advance</p>
                  <p id="chase-n">
                    {formatDate(repayment?.funded_date)}
                  </p>
                </div>
                <div>
                  <p id="cost-amt-Gr" className="success">
                    {formatNumberAsDollars(repayment?.funding_amount)}
                  </p>
                  <p id="cost-amt-fr">USD</p>
                </div>
              </div>
              {/* {
                        allPaymentsList?.length 
                        ? 
                          allPaymentsList.map((p) => (
                            <>
                            <div className="outer-Advance">
                              <div>
                                <p id="stand-Payment-Date">Payment</p>
                                <p id="chase-n"> {formatDate(p.effective_date)} </p>
                              </div>
                              <div>
                                <p id="cost-amt-Gr" className="success"> {formatNumberAsDollars(
                                      p.transaction_amount,
                                    )}</p>
                                <p id="cost-amt-fr">USD</p>
                              </div>
                            </div>
                            </>
                          ))
                        :
                          ""
                      } */}
              {allPaymentsList?.length
                ? allPaymentsList.map((p) => (
                    <>
                      <div className="outer-Advance">
                        <div>
                          <p id="stand-Payment-Date">
                            Payment
                            {p.status == 'Settled' ? (
                              <span className={`badge success`}>
                                Success
                              </span>
                            ) : p.status == 'Scheduled' ||
                              p.status == 'Pending' ? (
                              <span className={`badge warning`}>
                                {p.status}
                              </span>
                            ) : (
                              <span className={`badge error`}>
                                Failed
                              </span>
                            )}
                          </p>
                          <p id="chase-n">
                            {' '}
                            {formatDate(p.effective_date)}{' '}
                          </p>
                        </div>
                        <div>
                          <p
                            id="cost-amt-Gr"
                            className={
                              p.status == 'Settled'
                                ? 'success'
                                : p.status == 'Pending'
                                ? 'warning'
                                : 'failed'
                            }
                          >
                            {' '}
                            {formatNumberAsDollars(
                              p.transaction_amount,
                            )}
                          </p>
                          <p id="cost-amt-fr">USD</p>
                        </div>
                      </div>
                    </>
                  ))
                : ''}
              {/* <div>
                          <p id="stand-Payment-Date">Payment</p>
                          <p id="chase-n">09/28/21</p>
                        </div>
                        <div>
                          <p id="cost-amt-Gr" className="success">0</p>
                          <p id="cost-amt-fr">USD</p>
                        </div>
                        </div> */}
              {/* <div className="outer-Advance">
                          <div>
                            <p id="stand-Payment-Date">Initial Advancement Funded <span className={`badge error`}>Failed</span></p>
                          </div>
                          <div>
                            <p id="cost-amt-Gr" >0 </p>
                            <p id="cost-amt-fr">USD</p>
                          </div>
                        </div> */}
              {/* <div className="outer-Advance">
                          <div>
                            <p id="stand-Payment-Date">Processing Fee <span className={`badge warning`}>Pending</span></p>
                          </div>
                          <div>
                            <p id="cost-amt-Gr" className='warning'>0 </p>
                            <p id="cost-amt-fr">USD</p>
                          </div>
                        </div> */}
              {fees ? (
                <div className="outer-Advance">
                  <div>
                    <p id="stand-Payment-Date">NSF Fee</p>
                  </div>
                  <div>
                    <p id="cost-amt-Gr" className="failed">
                      {formatNumberAsDollars(fees ? fees : 0)}
                    </p>
                    <p id="cost-amt-fr">USD</p>
                  </div>
                </div>
              ) : (
                ''
              )}
              {/* <div className="outer-Advance">
                          <div>
                            <p id="stand-Payment-Date">Default Fee</p>
                          </div>
                          <div>
                            <p id="cost-amt-Gr">{formatNumberAsDollars(150)}</p>
                            <p id="cost-amt-fr">USD</p>
                          </div>
                        </div> */}
              {discount ? (
                <div className="outer-Advance">
                  <div>
                    <p id="stand-Payment-Date">Discount/Refund</p>
                  </div>
                  <div>
                    <p id="cost-amt-Gr">
                      {formatNumberAsDollars(discount ? discount : 0)}
                    </p>
                    <p id="cost-amt-fr">USD</p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default LedgerDetail;
