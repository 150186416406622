import PayrollToFundDetails from '../PayrollToFundDetails';
import RepaymentDate from '../RepaymentDate';
import CashAdvance from '../CashAdvance';
import CashAdvanceAgreement from '../CashAdvanceAgreement';
import { RequestFundingWrapperProps } from './interfaces';
import FooterButtons from '../../../Footer/footer-buttons';
import Funded from '../Funded';
import {
  BankAccount,
  GetAccountDto,
  GetRepaymentDto,
  UpdateBankAccountDto,
} from '../../../api-utils/generated-client';
import NextIcon from '../../../common-icons/next-arrow.svg';
import ConnectBankAccounts from '../ConnectBankAccounts/connect-banks';
import Confirm from '../Confirm';
import VerifyPayroll from '../VerifyPayroll';
import PayroButton from '../../../widgets/PayroButton';
import Loader from '../../../widgets/Loader';
import {
  docVersionState,
  signatureDataUrlState,
} from '../../../recoil-state/application-stage-states';
import LedgerHome from '../../Ledger/LedgerHome';
import {
  fundingStepState,
  dealDraftState,
  uploadedFilesState,
  bankAccountToUpdateState,
  bankAccountsState,
  chaseBankAccountsArrayState,
} from '../../../recoil-state/request-funding-states';
import { isThereFutureFinchPayroll } from '../../../recoil-state/finch-states';
import {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
} from 'recoil';
import { getClient } from '../../../api-utils/general-utils';
import { useContext, useEffect, useState } from 'react';
import { MessageContext } from '../../../context';
import RequestFundingTitle from '../request-funding-title';
import TotalPayback from '../TotalPayback';
import DealFailed from '../DealFailed/DealFailed';
import ImportantUpdate from '../ImportantUpdate'
import DealPending from '../DealPending/DealPending';
import closeX from '../../../common-icons/closex.svg';
import { useHistory } from 'react-router-dom';
import { logGreen } from '../../../common-utils';
import { accountRecordState,repaymentsState } from '../../../recoil-state/general-states';
import CashAdvanceConfirm from '../CashAdvanceConfirm';

export default (props:any) => {
  let history = useHistory();
  const isValidSceenName = (newScreen: string): boolean => {
    const validScreens = [
      'funding-amount',
      'repayment-date',
      'bank-info',
      'verify-payroll',
      'confirm',
      'funded',
      'dashboard',
      'PendingApproval'
    ];
    return validScreens.includes(newScreen);
  };
  const [fundingStep, setFundingStep] =
    useRecoilState(fundingStepState);
  const dealRecord = useRecoilValue(dealDraftState);
  const uploadedFiles = useRecoilValue(uploadedFilesState);
  const isThereFuturePayrollFromFinch = useRecoilValue(
    isThereFutureFinchPayroll,
  );
  const [hideConfirmFooterButtons, setHideConfirmFooterButtons] =
    useState(false);
  const [bankAccountToUpdate, setBankAccountToUpdate] =
    useRecoilState<UpdateBankAccountDto>(bankAccountToUpdateState);
  const [bankAccounts, setBankAccounts] =
    useRecoilState(bankAccountsState);
  let [chaseBankAccountsArray, setChaseBankAccountsArray] =
    useRecoilState(chaseBankAccountsArrayState);
  const [signatureDataUrl, setSignaturedataUrl] = useRecoilState<any>(signatureDataUrlState);
  const [docVersion, setDocVersion] = useRecoilState<string>(docVersionState);
  const [repayments, setRepayments] = useRecoilState<
    GetRepaymentDto[] | undefined
  >(repaymentsState);
  const [accountDetails, setAccountDetails] =
    useRecoilState<GetAccountDto>(accountRecordState);

  const messageContext = useContext(MessageContext);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isLoader, setisLoader] = useState<boolean>(true);

  useEffect(() => {
    if(accountDetails && accountDetails.legal_address_state == 'NY' && repayments && repayments.length > 0){
      repayments && repayments.length && repayments.map((repayment) => {
        if (repayment.status == 'Request In - Review Payroll'){
          setFundingStep('PendingApproval');
        }
      })
    }
    setTimeout(() => {
      setisLoader(false)
    }, 3000);
  })
  useEffect(() => {
      setisLoader(true)
  },[fundingStep])

  useEffect(() => {
    const setArray = async () => {
      let mainArray: any = [];
      const chaseAccounts = bankAccounts
        .filter((account) => account.plaid_id)
        .filter((name) => name.bank_name?.toLowerCase() == 'chase');
      chaseAccounts.forEach(
        (account, index) =>
          (mainArray = [
            ...mainArray,
            {
              key: Math.random(),
              uuid: account.uuid,
              bankRoutingNumber:
                account.is_real_bank_numbers == true
                  ? account.bank_routing_number?.toString()
                  : '',
              bankRoutingNumberConfirm:
                account.is_real_bank_numbers == true
                  ? account.bank_routing_number?.toString()
                  : '',
              bankAccountNumber:
                account.is_real_bank_numbers == true
                  ? account.bank_account_number?.toString()
                  : '',
              bankAccountNumberConfirm:
                account.is_real_bank_numbers == true
                  ? account.bank_account_number?.toString()
                  : '',
            },
          ]),
      );
      await setChaseBankAccountsArray(mainArray);
    };

    // console.log(chaseBankAccountsArray);

    const getBankAccounts = async () => {
      const client = await getClient();
      if (!client) {
        return;
      }
      const b = await client.bankAccountsControllerFindAll();
      await setBankAccounts(b.data);
    };
    getBankAccounts();
    {
      bankAccounts && bankAccounts.length > 1 && setArray();
    }

    const setBank = () => {
      setBankAccountToUpdate({
        ...bankAccountToUpdate,
        uuid: bankAccounts[0].uuid,
        bank_name: bankAccounts[0].bank_name,
        bank_account_type: bankAccounts[0].bank_account_type,
        account_last_four: bankAccounts[0].account_last_four,
      });
    };
    {
      bankAccounts && bankAccounts.length > 1 && setBank();
    }
  }, []);
  
  if (isLoader) {
    return <Loader />;
  }
  return (
    <div id={`request-funding-wrapper-${fundingStep}`} className={`main-body more-funding ${props && props.popupContainer ? 'refunding-popup' : ''}`}>
      {fundingStep == 'PendingApproval' && <DealPending />}
      {fundingStep != 'failed' && (
        <img
          id={accountDetails && accountDetails.legal_address_state == 'NY'?"close-request-more-on-page-other":"close-request-more-on-page"}
          src={closeX}
          onClick={() => history.push('/dashboard')}
        />
      )}
      {fundingStep == 'funding-amount' && !isLoader && (
        <div>
          <RequestFundingTitle
            fundingStep="confirm"
            title={'Fund Payroll'}
            subtitle=""
          />
          {
            accountDetails && accountDetails.legal_address_state !== 'NY'
            ?
              <>
                <VerifyPayroll />
                <PayrollToFundDetails />
                <RepaymentDate />
                <TotalPayback />
                <PayroButton
                  centered={true}
                  onClick={() => {
                    setFundingStep('confirm');
                    messageContext.clearMessages();
                  }}
                  disabled={
                    !dealRecord.funding_amount ||
                    dealRecord.funding_amount < 5000 ||
                    (accountDetails.credit_amount_available &&
                      dealRecord.funding_amount >
                        accountDetails.credit_amount_available) ||
                    (isThereFuturePayrollFromFinch != 'yes' &&
                      uploadedFiles.length <= 0) ||
                    !dealRecord.selected_num_of_weeks_in_portal
                  }
                  customWidth="width-200"
                  endIcon={NextIcon}
                >
                  Review and Confirm
                </PayroButton>
              </>
            :
              <>
              <span id='fund-payroll-enter'>{
                dealRecord && dealRecord.funding_amount && uploadedFiles && uploadedFiles.length > 0
                ?
                  ''
                :
                  'Please enter amount and verify payroll to check the plans'
              }
              </span>
                <PayrollToFundDetails IsRepeatFunding={true} />
                {
                  dealRecord && dealRecord.funding_amount && uploadedFiles && uploadedFiles.length > 0
                  ?
                    <CashAdvance changeStep={() => setFundingStep('cash-advance-agreement')} IsRepeatFunding={true}  userAddressState={accountDetails.legal_address_state} />
                  :
                    ""
                }
              </>

          }
        </div>
      )}

      {
        fundingStep == 'important-update' && (
          <><ImportantUpdate /></>
        )
      }
      {
        fundingStep == 'cash-advance-agreement' && (
          <>
            {/* <PayrollToFundDetails IsRepeatFunding={true} /> */}
            <CashAdvanceAgreement IsRepeatFunding={true} userAddressState={accountDetails.legal_address_state} />
          </>
        )
      }

      {fundingStep == 'confirm' && (
        <div className='confirm-step'>
          {
            accountDetails && accountDetails.legal_address_state == 'NY'
            ?
              <CashAdvanceConfirm
                confirmButtonClick={async () => {
                  const client = await getClient();
                  try {
                    const dealStatus =
                      await client?.dealsControllerCashAdvanceActivateDeal({signature: signatureDataUrl,documentVersion: docVersion as string});

                    if (dealStatus?.status == 200) {
                      // history.push('/funded');
                      setFundingStep('funded');
                      window.location.reload()
                    } else {
                      // setFundingStep('failed');
                    }
                  } catch {
                    // setFundingStep('failed');
                  }
                }}
              />
            :
              <Confirm
                confirmButtonClick={async () => {
                  setHideConfirmFooterButtons(true);
                  const client = await getClient();
                  try {
                    const dealStatus =
                      await client?.dealsControllerActivateDeal();

                    if (dealStatus?.status == 200) {
                      setFundingStep('funded');
                      history.push('/funded');
                    } else {
                      setFundingStep('failed');
                    }
                  } catch (error) {
                    logGreen('requestFundingFailed', error);

                    setFundingStep('failed');
                  }
                }}
              />
        }

          {!hideConfirmFooterButtons && (
            <FooterButtons
              hideNextButton={true}
              //     nextDisabled={false}
              nextType="cancel"
              // //the next type is set to cancel so that the button will say cancel request
            />
          )}
        </div>
      )}
      {fundingStep == 'failed' && <DealFailed />}

      {fundingStep == 'funded' && <Funded />}

    </div>
  );
};
